/*--------------------
  Classic Navbar
----------------------*/
.navigation {
  width: 100%;
  height: 80px;
  display: table;
  position: relative;
  font-family: inherit;
  background-color: #fff;
  padding-top: 9px;
}

.navigation-fixed-wrapper{
  background-color: #fff;
  border-bottom: 1px solid #eee;
  box-shadow: 0 0 65px rgba(0,0,0,.09);
  z-index: 2;
  position: relative;
}

@media (max-width: 991px) {
  .navigation {
    padding: 15px 0px;
  }
}

.navigation * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

.navigation-portrait {
  height: 48px;
}

.navigation-fixed-wrapper {
  width: 100%;
  left: 0;
  z-index: 19998;
  /*will-change: opacity;*/
}

.navigation-fixed-wrapper.fixed {
  position: fixed !important;
  -webkit-animation: fade .50s;
  animation: fade .50s;
}

@keyframes fade {
  from {
    opacity: 0.999;
  }
  to {
    opacity: 1;
  }
}

.navigation-fixed-wrapper .navigation {
  margin-right: auto;
  margin-left: auto;
}

.navigation-fixed-placeholder {
  width: 100%;
  display: none;
}

.navigation-fixed-placeholder.navigation-fixed-placeholder.visible {
  display: block;
}

.navigation-hidden {
  width: 0 !important;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute;
  top: -9999px;
}

.align-to-right {
  float: right;
}

.nav-header {
  float: left;
}

.nav-header-center{
  float: none;
  text-align: center;
  display: block;
}

.navigation-hidden .nav-header {
  display: none;
}

.nav-brand {
  line-height: 70px;
  padding: 0px 15px 0px 0px;
  color: #70798b;
  font-size: 24px;
  text-decoration: none !important;
}

@media (max-width: 991px) {
  .nav-brand {
    padding: 0px 15px;
  }
}

.nav-brand:hover, .nav-brand:focus {
  color: #70798b;
}

.navigation-portrait .nav-brand {
  font-size: 18px;
  line-height: 48px;
}

.nav-logo > img {
  height: 48px;
  margin: 11px auto;
  padding: 0 15px;
  float: left;
}

.nav-logo:focus > img {
  outline: initial;
}

.navigation-portrait .nav-logo > img {
  height: 36px;
  margin: 6px auto 6px 15px;
  padding: 0;
}

.nav-toggle {
  width: 30px;
  height: 30px;
  padding: 6px 2px 0;
  position: absolute;
  top: 50%;
  margin-top: -14px;
  right: 15px;
  display: none;
  cursor: pointer;
}

.nav-toggle:before {
  content: "";
  position: absolute;
  width: 24px;
  height: 2px;
  background-color: #70798b;
  border-radius: 10px;
  box-shadow: 0 0.5em 0 0 #70798b, 0 1em 0 0 #70798b;
}

.navigation-portrait .nav-toggle {
  display: block;
}

.navigation-portrait .nav-menus-wrapper {
  width: 320px;
  height: 100%;
  top: 0;
  left: -400px;
  position: fixed;
  background-color: #ffffff;
  z-index: 999999;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-transition-duration: .8s;
  transition-duration: .8s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}

.navigation-portrait .nav-menus-wrapper.nav-menus-wrapper-right {
  left: auto;
  right: -400px;
}

.navigation-portrait .nav-menus-wrapper.nav-menus-wrapper-open {
  left: 0;
}

.navigation-portrait .nav-menus-wrapper.nav-menus-wrapper-right.nav-menus-wrapper-open {
  left: auto;
  right: 0;
}

.nav-menus-wrapper-close-button {
  width: 30px;
  height: 40px;
  margin: 10px 7px;
  display: none;
  float: right;
  color: #70798b;
  font-size: 26px;
  cursor: pointer;
}

.navigation-portrait .nav-menus-wrapper-close-button {
  display: block;
}

.nav-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: normal;
  font-size: 0;
}

.navigation-portrait .nav-menu {
  width: 100%;
}

.nav-menu.nav-menu-centered {
  text-align: center;
}

.nav-menu.nav-menu-centered > li {
  float: none;
}

.nav-menu > li {
  display: inline-block;
  float: left;
  text-align: left;
}

.navigation-portrait .nav-menu > li {
  width: 100%;
  position: relative;
  border-top: solid 1px #f7f7f7;
}

.navigation-portrait .nav-menu > li:last-child {
  border-bottom: solid 1px #f0f0f0;
}

.nav-menu + .nav-menu > li:first-child {
  border-top: none;
}

.nav-menu > li > a {
  height: 80px;
  padding: 26px 16px 0px 16px;
  display: inline-block;
  position: relative;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  color: #222;
  -webkit-transition: color 0.3s, background 0.3s;
  transition: color 0.3s, background 0.3s;
}

.navigation-portrait .nav-menu > li > a {
  width: 100%;
  height: auto;
  padding: 12px 15px 12px 26px;
}

.nav-menu > li:hover > a,
.nav-menu > li.active > a,
.nav-menu > li.focus > a {
  color: #2387ea;
}

.nav-menu > li > a > i,
.nav-menu > li > a > [class*="ion-"] {
  width: 18px;
  height: 16px;
  line-height: 16px;
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  transform: scale(1.4);
}

.nav-menu > li > a > [class*="ion-"] {
  width: 16px;
  display: inline-block;
  -webkit-transform: scale(1.8);
  -ms-transform: scale(1.8);
  transform: scale(1.8);
}

.navigation-portrait .nav-menu.nav-menu-social {
  width: 100%;
  text-align: center;
}

.nav-menu.nav-menu-social > li {
  text-align: center;
  float: none;
  border: none !important;
}

.navigation-portrait .nav-menu.nav-menu-social > li {
  width: auto;
}

.nav-menu.nav-menu-social > li > a > [class*="ion-"] {
  font-size: 12px;
}

.nav-menu.nav-menu-social > li > a > .fa {
  font-size: 14px;
}

.navigation-portrait .nav-menu.nav-menu-social > li > a {
  padding: 15px;
}

.submenu-indicator {
  margin-left: 6px;
  margin-top: 6px;
  float: right;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.navigation-portrait .submenu-indicator {
  width: 54px;
  height: 44px;
  margin-top: 0;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  z-index: 20000;
}

.submenu-indicator-chevron {
  height: 6px;
  width: 6px;
  display: block;
  border-style: solid;
  border-width: 0 1px 1px 0;
  border-color: transparent #70798b #70798b transparent;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: border 0.3s;
  transition: border 0.3s;
}

.navigation-portrait .submenu-indicator-chevron {
  position: absolute;
  top: 18px;
  left: 24px;
}

.nav-menu > li:hover > a .submenu-indicator-chevron,
.nav-menu > .active > a .submenu-indicator-chevron,
.nav-menu > .focus > a .submenu-indicator-chevron {
  border-color: transparent #2387ea #2387ea transparent;
}

.navigation-portrait .submenu-indicator.submenu-indicator-up {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.nav-overlay-panel {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: none;
  z-index: 99999;
}

.no-scroll {
  touch-action: none;
  overflow-x: hidden;
}

/* SEARCH PANEL */
.nav-search {
  height: 70px;
  float: right;
  z-index: 19998;
}

.navigation-portrait .nav-search {
  height: 48px;
  padding: 0 10px;
  margin-right: 52px;
}

.navigation-hidden .nav-search {
  display: none;
}

.nav-search-button {
  width: 40px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  margin-top: 3px;
}

/*Extra Class*/
.nav-search-button i{
  font-size: 15px;
  font-weight: 600;
}
@media (max-width: 991px) {
  .nav-search-button{
    margin-top: 0px;
    width: 25px !important;
    height: 50px;
    padding-top: 3px;
  }
  .nav-search-button i{
    font-size: 17px;
    font-weight: 600;
  }   

  .nav-search-inner input{
    margin-top: 15px !important;
  }
  .nav-search-close-button{
    top: 25px !important; 
  }
}

.navigation-portrait .nav-search-button {
  width: 50px;
  height: 48px;
  line-height: 46px;
  font-size: 22px;
}

.nav-search-icon {
  width: 14px;
  height: 14px;
  margin: 2px 8px 8px 4px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  color: #70798b;
  text-align: left;
  text-indent: -9999px;
  border: 2px solid;
  border-radius: 50%;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.nav-search-icon:after, .nav-search-icon:before {
  content: '';
  pointer-events: none;
}

.nav-search-icon:before {
  width: 2px;
  height: 11px;
  top: 11px;
  position: absolute;
  left: 50%;
  border-radius: 0 0 1px 1px;
  box-shadow: inset 0 0 0 32px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.nav-search-button:hover .nav-search-icon {
  color: #2387ea;
}

.nav-search > form {
  width: 100%;
  height: 100%;
  padding: 0 auto;
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #ffffff;
  z-index: 99;
}

.nav-search-inner {
  width: 70%;
  height: 85px;
  margin: auto;
  display: table;
}

.navigation-portrait .nav-search-inner {
  height: 48px;
}

.nav-search-inner input[type=text],
.nav-search-inner input[type=search] {
  height: 85px;
  width: 100%;
  margin: 0;
  padding: 0 12px;
  font-size: 26px;
  text-align: center;
  color: #70798b;
  outline: none;
  line-height: 70px;
  border: none;
  background-color: transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.navigation-portrait .nav-search-inner input[type=text],
.navigation-portrait .nav-search-inner input[type=search] {
  height: 48px;
  font-size: 18px;
  line-height: 48px;
}

.nav-search-close-button {
  width: 28px;
  height: 28px;
  display: block;
  position: absolute;
  right: 20px;
  top: 30px;
  line-height: normal;
  color: #222;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
  outline: none;
}

.navigation-portrait .nav-search-close-button {
  top: 10px;
  right: 14px;
}

/* NAVIGATION BUTTON */
.nav-button {
  height: auto !important;
  margin: 18px 15px 0;
  padding: 8px 14px;
  display: inline-block;
  color: #fff;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  background-color: #2387ea;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.nav-button:hover, .nav-button:focus {
  color: #fff;
  text-decoration: none;
  opacity: 0.85;
}

.navigation-portrait .nav-button {
  width: calc(100% - 52px);
  margin: 17px 26px;
}

/* NAVIGATION TEXT */
.nav-text {
  margin: 25px 15px;
  display: inline-block;
  color: #70798b;
  font-size: 14px;
}

.navigation-portrait .nav-text {
  width: calc(100% - 52px);
  margin: 12px 26px 0;
}

.navigation-portrait .nav-text + ul {
  margin-top: 15px;
}

/* DROPDOWN EXTENSION */
.nav-dropdown {
  min-width: 200px;
  margin: 0;
  padding: 0;
  display: none;
  position: absolute;
  list-style: none;
  z-index: 98;
  white-space: nowrap;
  -webkit-box-shadow: 0 6px 50px rgba(0,0,0,.04);
  box-shadow: 0 6px 50px rgba(0,0,0,.04);
}

.navigation-portrait .nav-dropdown {
  width: 100%;
  position: static;
  left: 0;
}

.nav-dropdown .nav-dropdown {
  left: 100%;
}

.nav-menu > li > .nav-dropdown {
  /*border: solid 1px #f0f0f0;*/
}

.nav-dropdown > li {
  width: 100%;
  float: left;
  clear: both;
  position: relative;
  text-align: left;
}

.nav-dropdown > li > a {
  width: 100%;
  padding: 11px 20px;
  display: inline-block;
  text-decoration: none;
  float: left;
  font-size: 12px;
  font-weight: 600;
  color: #70798b;
  background-color: #fff;
  -webkit-transition: color 0.3s, background 0.3s;
  transition: color 0.3s, background 0.3s;
}

.nav-dropdown > li:hover > a,
.nav-dropdown > li.focus > a {
  color: #2387ea;
}

.nav-dropdown.nav-dropdown-left {
  right: 0;
}

.nav-dropdown > li > .nav-dropdown-left {
  left: auto;
  right: 100%;
}

.navigation-landscape .nav-dropdown.nav-dropdown-left > li > a {
  text-align: right;
}

.navigation-portrait .nav-dropdown > li > a {
  padding: 12px 20px 12px 30px;
}

.navigation-portrait .nav-dropdown > li > ul > li > a {
  padding-left: 50px;
}

.navigation-portrait .nav-dropdown > li > ul > li > ul > li > a {
  padding-left: 70px;
}

.navigation-portrait .nav-dropdown > li > ul > li > ul > li > ul > li > a {
  padding-left: 90px;
}

.navigation-portrait .nav-dropdown > li > ul > li > ul > li > ul > li > ul > li > a {
  padding-left: 110px;
}

.nav-dropdown .submenu-indicator {
  right: 15px;
  top: 16px;
  position: absolute;
}

.navigation-portrait .nav-dropdown .submenu-indicator {
  right: 0;
  top: 0;
}

.nav-dropdown .submenu-indicator .submenu-indicator-chevron {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.navigation-portrait .nav-dropdown .submenu-indicator .submenu-indicator-chevron {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.nav-dropdown > li:hover > a .submenu-indicator-chevron,
.nav-dropdown > .focus > a .submenu-indicator-chevron {
  border-color: transparent #2387ea #2387ea transparent;
}

.navigation-landscape .nav-dropdown.nav-dropdown-left .submenu-indicator {
  left: 10px;
}

.navigation-landscape .nav-dropdown.nav-dropdown-left .submenu-indicator .submenu-indicator-chevron {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

/* HORIZONTAL DROPDOWN EXTENSION */
.nav-dropdown-horizontal {
  width: 100%;
  left: 0;
  background-color: #fdfdfd;
  border-top: solid 1px #f0f0f0;
}

.nav-dropdown-horizontal .nav-dropdown-horizontal {
  width: 100%;
  top: 100%;
  left: 0;
}

.navigation-portrait .nav-dropdown-horizontal .nav-dropdown-horizontal {
  border-top: none;
}

.nav-dropdown-horizontal > li {
  width: auto;
  clear: none;
  position: static;
}

.navigation-portrait .nav-dropdown-horizontal > li {
  width: 100%;
}

.nav-dropdown-horizontal > li > a {
  position: relative;
}

.nav-dropdown-horizontal .submenu-indicator {
  height: 18px;
  top: 11px;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.navigation-portrait .nav-dropdown-horizontal .submenu-indicator {
  height: 42px;
  top: 0;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.navigation-portrait .nav-dropdown-horizontal .submenu-indicator.submenu-indicator-up {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

/* MEGAMENU EXTENSION */
.megamenu-panel {
  width: 100%;
  padding: 15px;
  display: none;
  position: absolute;
  font-size: 14px;
  z-index: 98;
  text-align: left;
  color: inherit;
  border-top: solid 1px #f7f7f7;
  -webkit-box-shadow: 0 6px 50px rgba(0,0,0,.04);
  box-shadow: 0 6px 50px rgba(0,0,0,.04);
  background-color: #fff;
}

.navigation-portrait .megamenu-panel {
  padding: 25px;
  position: static;
  display: block;
}

.megamenu-panel [class*="container"] {
  width: 100%;
}

.megamenu-panel [class*="container"] [class*="col-"] {
  padding: 0;
}

.megamenu-panel-half {
  width: 50%;
}

.megamenu-panel-quarter {
  width: 25%;
}

.navigation-portrait .megamenu-panel-half,
.navigation-portrait .megamenu-panel-quarter {
  width: 100%;
}

/* GRID */
.megamenu-panel-row {
  width: 100%;
}

.megamenu-panel-row:before, .megamenu-panel-row:after {
  display: table;
  content: "";
  line-height: 0;
}

.megamenu-panel-row:after {
  clear: both;
}

.megamenu-panel-row [class*="col-"] {
  display: block;
  min-height: 20px;
  float: left;
  margin-left: 3%;
}

.megamenu-panel-row [class*="col-"]:first-child {
  margin-left: 0;
}

.navigation-portrait .megamenu-panel-row [class*="col-"] {
  float: none;
  display: block;
  width: 100% !important;
  margin-left: 0;
  margin-top: 15px;
}

.navigation-portrait .megamenu-panel-row:first-child [class*="col-"]:first-child {
  margin-top: 0;
}

.megamenu-panel-row .col-1 {
  width: 5.583333333333%;
}

.megamenu-panel-row .col-2 {
  width: 14.166666666666%;
}

.megamenu-panel-row .col-3 {
  width: 22.75%;
}

.megamenu-panel-row .col-4 {
  width: 31.333333333333%;
}

.megamenu-panel-row .col-5 {
  width: 39.916666666667%;
}

.megamenu-panel-row .col-6 {
  width: 48.5%;
}

.megamenu-panel-row .col-7 {
  width: 57.083333333333%;
}

.megamenu-panel-row .col-8 {
  width: 65.666666666667%;
}

.megamenu-panel-row .col-9 {
  width: 74.25%;
}

.megamenu-panel-row .col-10 {
  width: 82.833333333334%;
}

.megamenu-panel-row .col-11 {
  width: 91.416666666667%;
}

.megamenu-panel-row .col-12 {
  width: 100%;
}

/* MEGAMENU TABS */
.megamenu-tabs {
  width: 100%;
  float: left;
  display: block;
}

.megamenu-tabs-nav {
  width: 20%;
  margin: 0;
  padding: 0;
  float: left;
  list-style: none;
}

.navigation-portrait .megamenu-tabs-nav {
  width: 100%;
}

.megamenu-tabs-nav > li > a {
  width: 100%;
  padding: 10px 16px;
  float: left;
  font-size: 13px;
  text-decoration: none;
  color: #70798b;
  border: solid 1px #eff0f2;
  outline: 0;
  background-color: #ffffff;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}

.megamenu-tabs-nav > li.active a,
.megamenu-tabs-nav > li:hover a {
  background-color: #f5f5f5;
}

.megamenu-tabs-pane {
  width: 80%;
  min-height: 30px;
  padding: 20px;
  float: right;
  display: none;
  opacity: 0;
  font-size: 13px;
  color: #70798b;
  border: solid 1px #eff0f2;
  background-color: #ffffff;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.megamenu-tabs-pane.active {
  display: block;
  opacity: 1;
}

.navigation-portrait .megamenu-tabs-pane {
  width: 100%;
}

/* MEGAMENU LIST */
.megamenu-lists {
  width: 100%;
  display: table;
}

/* The list */
.megamenu-list {
  width: 100%;
  margin: 0 0 15px;
  padding: 0;
  display: inline-block;
  float: left;
  list-style: none;
}

.megamenu-list:last-child {
  margin: 0;
  border: none;
}

.navigation-landscape .megamenu-list {
  margin: -15px 0;
  padding: 20px 0;
  border-right: solid 1px #f0f0f0;
}

.navigation-landscape .megamenu-list:last-child {
  border: none;
}

.megamenu-list > li > a {
  width: 100%;
  padding: 10px 15px;
  display: inline-block;
  color: #70798b;
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.megamenu-list > li > a:hover {
  color: #ffffff;
  background-color: #2387ea;
}

.megamenu-list > li.megamenu-list-title > a {
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  opacity: 0.8;
  color: #222;
}

.megamenu-list > li.megamenu-list-title > a:hover {
  opacity: 1;
  background-color: transparent;
}

.navigation-landscape .list-col-2 {
  width: 50%;
}

.navigation-landscape .list-col-3 {
  width: 33%;
}

.navigation-landscape .list-col-4 {
  width: 25%;
}






/*--------------------
  Transparent Navbar
----------------------*/
@media (min-width: 991px) {
  #nav-transparent{
    height: 0px !important; 
  }

  #nav-transparent .navigation{
    width: 100%;
    height: 80px;
    display: table;
    position: relative;
    font-family: inherit;
    background-color: transparent;
    padding-top: 9px;  
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;     
  }

  #nav-transparent .navigation-fixed-wrapper{
    background-color: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    box-shadow: none;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;     
  }

  /*Nav Links*/
  #nav-transparent .nav-menu > li > a {
    height: 80px;
    padding: 26px 16px 0px 16px;
    display: inline-block;
    position: relative;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    -webkit-transition: color 0.3s, background 0.3s;
    transition: color 0.3s, background 0.3s;
  }

  #nav-transparent .navigation-portrait .nav-menu > li > a {
    width: 100%;
    height: auto;
    padding: 12px 15px 12px 26px;
  }

  #nav-transparent .nav-menu > li:hover > a,
  #nav-transparent .nav-menu > li.active > a,
  #nav-transparent .nav-menu > li.focus > a {
    color: #2387ea;
  } 

  #nav-transparent .submenu-indicator-chevron {
    border-color: transparent #fff #fff transparent;
    -webkit-transition: border 0.3s;
    transition: border 0.3s;
  }

  #nav-transparent .nav-dropdown .submenu-indicator-chevron {
    border-color: transparent #70798b #70798b transparent;
    -webkit-transition: border 0.3s;
    transition: border 0.3s;
  }  

  #nav-transparent .nav-menu > li:hover > a .submenu-indicator-chevron,
  #nav-transparent .nav-menu > .active > a .submenu-indicator-chevron,
  #nav-transparent .nav-menu > .focus > a .submenu-indicator-chevron {
    border-color: transparent #2387ea #2387ea transparent;
  }

  #nav-transparent .nav-dropdown > li:hover > a .submenu-indicator-chevron,
  #nav-transparent .nav-dropdown > .focus > a .submenu-indicator-chevron {
    border-color: transparent #2387ea #2387ea transparent;
  }  

  #nav-transparent .nav-search-button i{
    font-size: 15px;
    font-weight: 600;
    color: #fff;
  }
  @media (max-width: 991px) {
    #nav-transparent .nav-search-button{
      margin-top: 0px;
    }
    #nav-transparent .nav-search-button i{
      font-size: 17px;
      font-weight: 600;
    }   
  }

  /*White Options*/
  #nav-transparent .nav-white-bg{
    background: #fff;
    border-bottom: 1px solid #eee;
    box-shadow: 0 0 65px rgba(0,0,0,.09);    
  }

  #nav-transparent .nav-white-bg{
    background: #fff;
  }  

  #nav-transparent .nav-white-bg .nav-menu > li > a {
    height: 80px;
    padding: 26px 16px 0px 16px;
    display: inline-block;
    position: relative;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    color: #222;
    -webkit-transition: color 0.3s, background 0.3s;
    transition: color 0.3s, background 0.3s;
  }  

  #nav-transparent .nav-white-bg .submenu-indicator-chevron {
    border-color: transparent #222 #222 transparent;
    -webkit-transition: border 0.3s;
    transition: border 0.3s;
  }  

  #nav-transparent .nav-white-bg .nav-menu > li:hover > a{
    color: #2387ea;
  }  

  #nav-transparent .nav-white-bg .nav-search-button i{
    font-size: 15px;
    font-weight: 600;
    color: #222;
  } 

  #main_logo{
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;   
  }
  #light_logo{
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;   
  }  

}





/*--------------------
  Push Nav
----------------------*/
.push-content{
  margin-left: 300px;
}
@media screen and (max-width: 991px) {
  .push-content{
    margin-left: 0px;
  }   
}

#navigation-push .navigation{
  z-index: 999;
}

#navigation-push .navigation-portrait .nav-menus-wrapper{
  width: 300px;
  max-width: 100%;
}


#navigation-push .navigation-portrait .nav-menu > li > a {
    width: 100%;
    height: auto;
    padding: 16px 15px 16px 26px;
}

#navigation-push .nav-menus-wrapper{
    -webkit-box-shadow: 0 0 18px rgba(28, 31, 33, 0.07);
    box-shadow: 0 0 18px rgba(28, 31, 33, 0.07);
}

#navigation-push .nav-menu{
  padding-bottom: 30px;
}

#navigation-push .nav-brand img{
  margin: 40px 0px;
}


#navigation-push .nav-menus-wrapper::-webkit-scrollbar {
    width: 4px;
}

#navigation-push .nav-menus-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(0,0,0,0.3); 
    border-radius: 0px;
}

#navigation-push .nav-menus-wrapper::-webkit-scrollbar-thumb {
    border-radius: 0px;
    -webkit-box-shadow: inset 6px 6px 6px rgba(0,0,0,0.3); 
}


#navigation-push .btn-show{
  font-size: 25px;
  color: #70798b;
  margin-top: 6px;
}

#navigation-push .small-size-header{
  padding: 15px 0px;
  text-align: right;
}