/*
	Project Name : Webmaker
	Author Company : SpecThemes
	Project Date: 5 June, 2018
	Template Developer: vsafaryan50@gmail.com
*/

/*
==============================================
TABLE OF CONTENT
==============================================

1. Sections 
2. Headings 
3. Preloader  
4. Page Title (Breadcrumb)
5. Fonts
6. Scroll To Top 
7. Helper Classes  
	a) Text-content
	b) Image-properties
	c) Buttons
	d) Lists
	e) Color Control
	f) Video Box
	g) Pagination
	h) Overlays & Opacity
	i) Forms
8. Service Boxes
9. Feature Boxes
10. Process Steps (icon boxes)
11. Testmonials
12. CountUps
13. Team
14. Small Forms(Contact, Callback, Subscibe)
15. Contact Assets
16. Blog Grid
17. Blog List
18. Blog Post
19. Pricing Options
20. Projects (Portfolio)
21. Gallery
22. Gallery Carousel
23. Gallery Grid
24. Gallery Masonry
25. Shop Grid
26. Contact Us
27. Project Single
28. Shop Single
29. Shop Single(Carousel)
30. Vertical Navs
31. Sidebars
32. Search Results
33. FAQ
34. Dropcaps
35. Action Boxes
36. Clients
37. Timeline
38. Top Bar
39. 3D Flipbox
40. Modals
41. Accordions
42. Tabs
43. Progress Bars
44. Pie Charts
45. Countdowns
46. Owl Carousel
47. Pulse Animation
48. Loader Styles
49. Social icons
50. Errors
51. Animations
52. Footer
53. Color Switcher
 

==============================================
[END] TABLE OF CONTENT
==============================================
*/



/*-----------------------------------------
1. Sections
-------------------------------------------*/
.section-block{
	position: relative;
	padding: 90px 0px 90px 0px;
	background-color: #fff;
}	
@media (max-width: 767px) {
	.section-block{
		padding: 60px 0px 60px 0px;
	}
}

.section-block-grey{
	position: relative;
	padding: 90px 0px 90px 0px;
	background-color: #fafafa;
}
@media (max-width: 767px) {
	.section-block-grey{
		padding: 60px 0px 60px 0px;
	}
}

.section-block-greyer{
	position: relative;
	padding: 90px 0px 90px 0px;
	background-color: #f5f5f5;
}
@media (max-width: 767px) {
	.section-block-greyer{
		padding: 60px 0px 60px 0px;
	}
}

.section-block-dark{
	position: relative;
	padding: 90px 0px 90px 0px;
	background-color: #222;
}
@media (max-width: 767px) {
	.section-block-dark{
		padding: 60px 0px 60px 0px;
	}
}

.section-block-parallax{
	position: relative;
	background-size: cover;
	background-attachment: fixed;
	background-position: 50% 50%;
	padding: 90px 0px 90px 0px;    
}	
@media (max-width: 767px) {
	.section-block-parallax{
		padding: 60px 0px 60px 0px;
	}
}

.section-block-bg{
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	padding: 90px 0px 90px 0px;    
}	
@media (max-width: 767px) {
	.section-block-bg{
		padding: 60px 0px 60px 0px;
	}
}

.right-position{
	position: relative;
}
@media (max-width: 767px) {
	.right-position{
		background-position: right;
	}
}

.left-position{
	position: relative;
}
@media (max-width: 767px) {
	.left-position{
		background-position: left;
	}
}

.jarallax {
    position: relative;
}

.jarallax > .jarallax-img {
    position: absolute;
    object-fit: cover;
    /* support for plugin https://github.com/bfred-it/object-fit-images */
    font-family: 'object-fit: cover;';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}


.video-section{
	position: relative;
}

.video-content{
	position: relative;
	padding: 90px 0px 90px 0px;	
}


.section-md{
	padding: 90px 0px 90px 0px;
}
.section-sm{
	padding: 60px 0px 60px 0px;
}
.section-xs{
	padding: 30px 0px 30px 0px;
}


.full-screen{
	position: relative;
	background-position: center;
	width: 100%;
	height: 100%;
	background-size: cover;
}



.block-primary-bg{
	position: relative;
	background: #2387ea;
}

.block-dark-bg{
	position: relative;
	background: #222;
}

.block-dark-bg-transparent{
	position: relative;
	background: rgba(0, 0, 0, .6);
}

.block-white-bg{
	position: relative;
	background: #fff;
}

.solid-border{
	border: 1px solid #eee;
}


.full-background{
	width: 100%; 
	height: 100%; 
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}



.primary-bg{
	background-color: #2387ea;
}

.grey-bg{
	background-color: #fafafa;
}

.dark-bg{
	background-color: #222;
}


/*-----------------------------------------
2. Headings
-------------------------------------------*/
.section-heading{
	position: relative;
	margin-bottom: 10px;
}

.section-heading h2 strong,
.section-heading h3 strong, 
.section-heading h4 strong, 
.section-heading h5 strong, 
.section-heading h6 strong{
	font-weight: 700;
}

.section-heading h2 i,
.section-heading h3 i, 
.section-heading h4 i, 
.section-heading h5 i, 
.section-heading h6 i{
	font-style: italic;
	font-family: 'Libre Baskerville', serif;
}

.section-heading h2{
	position: relative;
	font-size: 36px;
	font-weight: 600;
	color: #222;
	line-height: 130%;
	letter-spacing: -2px;
	word-spacing: 2px;	
	margin-bottom: 10px;
	margin-top: 5px;
}
@media (max-width: 767px) {
	.section-heading h2{
		font-size: 27px;
	}
}

.section-heading h3{
	position: relative;
	font-size: 33px;
	font-weight: 400;
	color: #222;
	line-height: 130%;
	letter-spacing: -2px;
	word-spacing: 2px;	
	margin-bottom: 10px;
	margin-top: 5px;
}
@media (max-width: 767px) {
	.section-heading h3{
		font-size: 24px;
	}
}

.section-heading h4{
	position: relative;
	font-size: 28px;
	font-weight: 400;
	color: #222;
	line-height: 130%;
	letter-spacing: -2px;
	word-spacing: 2px;	
	margin-bottom: 10px;
	margin-top: 5px;
}
@media (max-width: 767px) {
	.section-heading h4{
		font-size: 23px;
	}
}

.section-heading h5{
	position: relative;
	font-size: 24px;
	font-weight: 400;
	color: #222;
	line-height: 130%;
	letter-spacing: -2px;
	word-spacing: 2px;	
	margin-bottom: 10px;
	margin-top: 5px;
}
@media screen and (max-width: 767px) {
	.section-heading h5{
		font-size: 23px;
	}	
}
.section-heading h6{
	position: relative;
	font-size: 18px;
	font-weight: 600;
	color: #222;
	line-height: 130%;
	letter-spacing: -1px;
	word-spacing: 1px;	
	margin-bottom: 10px;
	margin-top: 5px;
}
.section-heading p{
	font-size: 17px;
	font-weight: 500;
	color: #979797;
	line-height: 160%;
	padding-top: 15px;
	margin-bottom: 0px;
}

.section-heading small{
	font-size: 13px;
	font-weight: 700;
	color: #2387ea;
	line-height: 250%;
	text-transform: uppercase;
	padding-bottom: 5px;
}


.section-heading-line{
	content: '';
	display: block;	
	width: 100px;
	height: 3px;
	background: #2387ea;
	margin: auto;
	margin-top: 20px;
	margin-bottom: 5px;
}

.left-holder .section-heading-line{
	content: '';
	display: inline-block;	
	width: 100px;
	height: 3px;
	background: #2387ea;
	margin-top: 10px;
	margin-bottom: 5px;
}


.large-heading{
	position: relative;
	margin-bottom: 15px;
}

.large-heading h2{
	position: relative;
	font-size: 45px;
	font-weight: 700;
	color: #222;
	line-height: 140%;
	letter-spacing: -2px;
	word-spacing: 2px;	
	margin-bottom: 15px;
}
@media (max-width: 767px) {
	.large-heading h2{
		font-size: 30px;
	}
}

.large-heading h3{
	position: relative;
	font-size: 41px;
	font-weight: 700;
	color: #222;
	line-height: 140%;
	letter-spacing: -2px;
	word-spacing: 2px;	
	margin-bottom: 15px;
}

.large-heading h4{
	position: relative;
	font-size: 37px;
	font-weight: 700;
	color: #222;
	line-height: 140%;
	letter-spacing: -2px;
	word-spacing: 2px;	
	margin-bottom: 15px;
}

.large-heading h5{
	position: relative;
	font-size: 32px;
	font-weight: 700;
	color: #222;
	line-height: 130%;
	letter-spacing: -2px;
	word-spacing: 2px;	
	margin-bottom: 15px;
}

.large-heading small{
	font-size: 13px;
	font-weight: 700;
	color: #2387ea;
	line-height: 250%;
	text-transform: uppercase;
	padding-bottom: 5px;
}


.small-heading{
	width: 100%;
	overflow: hidden;
}

.small-heading span{
    position: relative;
    display: inline-block;	
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
    color: #222;    
}

.small-heading span::before {
    background-color: #dedede;
    content: '';
    display: block;
    width: 9999px;
    left: 100%;
    margin-left: 30px;
    top: 50%;
    margin-top: -.5px;
    height: 1px;
    position: absolute;
}

.small-heading span::after {
    background-color: #dedede;
    content: '';
    display: block;
    width: 9999px;
    right: 100%;
    margin-right: 30px;
    top: 50%;
    margin-top: -.5px;
    height: 1px;
    position: absolute;
}


/*-----------------------------------------
3. Preloader
-------------------------------------------*/
#preloader {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    overflow: visible;
    background: #fff;
    display: table;
    text-align: center;
} 
.loader {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    width: 100%;
    position: relative;
    width: 200px;
    height: 200px;
}



/*-----------------------------------------
4. Page Title (Breadcrumb)
-------------------------------------------*/
/*--- Style 1 ---*/
.page-title{
	display: block;
	position: relative;
	overflow: hidden;
	padding: 130px 0px 130px 0px;
	background-color: #eee;
	background-size: cover;	
	background-position: top;
	text-align: center;
}

.page-title h1{
	position: relative;
	font-size: 40px;
	font-weight: 700;
	letter-spacing: -1px;
	line-height: 1;
	color: #fff;
	text-align: center;
	margin-bottom: 10px;
}
@media (max-width: 767px) {
	.page-title h1{
		font-size: 30px;
	}
}

.page-title h1:after{
	content: "";
	display: block;
	width: 100px;
	height: 3px;
	background: rgba(255, 255, 255, .5);
	margin: 18px auto; 
}

.page-title p{
	position: relative;
	font-size: 16px;
	font-weight: 600;
	color: #fff;
	opacity: .8;
}

.page-title ul li{
	position: relative;
	display: inline-block;
}

.page-title ul li:after{
	content: "●";
	font-size: 13px;
	display: inline-block;
	color: #fff;
	opacity: .6;
	margin: 0px 2px 0px 4px;
}

.page-title ul li:last-child:after{
	content: none;
}

.page-title ul li a{
	position: relative;
	display: inline-block;
	font-size: 15px;
	font-weight: 500;
	line-height: 1;
	color: #fff;
	opacity: .5;
	text-align: center;
	margin-bottom: 5px;
	margin: 5px;
}

.page-title ul li a:hover{
	opacity: 1;
}


/*--- Style 2 ---*/
.page-title-2{
	position: relative;
	padding: 80px 0px 80px 0px;
	background-color: #fafafa;
	text-align: center;
}

.page-title-2 h1{
	position: relative;
	font-size: 30px;
	font-weight: 700;
	letter-spacing: -1px;
	line-height: 1;
	color: #222;
	margin-bottom: 10px;
}

.page-title-2 ul li{
	position: relative;
	display: inline-block;
}

.page-title-2 ul li a{
	position: relative;
	display: inline-block;
	font-size: 13px;
	font-weight: 600;
	line-height: 1;
	color: #aaa;
	text-align: center;
	margin-bottom: 5px;
	margin: 5px 7px 5px 0px;
}

.page-title-2 ul li:after{
	content: ' / ';
	font-size: 11px;
	font-weight: 600;
	display: inline-block;
	color: #aaa;
	margin-right: 2px;
}

.page-title-2 ul li:last-child:after{
	content: none;
}

.page-title-2 ul li a:hover{
	color: #2387ea;
}


/*--- Style 3 ---*/
.page-title-dark{
	position: relative;
	padding: 80px 0px 80px 0px;
	background-color: #222;
	text-align: center;
}

.page-title-dark h1{
	position: relative;
	font-size: 30px;
	font-weight: 700;
	letter-spacing: -1px;
	line-height: 1;
	color: #fff;
	margin-bottom: 10px;
}

.page-title-dark ul li{
	position: relative;
	display: inline-block;
}

.page-title-dark ul li a{
	position: relative;
	display: inline-block;
	font-size: 13px;
	font-weight: 600;
	line-height: 1;
	color: #999;
	text-align: center;
	margin-bottom: 5px;
	margin: 5px 7px 5px 0px;
}

.page-title-dark ul li:after{
	content: ' / ';
	font-size: 11px;
	font-weight: 600;
	display: inline-block;
	color: #999;
	margin-right: 2px;
}

.page-title-dark ul li:last-child:after{
	content: none;
}

.page-title-dark ul li a:hover{
	color: #fff;
}


/*--- Style 4 ---*/
.page-title-primary{
	position: relative;
	padding: 80px 0px 80px 0px;
	background-color: #2387ea;
	text-align: center;
}

.page-title-primary h1{
	position: relative;
	font-size: 30px;
	font-weight: 700;
	letter-spacing: -1px;
	line-height: 1;
	color: #fff;
	margin-bottom: 10px;
}

.page-title-primary ul li{
	position: relative;
	display: inline-block;
}

.page-title-primary ul li a{
	position: relative;
	display: inline-block;
	font-size: 13px;
	font-weight: 600;
	line-height: 1;
	color: #fff;
	opacity: .7;
	text-align: center;
	margin-bottom: 5px;
	margin: 5px 7px 5px 0px;
}

.page-title-primary ul li:after{
	content: ' / ';
	font-size: 11px;
	font-weight: 600;
	display: inline-block;
	color: #fff;
	opacity: .7;
	margin-right: 2px;
}

.page-title-primary ul li:last-child:after{
	content: none;
}

.page-title-primary ul li a:hover{
	opacity: 1;
}



/*--- Style 5 ---*/
.page-title-video{
	position: relative;
	padding: 130px 0px 130px 0px;
	background-color: transparent;
	background-size: cover;	
	background-position: top;
	text-align: center;
}

.page-title-video h1{
	position: relative;
	font-size: 40px;
	font-weight: 700;
	letter-spacing: -1px;
	line-height: 1;
	color: #fff;
	text-align: center;
	margin-bottom: 10px;
}

.page-title-video h1:after{
	content: "";
	display: block;
	width: 100px;
	height: 3px;
	background: rgba(255, 255, 255, .5);
	margin: 18px auto; 
}

.page-title-video p{
	position: relative;
	font-size: 16px;
	font-weight: 600;
	color: #fff;
	opacity: .8;
}

.page-title-video ul{
	position: relative;
}

.page-title-video ul li{
	position: relative;
	display: inline-block;
}

.page-title-video ul li:after{
	content: "●";
	font-size: 13px;
	display: inline-block;
	color: #fff;
	opacity: .6;
	margin: 0px 2px 0px 4px;
}

.page-title-video ul li:last-child:after{
	content: none;
}

.page-title-video ul li a{
	position: relative;
	display: inline-block;
	font-size: 15px;
	font-weight: 500;
	line-height: 1;
	color: #fff;
	opacity: .5;
	text-align: center;
	margin-bottom: 5px;
	margin: 5px;
}

.page-title-video ul li a:hover{
	opacity: 1;
}


/*--- Style 6 ---*/
.page-title-small{
	padding: 40px 0px 30px 0px;
	background-color: #fafafa;
	text-align: center;
	z-index: -1;
}

.page-title-small h1{
	position: relative;
	font-size: 27px;
	font-weight: 700;
	letter-spacing: -1px;
	line-height: 1;
	color: #222;
	margin-bottom: 10px;
}

.page-title-small ul li{
	position: relative;
	display: inline-block;
}

.page-title-small ul li a{
	position: relative;
	display: inline-block;
	font-size: 13px;
	font-weight: 600;
	line-height: 1;
	color: #aaa;
	text-align: center;
	margin-bottom: 5px;
	margin: 5px 7px 5px 0px;
}

.page-title-small ul li:after{
	content: ' ● ';
	font-size: 11px;
	font-weight: 600;
	display: inline-block;
	color: #aaa;
	margin-right: 2px;
}

.page-title-small ul li:last-child:after{
	content: none;
}

.page-title-small ul li a:hover{
	color: #2387ea;
}






/*-----------------------------------------
5. Fonts
-------------------------------------------*/
.open-sans{
	font-family: 'Open Sans', sans-serif !important;
}

.poppins{
	font-family: 'Poppins', sans-serif !important;
}


.montserrat{
	font-family: 'Montserrat', sans-serif !important;
}

.libre-baskerville{
	font-family: 'Libre Baskerville', serif !important;
}

.extra-light{
	font-weight: 100 !important;
}

.lighter{
	font-weight: 300 !important;
}

.light{
	font-weight: 400 !important;
}

.regular{
	font-weight: 500 !important;
}

.semi-bold{
	font-weight: 600 !important;
}

.bold{
	font-weight: 700 !important;
}

.extra-bold{
	font-weight: 800 !important;
}

.underline{
	text-decoration: underline;
}

.line-through{
	text-decoration: line-through;
}

.italic{
	font-style: italic !important;
}

.uppercase{ 
	text-transform: uppercase;
}

.capitalize{ 
	text-transform: capitalize !important;
}


.ls-none{
	letter-spacing: 0px !important;
}

.ls-minus{
	letter-spacing: -2px;
	word-spacing: 2px;
}


/*-----------------------------------------
6. Scroll To Top
-------------------------------------------*/
.scroll-to-top{
	font-size: 11px;  
	padding: 12px 6px; 
	text-align:center; 
	color: #fff;
	text-decoration: none;
	position:fixed;
	bottom:80px;
	right: 10px;
	display:none;
	border-radius: 50%;
	background: #111;
	width: 40px;
	height: 40px;  
	z-index: 9999;
	outline: none;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;   
}
.scroll-to-top i{
	color: #fff;
}

.scroll-to-top:hover{
	color: #fff;
	background: #2d2d2d;
}
 .scroll-to-top:hover i{
	color: #fff;
}
.scroll-to-top:visited{
	color: #fff;
	text-decoration:none;
}




/*-----------------------------------------
7. Helper Classes
-------------------------------------------*/

/*--------------------
a) Text-content
----------------------*/

.text-content-small{
	position: relative;
}

.text-content-small p{
	font-size: 13px !important;
	font-weight: 600;
	color: #999;
	line-height: 190%;	
	margin-bottom: 15px;
}

.text-content{
	position: relative;
}

.text-content p{
	font-size: 14px;
	font-weight: 500;
	color: #999;
	line-height: 190%;	
	margin-bottom: 15px;
}

.text-content-big{
	position: relative;
}

.text-content-big p{
	font-size: 15px;
	font-weight: 500;
	color: #999;
	line-height: 190%;	
	margin-bottom: 15px;
}

.small-text{
	color: #222;
	font-size: 13px;
	font-weight: 600;
}



/*--------------------
b) Image-properties
----------------------*/
/*--- Frames ---*/
.semi-rounded{
	border-radius: 3px !important;
}

.rounded-border{
	border-radius: 5px !important;
}

.extra-rounded-border{
	border-radius: 10px !important;
}

.circled-border{
	border-radius: 50% !important;
}

.no-rounded{
	border-radius: 0px !important;
}



/*--- Shadows ---*/
.shadow-black{
  -webkit-box-shadow: 0 20px 40px rgba(0,0,0,0.35);
  box-shadow: 0 20px 40px rgba(0,0,0,0.35);	
}
@media (max-width: 767px) {
	.shadow-black{
		margin-bottom: 15px;
	}
}

.shadow-primary{
  -webkit-box-shadow: 0 20px 40px rgba(0,0,0,0.2);
  box-shadow: 0 20px 40px rgba(0,0,0,0.2);	
}
@media (max-width: 767px) {
	.shadow-primary{
		margin-bottom: 15px;
	}
}

.shadow-light{
  -webkit-box-shadow: 0 20px 40px rgba(0,0,0,0.1);
  box-shadow: 0 20px 40px rgba(0,0,0,0.1);	
}
@media (max-width: 767px) {
	.shadow-light{
		margin-bottom: 15px;
	}
}


.shadow-extra-light{
  -webkit-box-shadow: 0 20px 40px rgba(0,0,0,0.05);
  box-shadow: 0 20px 40px rgba(0,0,0,0.05);	
}
@media (max-width: 767px) {
	.shadow-extra-light{
		margin-bottom: 15px;
	}
}



/*--- Extra ---*/
.black-white{
	filter: grayscale(100%);
}

.full-width{
	width: 100%;
}

.w-200{
	width: 200px;
	max-width: 100%;
}

.w-300{
	width: 300px;
	max-width: 100%;
}

.w-400{
	width: 400px;
	max-width: 100%;
}

.w-500{
	width: 500px;
	max-width: 100%;
}

.full-height{
	height: 100%;
}

.min-350{
	min-height: 350px;
}


/*--------------------
c) Buttons
----------------------*/
.primary-button{
	background: #2387ea;
	color: #fff;
	font-size: 11px;
	font-weight: 500;
	border: 2px solid #2387ea;
	border-radius: 50px;
	padding: 10px 15px 10px 15px;		
	-webkit-transition: all .4s ease;
	-moz-transition: all .4s ease;
	transition: all .4s ease;
}
.primary-button:hover {
	color: #fff;
	border-color: #222;
	background: #222;
}


.primary-button-bordered{
	background: transparent;
	color: #2387ea;
	font-size: 11px;
	font-weight: 500;
	border: 2px solid #2387ea;
	border-radius: 50px;
	padding: 10px 15px 10px 15px;		
	-webkit-transition: all .4s ease;
	-moz-transition: all .4s ease;
	transition: all .4s ease;
}
.primary-button-bordered:hover {
	color: #fff;
	border-color: #2387ea;
	background: #2387ea;
}


.white-button{
	background: #fff;
	color: #222;
	border: 2px solid #fff;
	font-size: 11px;
	font-weight: 500;
	border-radius: 50px;
	padding: 10px 15px 10px 15px;		
	-webkit-transition: all .4s ease;
	-moz-transition: all .4s ease;
	transition: all .4s ease;
}
.white-button:hover {
	color: #fff;
	background: transparent;
}


.white-button-bordered{
	background: transparent;
	color: #fff;
	border: 2px solid #fff;
	font-size: 11px;
	font-weight: 500;
	border-radius: 50px;
	padding: 10px 15px 10px 15px;		
	-webkit-transition: all .4s ease;
	-moz-transition: all .4s ease;
	transition: all .4s ease;
}
.white-button-bordered:hover {
	color: #222;
	background: #fff;
}


.dark-button{
	background: #2d2d2d;
	color: #fff;
	font-size: 11px;
	font-weight: 500;
	border: 2px solid #2d2d2d;
	border-radius: 50px;
	padding: 10px 15px 10px 15px;		
	-webkit-transition-duration: .3s;
	transition-duration: .3s;		
}
.dark-button:hover{
	color: #fff;
	border-color: #2387ea;
	background: #2387ea;
}


.dark-button-bordered{
	background: transparent;
	color: #222;
	font-size: 11px;
	font-weight: 500;
	border: 2px solid #222;
	border-radius: 50px;
	padding: 10px 15px 10px 15px;		
	-webkit-transition-duration: .3s;
	transition-duration: .3s;		
}
.dark-button-bordered:hover{
	color: #fff;
	background: #222;
	border-color: #222;
}



.grey-button{
	background: #eee;
	color: #222;
	font-size: 11px;
	font-weight: 500;
	border: 2px solid #eee;
	border-radius: 50px;
	padding: 10px 15px 10px 15px;		
	-webkit-transition: all .4s ease;
	-moz-transition: all .4s ease;
	transition: all .4s ease;
}
.grey-button:hover {
	color: #222;
	border-color: #eee;
	background: transparent;
}


.grey-button-bordered{
	background: transparent;
	color: #222;
	font-size: 11px;
	font-weight: 500;
	border: 2px solid #eee;
	border-radius: 50px;
	padding: 10px 15px 10px 15px;		
	-webkit-transition-duration: .3s;
	transition-duration: .3s;		
}
.grey-button-bordered:hover{
	color: #222;
	background: #eee;
}



.button-tag{
	display: inline-block;
	font-size: 12px;
	text-align: center;
	font-weight: 600;
	margin: 5px 5px 5px 0px;
	padding:  7px 12px 7px 12px !important;
}
.button-xs{
	position: relative;
	display: inline-block;
	text-align: center;
	font-size: 10px;
	font-weight: 600;
	padding: 8px 17px 8px 17px;
	margin: 5px 0px;		
}
.button-sm{
	position: relative;
	display: inline-block;
	font-weight: 600;
	font-size: 12px;
	text-align: center;
	padding: 9px 19px 9px 19px;
	margin: 5px 5px 5px 0px;	
}

.button-sm i{
	margin-left: 8px !important;
	margin-right: 8px !important;
}

@media (max-width: 767px) {
	.button-sm{	
		margin: 5px 0px;	
	}
}



.button-default{
	position: relative;
	display: inline-block;
	font-size: 14px;
	text-align: center;
	font-weight: 600;
	padding: 8px 23px 8px 23px;
	margin: 5px 0px;	
}
.button-md{
	position: relative;
	display: inline-block;
	font-size: 14px;
	text-align: center;
	font-weight: 600;
	padding: 11px 25px 11px 25px;
	margin: 5px 5px 5px 0px;	
}
@media (max-width: 767px) {
	.button-md{	
		margin: 5px 0px;	
	}
}
.button-md i{
	margin: 0px 5px;
}

.button-lg{
	position: relative;
	display: inline-block;
	font-size: 17px;
	text-align: center;
	font-weight: 600;
	padding: 12px 40px 13px !important;
	margin: 5px 5px 5px 0px;	
}

.button-pagination{
	position: relative;
	display: inline-block;
	font-size: 12px;
	text-align: center;
	font-weight: 700;
	padding: 6px 11px;
	margin: 5px 5px 5px 0px;	
}

.btn{
	margin: 2.5px 0px !important;
}



/*--------------------
d) Lists
----------------------*/
.primary-list{
	padding-left: 0px;
}
.primary-list li{
    list-style-type: none;
    color: #222;
    font-size: 14px;
    font-weight: 600;
    margin-top: 15px;  
}
.primary-list li i{
    color: #2387ea;
    padding-right: 10px;
}

.dark-list{
	padding-left: 0px;
}
.dark-list li{
    list-style-type: none;
    color: #222;
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;  
}
.dark-list li i{
    color: #222;
    padding-right: 15px;
}


.white-list{
	padding-left: 0px;
}
.white-list li{
    list-style-type: none;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    margin-top: 15px;  
}
.white-list li i{
    color: #2387ea;
    padding-right: 15px;
}

/*--------------------
e) Color Control
----------------------*/
.primary-color,
.primary-color h1, 
.primary-color h2, 
.primary-color h3, 
.primary-color h4, 
.primary-color h5, 
.primary-color h6, 
.primary-color p, 
.primary-color i, 
.primary-color strong, 
.primary-color span{
	color: #2387ea !important;
}
.dark-color,
.dark-color h1, 
.dark-color h2, 
.dark-color h3, 
.dark-color h4, 
.dark-color h5, 
.dark-color h6, 
.dark-color p, 
.dark-color i, 
.dark-color strong, 
.dark-color span{
	color: #222 !important;
}
.grey-color, 
.grey-color h1, 
.grey-color h2, 
.grey-color h3, 
.grey-color h4, 
.grey-color h5, 
.grey-color h6, 
.grey-color p, 
.grey-color i, 
.grey-color strong, 
.grey-color span{
	color: #999 !important;
}
.white-color, 
.white-color h1, 
.white-color h2, 
.white-color h3, 
.white-color h4, 
.white-color h5, 
.white-color h6, 
.white-color p, 
.white-color i, 
.white-color strong, 
.white-color span{
	color: #fff !important;
}




/*--------------------
f) Video Box
----------------------*/
.video-video-box{
	position: relative;
	display: block;
	width: 100%;
	height: auto;	
}
.video-video-box img{
	width: 100%;
}
.video-video-box-overlay{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
	opacity: 1;
	transition: .5s ease;
}

.video-video-box-button{
	position: absolute;
	overflow: hidden;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
}

.video-video-box-button button{
	color: #222;
	font-size: 25px;
	background: rgba(255, 255, 255, 1);
	width: 100px;
	height: 100px;
	border-radius: 50%;
	padding-top: 5px;
	padding-left: 12px;
	margin: 30px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;	
}

.video-video-box-button button:hover{
	color: #222;
	background: rgba(255, 255, 255, .7);
}
@media (max-width: 991px) {
	.video-video-box-button button{
		color: #222;
		font-size: 15px;
		background: rgba(255, 255, 255, .7);
		width: 60px;
		height: 60px;
		border-radius: 50%;
		padding-top: 1px;
		padding-left: 9px;
		-webkit-transition-duration: .3s;
		transition-duration: .3s;	
	}	
}



/*--- Small Size ---*/
.video-video-box-button-sm{
	position: absolute;
	overflow: hidden;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
}

.video-video-box-button-sm button{
	color: #222;
	font-size: 20px;
	background: rgba(255, 255, 255, 1);
	width: 80px;
	height: 80px;
	border-radius: 50%;
	padding-top: 2.5px;
	padding-left: 8.5px;
	margin: 30px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;	
}

.video-video-box-button-sm button:hover{
	color: #222;
	background: rgba(255, 255, 255, .7);
}
@media (max-width: 991px) {
	.video-video-box-button-sm button{
		color: #222;
		font-size: 15px;
		background: rgba(255, 255, 255, .7);
		width: 60px;
		height: 60px;
		border-radius: 50%;
		padding-top: 1px;
		padding-left: 9px;
		-webkit-transition-duration: .3s;
		transition-duration: .3s;	
	}	
}



/*--- Extra Small Size ---*/
.video-video-box-button-xs{
	position: absolute;
	overflow: hidden;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
}

.video-video-box-button-xs button{
	color: #222;
	font-size: 15px;
	background: rgba(255, 255, 255, 1);
	width: 70px;
	height: 70px;
	border-radius: 50%;
	padding-top: 2.5px;
	padding-left: 8.5px;
	margin: 30px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;	
}

.video-video-box-button-xs button:hover{
	color: #222;
	background: rgba(255, 255, 255, .7);
}
@media (max-width: 991px) {
	.video-video-box-button-xs button{
		color: #222;
		font-size: 15px;
		background: rgba(255, 255, 255, .7);
		width: 60px;
		height: 60px;
		border-radius: 50%;
		padding-top: 1px;
		padding-left: 9px;
		-webkit-transition-duration: .3s;
		transition-duration: .3s;	
	}	
}



/*--- Video Buttons ---*/
.video-button button{
	position: relative;
	color: #222;
	font-size: 25px;
	background: rgba(255, 255, 255, 1);
	width: 100px;
	height: 100px;
	border-radius: 50%;
	padding-top: 5px;
	padding-left: 12px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;		
}
.video-button button:hover{
	color: #222;
	background: rgba(255, 255, 255, .7);
}
.video-button h4{
	color: #fff;
	font-weight: 400;
	margin-top: 15px;
}


/*--- Small Size ---*/
.video-button-sm button{
	position: relative;
	color: #222;
	font-size: 20px;
	background: rgba(255, 255, 255, 1);
	width: 80px;
	height: 80px;
	border-radius: 50%;
	padding-top: 5px;
	padding-left: 12px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;		
}
.video-button-sm button:hover{
	color: #222;
	background: rgba(255, 255, 255, .7);
}

.video-button-sm h4{
	color: #fff;
	font-weight: 400;
	margin-top: 10px;
}



/*--- Extra Small Size ---*/
.video-button-xs button{
	position: relative;
	color: #222;
	font-size: 15px;
	background: rgba(255, 255, 255, 1);
	width: 70px;
	height: 70px;
	border-radius: 50%;
	padding-top: 2px;
	padding-left: 10px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;		
}
.video-button-xs button:hover{
	color: #222;
	background: rgba(255, 255, 255, .7);
}
.video-button-xs h4{
	color: #fff;
	font-weight: 400;
	margin-top: 10px;
}

/*--------------------
g) Pagination
----------------------*/
.paginator{
	display: inline-block;
	border-radius: 10px;
	font-size: 15px;
	font-weight: 600;
	color: #2d2d2d;
	background: #f1f1f1;
	padding: 10px 15px 10px 15px;
	margin: 3px;
}
.paginator:hover{
	color: #fff;
	background: #bda87f;
}
.paginator:focus{
	color: #fff;
	background: #bda87f;
}
.active-page{
	color: #fff;
	background: #bda87f;	
}


/*--------------------
h) Overlays
----------------------*/
.black-overlay-light:before {
	background: rgba(0, 0, 0, 0.03); 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
}

.black-overlay-5:before {
	background: rgba(0, 0, 0, 0.05); 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
}

.black-overlay-10:before {
	background: rgba(0, 0, 0, 0.1); 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
}

.black-overlay-20:before {
	background: rgba(0, 0, 0, 0.2); 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
}

.black-overlay-30:before {
	background: rgba(0, 0, 0, 0.3); 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
}

.black-overlay-40:before {
	background: rgba(0, 0, 0, 0.4); 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
}

.black-overlay-50:before {
	background: rgba(0, 0, 0, 0.5); 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
}

.black-overlay-60:before {
	background: rgba(0, 0, 0, 0.6); 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
}

.black-overlay-70:before {
	background: rgba(0, 0, 0, 0.7); 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
}

.black-overlay-80:before {
	background: rgba(0, 0, 0, 0.8); 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
}

.black-overlay-90:before {
	background: rgba(0, 0, 0, 0.9); 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
}

.black-overlay-100:before {
	background: rgba(0, 0, 0, 1); 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
}


.primary-overlay-10:before {
	background: #2387ea; 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
	opacity: .1;
}

.primary-overlay-20:before {
	background: #2387ea; 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
	opacity: .2;
}

.primary-overlay-30:before {
	background: #2387ea; 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
	opacity: .3;
}

.primary-overlay-40:before {
	background: #2387ea; 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
	opacity: .4;
}

.primary-overlay-50:before {
	background: #2387ea; 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
	opacity: .5;
}

.primary-overlay-60:before {
	background: #2387ea; 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
	opacity: .6;
}

.primary-overlay-70:before {
	background: #2387ea; 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
	opacity: .7;
}

.primary-overlay-80:before {
	background: #2387ea; 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
	opacity: .8;
}

.primary-overlay-90:before {
	background: #2387ea; 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
	opacity: .9;
}

.primary-overlay-100:before {
	background: #2387ea; 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
	opacity: 1;
}


.white-overlay-10:before {
	background: #fff; 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
	opacity: .1;
}

.white-overlay-20:before {
	background: #fff; 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
	opacity: .2;
}

.white-overlay-30:before {
	background: #fff; 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
	opacity: .3;
}

.white-overlay-40:before {
	background: #fff; 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
	opacity: .4;
}

.white-overlay-50:before {
	background: #fff; 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
	opacity: .5;
}

.white-overlay-60:before {
	background: #fff; 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
	opacity: .6;
}

.white-overlay-70:before {
	background: #fff; 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
	opacity: .7;
}

.white-overlay-80:before {
	background: #fff; 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
	opacity: .8;
}

.white-overlay-90:before {
	background: #fff; 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
	opacity: .9;
}

.white-overlay-100:before {
	background: #fff; 
	content: ""; 
	height: 100%; 
	left: 0; 
	position: absolute; 
	top: 0; 
	width: 100%; 
	z-index: 0; 
	opacity: 1;
}



.white-background{
	background: #fff;
}

.black-background{
	background: #000;
}


.opacity-01{
	opacity: 0.1 !important;
} 
.opacity-02{
	opacity: 0.2 !important;
} 
.opacity-03{
	opacity: 0.3 !important;
} 
.opacity-04{
	opacity: 0.4 !important;
} 
.opacity-05{
	opacity: 0.5 !important;
} 
.opacity-06{
	opacity: 0.6 !important;
} 
.opacity-07{
	opacity: 0.7 !important;
} 
.opacity-08{
	opacity: 0.8 !important;
} 
.opacity-09{
	opacity: 0.9 !important;
} 



/*--------------------
i) Forms
----------------------*/
/*--- Primary Form ---*/
.primary-form{
	position: relative;
}

.primary-form label{
	font-size: 14px;
	color: #222;
	font-weight: 600;
    display: inline-block;
    margin-bottom: 10px;
}

.primary-form input, .primary-form textarea, .primary-form select{
    display: block;
    border-radius: 5px;
    border: none;
    outline: 0;
    background: #f4f4f4;
    font-size: 13px;
    font-weight: 500;
    width: 100%;
    margin-bottom: 17px;
    padding: 12px 16px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.primary-form input:focus, .primary-form textarea:focus, .primary-form select:focus{
	background: #ececec;
}

.primary-form textarea{
	min-height: 100px;
}

.primary-form button{
	margin-top: 0px;
}



/*--- Bordered Form ---*/
.bordered-form{
	position: relative;
}

.bordered-form label{
	font-size: 14px;
	color: #222;
	font-weight: 600;
    display: inline-block;
    margin-bottom: 10px;
}

.bordered-form input, .bordered-form textarea, .bordered-form select{
    display: block;
    border-radius: 5px;
    border: none;
    outline: 0;
    border: 2px solid #f4f4f4;
    background: transparent;
    font-size: 13px;
    font-weight: 600;
    width: 100%;
    margin-bottom: 17px;
    padding: 12px 16px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.bordered-form input:focus, .bordered-form textarea:focus, .bordered-form select:focus{
	background: #f4f4f4;
}

.bordered-form textarea{
	min-height: 100px;
}

.bordered-form button{
	margin-top: 0px;
}



/*--- White Form ---*/
.white-form{
	position: relative;
}

.white-form label{
	font-size: 14px;
	color: #222;
	font-weight: 600;
    display: inline-block;
    margin-bottom: 10px;
}

.white-form input, .white-form textarea, .white-form select{
    display: block;
    border-radius: 5px;
    border: none;
    outline: 0;
    background: #fff !important;
    border: 1px solid #f4f4f4;
    background: transparent;
    font-size: 13px;
    font-weight: 600;
    width: 100%;
    margin-bottom: 17px;
    padding: 12px 16px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.white-form input:focus, .white-form textarea:focus, .white-form select:focus{
	border-color: #eee;
}

.white-form textarea{
	min-height: 100px;
}

.white-form button{
	margin-top: 0px;
}



/*--- Dark Form ---*/
.dark-form{
	position: relative;
}

.dark-form label{
	font-size: 14px;
	color: #fff;
	font-weight: 600;
    display: inline-block;
    margin-bottom: 10px;
}

.dark-form input, .dark-form textarea, .dark-form select{
    display: block;
    color: #fff;
    border-radius: 5px;
    border: none;
    outline: 0;
    background: #333;
    font-size: 13px;
    font-weight: 600;
    width: 100%;
    margin-bottom: 17px;
    padding: 12px 16px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.dark-form input:focus, .dark-form textarea:focus, .dark-form select:focus{
	background: #323232;
}

.dark-form textarea{
	min-height: 100px;
}

.dark-form button{
	margin-top: 0px;
}



/*--- Transparent Form ---*/
.transparent-form{
	position: relative;
}

.transparent-form label{
	font-size: 14px;
	color: #fff;
	font-weight: 600;
    display: inline-block;
    margin-bottom: 10px;
}

.transparent-form input::placeholder, .transparent-form textarea::placeholder, .transparent-form select{
	color: rgba(255, 255, 255, .6);
}

.transparent-form input, .transparent-form textarea, .transparent-form select{
    display: block;
    color: #fff;
    border-radius: 5px;
    border: none;
    outline: 0;
    background: rgba(255, 255, 255, .05);
    font-size: 13px;
    font-weight: 600;
    width: 100%;
    margin-bottom: 17px;
    padding: 12px 16px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.transparent-form input:focus, .transparent-form textarea:focus, .transparent-form select:focus{
	background: rgba(255, 255, 255, .1);
}

.transparent-form textarea{
	min-height: 100px;
}

.transparent-form button{
	margin-top: 0px;
}



/*--- Checkbox Styles ---*/
.checkbox{
	display: inline-block;
	margin-right: 15px;
	margin-top: 10px;
}
.checkbox input[type="checkbox"] {
    display: none;
}

.checkbox label {
    position: relative;
    font-weight: 600;
    font-size: 13px;
    display: inline-block;
    padding-left: 35px;
    padding-top: 6px;
}

.checkbox label::before,
.checkbox label::after {
    position: absolute;
    content: "";
    display: inline-block;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;    
}

/*Outer box of the fake checkbox*/
.checkbox label::before{
    height: 27px;
    width: 27px;
    border: 1px solid;
    border-radius: 5px;
    left: 0px;
    top: 3px;
    color: #ccc;
}

/*Checkmark of the fake checkbox*/
.checkbox label::after {
    height: 7px;
    width: 13px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    transform: rotate(-45deg);
    left: 7px;
    top: 11px;
}

/*Hide the checkmark by default*/
.checkbox input[type="checkbox"] + label::after {
    content: none;
}

/*Unhide on the checked state*/
.checkbox input[type="checkbox"]:checked + label::after {
    content: "";
    color: #fff;
}

/*Adding focus styles on the outer-box of the fake checkbox*/
.checkbox input[type="checkbox"]:focus + label::before {
    outline: none;
}

.checkbox input[type="checkbox"]:checked + label::before {
    background-color: #2387ea;
    color: #2387ea;
}



/*--- Radio Styles ---*/
.radio {
	display: none !important;
}

.radio-custom {
	width: 30px;
	height: 30px;
	border: 1px solid #ccc;
	border-radius: 3px;
	margin-left: 25px;
	margin-right: 5px;
	position: relative;
}

.radio-custom, .radio-label {
	display: inline-block;
	vertical-align: middle;
	margin-top: 10px;
}

@media (max-width: 767px) {
	.radio-label {
		display: block;
	}
	.radio-custom {
		text-align: center;
		margin: 0px 15px;
	}
}

.radio:checked + .radio-custom::before {
	content: "";
	display: block;
	position: absolute;
	top: 7px;
	right: 7px;
	bottom: 7px;
	left: 7px;
	background: #2387ea;
	border-radius: 2px;
}

.radio-custom, .radio:checked + .radio-custom::before {
	border-radius: 50%;
}

.radio-label{
    font-weight: 600;
    font-size: 13px;
}









/*-----------------------------------------
8. Service Boxes
-------------------------------------------*/

/*--------------------
Style 1
----------------------*/
.service-box{
	position: relative;
}

.service-box h4{
	color: #222;
	font-size: 20px;
	font-weight: 600;
	margin-top: 10px;
}

.service-box p{
	color: #999;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.5;
	margin-top: 10px;
}


/*--------------------
Style 2
----------------------*/
.service-overlay{
	position: relative;
}

.service-overlay img{
	width: 100%; 
	border-radius: 10px;
}

.service-overlay-box{
	position: relative;
	background: #fff;
	border-radius: 10px;
	transform: translateY(-45px);
	padding: 20px;
	margin-left: 20px;
	margin-right: 20px; 
	-webkit-box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow:    0px 0px 35px 0px rgba(0, 0, 0, 0.1);
	box-shadow:         0px 0px 35px 0px rgba(0, 0, 0, 0.1);
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;  			
}

.service-overlay-box h4{
	color: #222;
	font-size: 18px;
	font-weight: 600;
	margin-top: 5px;
}

.service-overlay-box p{
	color: #999;
	font-size: 13px;
	font-weight: 600;
	line-height: 1.8;
	margin-top: 10px;
}

.service-overlay-box a{
	display: inline-block;
	color: #2387ea;
	font-size: 11px;
	text-transform: uppercase;
	font-weight: 700;
	margin-top: 15px;
}

.service-overlay:hover .service-overlay-box{
	transform: translateY(-60px);
}

.service-overlay:hover .service-overlay-box{
	background: #2387ea;
}

.service-overlay:hover .service-overlay-box h4{
	color: #fff;
}

.service-overlay:hover .service-overlay-box p{
	color: #fff;
}

.service-overlay:hover .service-overlay-box a{
	color: #fff;
}



/*--------------------
Style 3
----------------------*/
.service-block{
	position: relative;
	overflow: hidden;
	background: #fff;
	border: 1px solid #eee;
	border-radius: 10px;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.08);
    -moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.08);
    box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.08); 	
	margin: 15px 0px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;	
}

.service-block:hover{
    -webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
    -moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
    box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16); 
}

.service-block img{
	width: 100%;
	margin-bottom: 5px;
}

.service-block-inner{
	padding: 15px 25px 20px 25px;
}

.service-block h6{
	display: inline-block;
	background: #2387ea;
	color: #fff;
	font-size: 12px;
	font-weight: 600;
	border-radius: 5px;
	padding: 5px;
	margin-right: 5px;
}

.service-block h4{
	display: inline-block;
	color: #222;
	font-size: 18px;
	font-weight: 600;
	line-height: 1.5;
}

.service-block h4 a{
	color: #222;
}

.service-block h4 a:hover{
	color: #2387ea;
}

.service-block-button{
	overflow: hidden;
	display: block;
	border-top: 1px solid #ececec;
	margin-top: 15px;
}

.service-block-button a{
	display: inline-block;
	color: #222;
	font-size: 14px;
	font-weight: 600;
	margin-top: 17px;
}

.service-block-button a i{
	font-size: 11px;
	margin-left: 4px;
}

.service-block-button a:hover{
	color: #2387ea;
}

.service-block p{
	color: #999;
	font-size: 15px;
	font-weight: 500;
	line-height: 1.7;
	margin-top: 8px;
}



/*--------------------
Style 4
----------------------*/
.service-list{
	position: relative;
	border-bottom: 1px solid #ececec;
	padding: 35px 0px;
}

.service-list:last-child{
	border-bottom: none;
}

.service-list img{
	border-radius: 10px;
}

.service-list h6{
	display: inline-block;
	background: #222;
	color: #fff;
	font-size: 12px;
	font-weight: 600;
	border-radius: 5px;
	padding: 5px;
	margin-top: 12px;
}

.service-list h3{
	font-size: 21px;
	font-weight: 600;
	color: #222;
	margin: 10px 0px;
}

.service-list p{
	color: #888;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.8;
}


/*--------------------
Style 5
----------------------*/
.service-block-image{
	position: relative;
	overflow: hidden;
	outline: 2px solid rgba(255,255,255,0.2);
	outline-offset: -13px;
	margin: 15px 0px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;  		
}

.service-block-image img{
	display: block;
	border-radius: 10px;
	width: 100%;
	height: auto;
}


.service-block-image-overlay{
	position: absolute;
	border-radius: 10px;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
	opacity: 1;
	transition: .5s ease;
	background: linear-gradient(transparent 20%, rgba(0, 0, 0, 1));
}

.service-block-image-overlay h3{
	width: 100%;
	display: block;
	color: #fff;
	font-size: 21px;
	font-weight: 700;
	position: absolute;	
	text-align: center;
	bottom: 60px;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	padding: 0px 30px;
}
@media (max-width: 991px) {
	.service-block-image-overlay h3{
		bottom: 80px;
		left: 50%;
		transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		padding: 0px 30px;
	}	
}

.service-block-image-overlay a{
	position: absolute;
	display: inline-block;
	color: #fff;
	background: #2387ea;
	color: #fff;
	font-size: 11px;
	font-weight: 500;
	border: 2px solid #2387ea;	
	border-radius: 50px;
	text-align: center;	
	font-weight: 600;
	font-size: 12px;
	text-align: center;
	padding: 9px 19px 9px 19px;
	bottom: 10px;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
}

.service-block-image-overlay a:hover{
	background: #222;
	border-color: #222;
}



/*--------------------
Style 6
----------------------*/
.service-box-2{
	position: relative;
	overflow: hidden;
	border-radius: 10px;
	margin: 15px 0px;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.08);
    -moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.08);
    box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.08); 		
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;     
}

.service-box-2:hover{
    -webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
    -moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
    box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16); 	
}

.service-box-2-inner{
	background: #fff;
	padding: 20px;
}

.service-box-2 h4{
	color: #222;
	font-size: 17px;
	font-weight: 600;
	margin-top: 1px;
}

.service-box-2 p{
	color: #999;
	font-size: 14px;
	font-weight: 600;
	line-height: 1.5;
	margin-top: 3px;
}

.service-box-2 a{
	display: inline-block;
	color: #2387ea;
	background: #eee;
	border-radius: 50%;
	font-size: 13px;
	width: 50px;
	height: 50px;
	padding: 16px 21px;
}
.service-box-2:hover a{
	color: #fff;
	background: #2387ea;
}



/*--------------------
Style 7
----------------------*/
.service-block-2{
	position: relative;
	overflow: hidden;
	background: #fff;
	margin: 15px 0px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;	
}

.service-block-2:hover img{
	transform: scale(1.05);
}


.service-block-2 img{
	height: 100%;
	width: 100%;
	margin-bottom: 5px;
}

.service-block-2-inner{
	padding: 15px 0px;
}


.service-block-2 h4{
	display: inline-block;
	color: #222;
	font-size: 17px;
	font-weight: 600;
	line-height: 1.5;
}

.service-block-2 h4 a{
	color: #222;
}

.service-block-2 h4 a:hover{
	color: #2387ea;
}

.service-block-2-button{
	overflow: hidden;
	display: block;
	border-top: 1px solid #ececec;
	margin-top: 15px;
}

.service-block-2-button a{
	display: inline-block;
	color: #222;
	font-size: 14px;
	font-weight: 600;
	margin-top: 17px;
}

.service-block-2-button a i{
	font-size: 11px;
	margin-left: 4px;
}

.service-block-2-button a:hover{
	color: #2387ea;
}

.service-block-2 p{
	color: #999;
	font-size: 15px;
	font-weight: 500;
	line-height: 1.7;
	margin-top: 8px;
}



/*--------------------
Style 8
----------------------*/
.service-block-image-2{
	position: relative;
	overflow: hidden;
	border-radius: 10px;
	margin: 15px 0px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;  		
}

.service-block-image-2:hover{
	transform: translateY(-10px);
    -webkit-box-shadow: 0 20px 40px rgba(0,0,0,0.3);
    box-shadow: 0 20px 40px rgba(0,0,0,0.3);	
}

.service-block-image-2 img{
	display: block;
	border-radius: 10px;
	width: 100%;
	height: auto;
}

.service-block-image-overlay-2{
	position: absolute;
	border-radius: 10px;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
	opacity: 1;
	transition: .5s ease;
	background: linear-gradient(transparent 0%, rgba(0, 0, 0, .9) 70%);
}

.service-block-image-overlay-2 h3{
	width: 100%;
	display: block;
	color: #fff;
	font-size: 18px;
	font-weight: 600;
	position: absolute;	
	text-align: left;
	bottom: 60px;
	padding: 0px 30px;
}
@media (max-width: 991px) {
	.service-block-image-overlay-2 h3{
		bottom: 60px;
		padding: 0px 30px;
	}	
}

.service-block-image-overlay-2 h4{
	width: 100%;
	display: block;
	color: #fff;
	opacity: .7;
	font-size: 14px;
	font-weight: 600;
	position: absolute;	
	text-align: left;
	bottom: 35px;
	padding: 0px 30px;
}

.service-block-image-overlay-2 a{
	color: #fff;
}




/*-----------------------------------------
9. Feature Boxes
-------------------------------------------*/

/*--------------------
Style 1
----------------------*/
.feature-block{
	position: relative;
	margin: 20px 0px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;  			
}
.feature-block h4{
	color: #222;
	font-size: 17.5px;
	font-weight: 600;
	margin: 15px 0px;
}
.feature-block p{
	color: #888;
	font-size: 13px;
	font-weight: 600;
	line-height: 1.8;
	margin-top: 15px;
}
.feature-block i{
	font-size: 55px;
	color: #2387ea;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;  		
}
.feature-block img{
	width: 190px;
	max-width: 100%;
}

.feature-block a{
	display: inline-block;
	font-size: 14px;
	font-weight: 600;
	color: #2387ea;
	margin-top: 15px;
}
.feature-block a i{
	font-size: 11px;
	color: #2387ea;
	margin-left: 2px;
}

.feature-block a:hover{
	color: #222;
}
.feature-block a:hover i{
	color: #222;
}


/*--- Bigger Size ---*/
.feature-block-2{
	position: relative;
	margin: 15px 0px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;  			
}
.feature-block-2:hover{	
	-webkit-box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
	-moz-box-shadow:    0 15px 40px rgba(0, 0, 0, 0.2);
	box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);		
	transform: translateY(-5px);
}

.feature-block-2-no-hover{
	position: relative;
}
.feature-block-2-no-hover:hover{	
	-webkit-box-shadow: 0px 0px 0px 0px transparent;
	-moz-box-shadow: 0px 0px 0px 0px transparent;
	box-shadow: 0px 0px 0px 0px transparent;		
	transform: translateY(0px);
}

.feature-block-2 h4{
	color: #222;
	font-size: 18px;
	font-weight: 600;
	margin: 15px 0px;
}

.feature-block-2 p{
	color: #888;
	font-size: 15px;
	font-weight: 500;
	line-height: 1.8;
	margin-top: 15px;
}
.feature-block-2 i{
	font-size: 60px;
	color: #2387ea;
}

.feature-block-2 img{
	width: 100px;
	margin-bottom: 5px;
}

.feature-block-2 a{
	display: inline-block;
	font-size: 14px;
	font-weight: 600;
	color: #2387ea;
	margin-top: 20px;
}
.feature-block-2 a i{
	font-size: 11px;
	color: #2387ea;
	margin-left: 2px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;  			
}
.feature-block-2 a:hover{
	color: #222;
}
.feature-block-2 a:hover i{
	color: #222;
}


/*--- Small Size ---*/
.feature-block-3{
	position: relative;
	margin: 20px 0px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;  			
}
.feature-block-3 h4{
	color: #222;
	font-size: 15px;
	font-weight: 600;
	margin: 15px 0px;
}
.feature-block-3 p{
	color: #888;
	font-size: 13px;
	font-weight: 600;
	line-height: 1.8;
	margin-top: 0px;
}
.feature-block-3 i{
	font-size: 50px;
	color: #2387ea;
}
.feature-block-3 img{
	width: 90px;
}




/*---Makes All Texts White---*/
.feature-white h4{
	color: #fff;
	font-size: 17.5px;
	font-weight: 700;
	margin: 15px 0px;
}
.feature-white p{
	color: #fff;
	font-size: 13px;
	font-weight: 600;
	line-height: 1.8;
	margin-top: 15px;
}
.feature-white a{
	color: #fff;
}
.feature-white a i{
	color: #fff;
}
.feature-white a:hover{
	color: #fff;
}
.feature-white a:hover i{
	color: #fff;
}

/*---Makes All Elements White---*/
.feature-all-white h4{
	color: #fff;
	font-size: 17.5px;
	font-weight: 700;
	margin: 15px 0px;
}
.feature-all-white p{
	color: #fff;
	font-size: 13px;
	font-weight: 600;
	line-height: 1.8;
	margin-top: 15px;
}
.feature-all-white i{
	font-size: 55px;
	color: #fff;
}
.feature-all-white a{
	color: #fff;
}
.feature-all-white a i{
	color: #fff;
}
.feature-all-white a:hover{
	color: #fff;
}
.feature-all-white a:hover i{
	color: #fff;
}


/*--- Additional Feature Styles ---*/
.feature-bg{
	background: #fff;
	border-radius: 10px;
}

.feature-shadow{
	-webkit-box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
	-moz-box-shadow:    0 15px 40px rgba(0, 0, 0, 0.1);
	box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);	
}

.feature-bordered{
	border: 1px solid #f6f6f6;
}

.feature-hover-shadow{
	position: relative;
}
.feature-hover-shadow:hover{
	-webkit-box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
	-moz-box-shadow:    0 15px 40px rgba(0, 0, 0, 0.2);
	box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);	
}



/*--------------------
Style 2
----------------------*/
.feature-float{
	position: relative;
	display: block;
	padding: 15px 0px;
	margin: 15px 0px;
}

.feature-float-icon{
	text-align: center;
	float: left;
	width: 19%;
}

.feature-float-icon i{
	display: inline-block;
	color: #2387ea;	
	font-size: 45px;
	margin-right: 20px;
}

.feature-float-content{
	float: left;
	width: 81%;
	padding: 0px 5px;
}

.feature-float h4{
	display: block;
	color: #222;	
	font-size: 17px;
	font-weight: 600;
	margin-bottom: 7px;
	vertical-align: top;
}

.feature-float h4 a{
	color: #222;	
}

.feature-float h4 a:hover{
	color: #2387ea;	
}

.feature-float p{
	display: block;
	color: #888;	
	font-size: 14px;
	line-height: 1.9;
	font-weight: 400;
}


/*--------------------
Style 3 (Number Boxes)
----------------------*/
.number-box{
	position: relative;
}


@media (max-width: 991px) {
	.number-box{
		position: relative;
		margin: 15px 0px;
	}
}

.number-box h3{
	display: inline-block;
	color: #e2e2e2;	
	font-size: 55px;
	font-weight: 700;	
}

.number-box h4{
	display: block;
	color: #222;	
	font-size: 16px;
	font-weight: 600;	
	margin-top: 12px;
}

.number-box p{
	position: relative;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.7;
	color: #999;
	margin: 12px 0px;	
}

.number-box p:after{
	content: "";
	display: block;
	width: 40px;
	height: 4px;
	background: #2387ea;	
	margin-top: 20px;
}


/*--------------------
Style 4 (Number Boxes)
----------------------*/
.number-box-2{
	position: relative;
}


@media (max-width: 991px) {
	.number-box-2{
		position: relative;
		margin: 15px 0px;
	}
}

.number-box-2 h3{
	display: inline-block;
	color: #222;	
	letter-spacing: -1px;
	font-size: 60px;
	font-weight: 300;	
}

.number-box-2 h4{
	display: block;
	color: #222;	
	font-size: 16px;
	font-weight: 600;	
	margin-top: 5px;
}

.number-box-2 h4:before{
	content: "●";
	font-size: 20px;
	display: inline-block;
	color: #2387ea;	
	margin-right: 8px;
}


.number-box-2 p{
	position: relative;
	font-size: 13px;
	font-weight: 600;
	line-height: 1.7;
	color: #999;
	margin: 12px 0px;	
}



/*--------------------
Style 4
----------------------*/
.feature-block-rounded{
	position: relative;
	padding: 5px;
	margin: 30px 0px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;  			
}

.feature-block-rounded h4{
	position: relative;
	color: #222;
	font-size: 17px;
	font-weight: 600;
	margin: 50px 0px 10px 0px;
}
.feature-block-rounded h4 a{
	position: relative;
	color: #222;
	font-size: 17px;
	font-weight: 600;
	margin: 50px 0px 10px 0px;
}
.feature-block-rounded h4 a:hover{
	color: #2387ea;
}

.feature-block-rounded p{
	color: #888;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.8;
	margin-top: 15px;
}
.feature-block-rounded i{
	background: #f9f9f9;
	border-radius: 50%;
	font-size: 50px;
	color: #2387ea;
	padding: 35px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;  	
}

.feature-block-rounded:hover i{
	background: #2387ea;
	color: #fff;
}



/*--------------------
Style 5
----------------------*/
.features-range{
	position: relative;
	background: #2387ea;
	border-radius: 10px;
	padding: 15px;
}


@media (max-width: 767px) {
	.features-range{
		border-radius: 0px;
	}	
}







/*-----------------------------------------
10. Process Steps (icon Boxes)
-------------------------------------------*/
/*--------------------
Sizes XS, SM, MD
----------------------*/
.process-step-xs{
	position: relative;
	margin: 15px 0px;
}

.process-step-xs .process-step-icon i{
    color: #2387ea;	
    font-size: 47px;
}	

.process-step-xs h3{
	position: relative;
	font-size: 14px;
	font-weight: 600;
	color: #222;
	margin: 10px 0px;	
}

.process-step-xs p{
	position: relative;
	font-size: 14px;
	font-weight: 600;
	color: #999;
	margin-top: 10px;	
}


.process-steps-xs{
	position: relative;
	padding: 0px;
	margin: 0px;
}


@media (max-width: 991px) {
	.process-steps-xs li:first-child{
		margin-left: 15px;
	}	
}
@media (max-width: 767px) {
	.process-steps-xs li:first-child{
		margin-left: 0px;
	}	
}

.process-steps-xs li{
    position: relative;
    float: left;	
	list-style-type: none;
	display: inline-block;
	padding: 0px 35px;
}

.process-steps-xs .left-holder li:first-child{
	padding-left: 0px;
}
@media (max-width: 991px) {
	.process-steps-xs .left-holder li:first-child{
		padding-left: 35px;
	}
}
@media (max-width: 767px) {
	.process-steps-xs .left-holder li:first-child{
		padding-left: 35px;
	}	
}

.process-steps-xs li:after{
    content: "";
    display: block;
    width: 50%;
    height: 1px;
    border-bottom: 2px dotted rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 2px;
    border-radius: 2px;
    position: absolute;
    right: -20%;
    top: 40px;
    text-align: center;
}

.process-steps-xs li:last-child:after{
	display: none;
}


@media (max-width: 991px) {
	.process-steps-xs li{
	    position: relative;
	    float: left;	
		list-style-type: none;
		display: inline-block;
		margin: 0px 15px;
	}
	.process-steps-xs li:after{
	    display: none;
	}
}

@media (max-width: 767px) {
	.process-steps-xs li{
	    position: relative;
	    float: left;	
		list-style-type: none;
		display: inline-block;
		margin: 15px 0px;
	}
	.process-steps-xs li:after{
	    display: none;
	}
}



/*---SM Size---*/
.process-step-sm{
	position: relative;
	margin: 15px 0px;
}

.process-step-sm .process-step-icon i{
    color: #2387ea;	
    font-size: 50px;
}	

.process-step-sm h3{
	position: relative;
    color: #222;
    font-size: 17px;
    font-weight: 600;
	margin: 10px 0px;	
}
.process-step-sm h3 a{
	position: relative;
    color: #222;
    font-size: 17px;
    font-weight: 600;
	margin: 10px 0px;	
}
.process-step-sm h3 a:hover{
    color: #2387ea;
}

.process-step-sm p{
	position: relative;
    color: #888;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.8;
    margin-top: 12px;
}


.process-steps-sm{
	position: relative;
	padding: 0px;
	margin: 0px;
}



.process-steps-sm li{
    position: relative;
    float: left;	
	list-style-type: none;
	display: inline-block;
	padding: 0px 30px;
}

.process-steps-sm .left-holder li:first-child{
	padding-left: 0px;
}


.process-steps-sm li:after{
    content: "";
    display: block;
    width: 50%;
    height: 1px;
    border-bottom: 2px dotted rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 2px;
    border-radius: 2px;
    position: absolute;
    right: -20%;
    top: 40px;
    text-align: center;
}

.process-steps-sm li:last-child:after{
	display: none;
}



@media (max-width: 767px) {
	.process-steps-sm li{
	    position: relative;
	    float: left;	
		list-style-type: none;
		width: 100%;
		display: inline-block;
		margin: 15px 0px;
	}
	.process-steps-sm li:after{
	    display: none;
	}
}



/*---MD Size---*/
.process-step-md{
	position: relative;
	margin: 15px 0px;
}

.process-step-md .process-step-icon i{
    color: #2387ea;	
    font-size: 70px;
}	

.process-step-md h3{
	position: relative;
	font-size: 19px;
	font-weight: 600;
	color: #222;
	margin: 10px 0px;	
}

.process-step-md p{
	position: relative;
	font-size: 14px;
	font-weight: 600;
	color: #999;
	margin-top: 10px;	
}


.process-steps-md{
	position: relative;
	padding: 0px;
	margin: 0px;
}


@media (max-width: 991px) {
	.process-steps-md li:first-child{
		margin-left: 15px;
	}	
}
@media (max-width: 767px) {
	.process-steps-md li:first-child{
		margin-left: 0px;
	}	
}

.process-steps-md li{
    position: relative;
    float: left;	
	list-style-type: none;
	display: inline-block;
	padding: 0px 35px;
}

.process-steps-md .left-holder li:first-child{
	padding-left: 0px;
}
@media (max-width: 991px) {
	.process-steps-md .left-holder li:first-child{
		padding-left: 35px;
	}
}
@media (max-width: 767px) {
	.process-steps-md .left-holder li:first-child{
		padding-left: 35px;
	}	
}

.process-steps-md li:after{
    content: "";
    display: block;
    width: 50%;
    height: 1px;
    border-bottom: 2px dotted rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 2px;
    border-radius: 2px;
    position: absolute;
    right: -20%;
    top: 40px;
    text-align: center;
}

.process-steps-md li:last-child:after{
	display: none;
}


@media (max-width: 991px) {
	.process-steps-md li{
	    position: relative;
	    float: left;	
		list-style-type: none;
		width: 40%;
		display: inline-block;
		margin: 0px 15px;
	}
	.process-steps-md li:after{
	    display: none;
	}
}

@media (max-width: 767px) {
	.process-steps-md li{
	    position: relative;
	    float: left;	
		list-style-type: none;
		width: 100%;
		display: inline-block;
		margin: 15px 0px;
	}
	.process-steps-md li:after{
	    display: none;
	}
}


/*--- Process Step Background ---*/
.process-steps-background{
	position: relative;
}


/*--- SM Backgorund ---*/
.process-steps-background .process-step-sm i{
    background: #f9f9f9;
    border-radius: 50%;
    font-size: 50px;
    color: #2387ea;
    padding: 40px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;	
}

.process-steps-background .process-step-sm:hover i{
	background: #2387ea;
	color: #fff;
}

.process-steps-background .process-step-sm h3{
    margin-top: 55px;
}

.process-steps-background li:after{
    content: "";
    display: block;
    width: 20%;
    height: 3px;
    border-bottom: 4px solid rgba(0, 0, 0, 0.08);
    -webkit-border-radius: 2px;
    border-radius: 2px;
    position: absolute;
    right: -10%;
    top: 40px;
    text-align: center;
}

.process-steps-background li:last-child:after{
	display: none;
}

@media (max-width: 991px) {
	.process-steps-background li:after{
	    display: none;
	}
}




/*-----------------------------------------
11. Testmonials
-------------------------------------------*/
/*--------------------
Style 1
----------------------*/
.testmonial-box{
	position: relative;
	background: #fff;
	border-radius: 10px;
	padding: 25px;
	border: 1px solid #eee; 
	margin: 15px 0px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s; 	
    -webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.1);
    -moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.1);
    box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.1); 	
}

.testmonial-box-image img{
	position: relative;
	max-width: 100%;
	border-radius: 50%;
	margin-bottom: 15px;	
}

@media (max-width: 767px) {
	.testmonial-box-image{
		text-align: center;
	}

	.testmonial-box-image img{
		width: 90px;
		max-width: 100%;
	}
}

.testmonial-box h5{
	color: #222;
	font-size: 18px;
	font-weight: 600;
}
.testmonial-box span{
	color: #999;
	font-size: 13px;
	font-weight: 600;
}

.testmonial-box p{
  font-size: 15px;
  color: #999;
  line-height: 1.7;
  margin-top: 10px;
}

.testmonial-box-rating{
	position: relative;
}

.testmonial-box-rating h6{
	font-size: 14px;
	font-weight: 600;
	color: #222;
}

.testmonial-box-rating i{
	font-size: 13px;
	color: #2387ea;
}



/*--------------------
Style 2
----------------------*/
.testmonial-modern{
    background: #fff;
    position: relative;
    border:1px solid #eee;
    border-radius: 10px;
    padding: 35px 40px;
    margin: 15px;
    -webkit-box-shadow: 2px 2px 25px 0px rgba(50, 50, 50, 0.11);
    -moz-box-shadow:    2px 2px 25px 0px rgba(50, 50, 50, 0.11);
    box-shadow:         2px 2px 25px 0px rgba(50, 50, 50, 0.11);
    -webkit-transition-duration: .5s;
    transition-duration: .5s;   
}

.testmonial-modern p{
   color: #999;
   font-size: 15px;
   font-weight: 500;
   line-height: 1.8;
   padding-right: 30px;
}


.testmonial-modern-img img{
    border-radius: 50px;
    width: 100%;
    height: auto;
}


.testmonial-modern-name{
    margin-left: 0px;
}

.testmonial-modern-name h4{
    color: #222;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0px;
}

.testmonial-modern-name span{
    color: #2387ea;
    font-size: 13px;
    font-weight: 600;
}



/*--------------------
Style 3
----------------------*/
.testmonial-parallax{
    padding: 0px 80px;
}
@media screen and (max-width:991px) {
	.testmonial-parallax{
	    padding: 0px 30px;
	}
}

.testmonial-parallax-text h6{
    color: #fff;
    font-size: 60px;
    line-height: 1;
}

.testmonial-parallax-text p{
    color: #fff;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.6;
}

.testmonial-parallax-text h4{
    color: #fff;
    font-size: 22px;
    font-weight: 500;
    margin-top: 15px;
}

.testmonial-parallax-text strong{
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0.8;
    letter-spacing: 2px;
}



/*--------------------
Style 4
----------------------*/
.testmonial-single{
	position: relative;
}
@media screen and (max-width:991px) {
	.testmonial-single{
		padding-bottom: 70px;
	}
}

.testmonial-single h3:before{
	font-family: 'Libre Baskerville', serif !important;
	content: '“';
	display: block;
	font-size: 90px;
	color: #2387ea;
	font-weight: 600;
	height: 75px;
	margin-bottom: 0px;	
	margin-top: 10px;
}
@media screen and (max-width:991px) {
	.testmonial-single h3:before{
		margin-top: 0px;
	}
}

.testmonial-single h3{
	font-size: 20px;
	color: #222;
	font-weight: 600;
	line-height: 1.5;
	margin-bottom: 10px;
}

.testmonial-single h4{
	font-size: 17px;
	color: #222;
	font-weight: 600;
	line-height: 1.5;
	margin-top: 30px;
}
.testmonial-single h5{
	font-size: 14px;
	color: #666;
	font-weight: 500;
	line-height: 1.5;
	margin-bottom: 10px;
}

.testmonial-single p{
	color: #999;
	font-size: 15px;
	font-weight: 500;
	line-height: 1.8;
	margin: 15px 0px;
}




/*--------------------
Style 5
----------------------*/
.testmonial-classic{
	background: #fff;
    border: 1px solid #eee;
    border-radius: 10px;
    padding: 30px;
    margin: 15px 0px;    
    -webkit-box-shadow: 0 15px 40px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 0 15px 40px rgba(0, 0, 0, 0.07);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.07);
    -webkit-transition-duration: .4s;
    transition-duration: .4s; 
}
.testmonial-classic:hover{
    -webkit-box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
}

.testmonial-classic h4{
    color: #222;
    font-size: 17px;
    font-weight: 600;
}

.testmonial-classic h5{
    color: #666;
    font-size: 13px;
    font-weight: 600;
    margin-top: 3px;  
}

.testmonial-classic p{
    color: #999;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.9;
    margin-top: 10px;  
}

.testmonial-classic ul{
    margin-top: 10px;
}

.testmonial-classic ul li{
	font-size: 13px;
    display: inline-block;
    color: #ffb432;
}




/*--------------------
Style 6
----------------------*/
.testmonial-simple{
	position: relative;
	text-align: center;
	padding: 0px 100px;
}

@media screen and (max-width:991px) {
	.testmonial-simple{
		padding: 0px 0px;
	}
}

.testmonial-simple img{
	width: 100px !important;
	height: 100px !important;
	border-radius: 50%;
	margin: 20px auto;
}

.testmonial-simple h3{
	font-size: 19px;
	color: #222;
	font-weight: 700;
	display: inline-block;
	letter-spacing: -1px;
	word-spacing: 1px;
	margin-top: 20px;
}

.testmonial-simple h4{
	font-size: 14px;
	color: #999;
	font-weight: 600;	
	display: inline-block;
}

.testmonial-simple p{
	font-size: 17px;
	color: #999;
	font-weight: 500;	
}

.testmonial-simple-rating{
	margin-top: 20px;
	position: relative;
}

.testmonial-simple-rating i{
	font-size: 16px;
	color: #2387ea;
}






/*-----------------------------------------
12. Countups
-------------------------------------------*/
/*--------------------
Style 1
----------------------*/
.countup-box {
	position: relative;
	text-align: center;
	margin: 15px 0px;
}

@media screen and (max-width:991px) {
	.countup-box{
		margin: 30px 0px;
		text-align: center;
	}
}

@media screen and (max-width:767px) {
	.countup-box{
		margin: 30px 0px;
		text-align: center;
	}
}

.countup-box h4{
	font-family: 'Libre Baskerville', serif;
	font-style: italic;	
	font-size: 45px;
	font-weight: 300;
	color: #2387ea;
}
.countup-box h4:after{
	font-family: 'Libre Baskerville', serif;
	font-style: italic;		
	content: '+';
	font-size: 43px;
	font-weight: 300;
	color: #2387ea;
	margin-left: 5px;
}
.countup-box h5{
	font-size: 13px;
	text-transform: uppercase;
	color: #aaa;
}
.countup-box i{
	font-size: 60px;
	font-weight: 300;
	color: #dcdcdc;
}
.countup-box-left{
	display: inline-block;
	padding-right: 10px;
}
.countup-box-right{
	text-align: left;
	display: inline-block;	
}



/*--------------------
Style 2
----------------------*/
.countup-block{
	position: relative;
	text-align: center;
	margin: 15px 0px;
}

@media (max-width: 991px) {
	.countup-block{
		margin: 25px;
	}	
}

.countup-block i{
	display: block;
	font-size: 55px;
	font-weight: 300;
	color: #fff;
}

.countup-block h4{
	display: inline-block;
	font-size: 40px;
	font-weight: 700;
	color: #fff;
	margin: 5px 0px;
}
.countup-block span{
	display: inline-block;
	font-size: 40px;
	font-weight: 900;
	color: #fff;
	margin: 5px;
}

.countup-block h5{
	font-family: 'Libre Baskerville', serif;
	font-style: italic;
	font-size: 15px;
	font-weight: 300;
	color: #fff;
	opacity: .7;
}



/*--------------------
Style 3
----------------------*/
.countup-box-2{
	position: relative;
	text-align: center;
}

@media (max-width: 991px) {
	.countup-box-2{
		margin: 25px;
	}	
}

.countup-box-2 i{
	display: block;
	font-size: 65px;
	font-weight: 300;
	color: #222;
	margin-bottom: 15px;
}

.countup-box-2 h3{
	display: inline-block;
	font-size: 65px;
	font-weight: 700;
	color: #222;
	margin: 5px 0px;
}

.countup-box-2 h4{
	display: inline-block;
	font-size: 40px;
	font-weight: 700;
	color: #222;
	margin: 5px 0px;
}
.countup-box-2 span{
	display: inline-block;
	font-size: 60px;
	font-weight: 700;
	color: #222;
	margin: 5px;
}

.countup-box-2 h5{
	font-family: 'Libre Baskerville', serif;
	font-style: italic;
	font-size: 17px;
	font-weight: 300;
	color: #222;
	opacity: .7;
}


/*--- Style 3 Small Size ---*/
.countup-box-2-sm{
	position: relative;
	text-align: center;
}

@media (max-width: 991px) {
	.countup-box-2-sm{
		margin: 25px;
	}	
}

.countup-box-2-sm i{
	display: block;
	font-size: 65px;
	font-weight: 300;
	color: #222;
	margin-bottom: 15px;
}

.countup-box-2-sm h3{
	display: inline-block;
	font-size: 45px;
	font-weight: 700;
	color: #222;
	margin: 5px 0px;
}

.countup-box-2-sm h4{
	display: inline-block;
	font-size: 30px;
	font-weight: 700;
	color: #222;
	margin: 5px 0px;
}
.countup-box-2-sm span{
	display: inline-block;
	font-size: 40px;
	font-weight: 700;
	color: #222;
	margin: 5px;
}

.countup-box-2-sm h5{
	font-family: 'Libre Baskerville', serif;
	font-style: italic;
	font-size: 16px;
	font-weight: 300;
	color: #222;
	opacity: .7;
}





/*-----------------------------------------
13. Team
-------------------------------------------*/
/*--------------------
Style 1
----------------------*/
.team-member{
	position: relative;
	margin: 30px 0px 15px 0px;	
}

.team-member-image{
	position: relative;
	margin: 15px 0px 15px 0px;
}

.team-member-image img{
	display: block;
	width: 100%;
	height: auto;
	border-radius: 10px;
}

.team-member-overlay {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, .5);
	overflow: hidden;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: .5s ease;
	border-radius: 10px; 
}
.team-member:hover .team-member-overlay {
	opacity: 1;
}

.team-member-content {
	white-space: nowrap; 
	color: white;
	font-size: 20px;
	position: absolute;
	overflow: hidden;
	top: 85%;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	padding: 50px;
}

.team-member-content ul{
	background: #fff;
	border-radius: 5px;
	padding: 0px 8px 2px 8px;
}

.team-member-content ul li{
	display: inline-block;
}

.team-member-content i{
	font-size: 15px;
	text-align: center;
	color: #222;
	padding: 7px;
	transition: .3s ease;	
}
.team-member-content i:hover{
	color: #2387ea;
}

.team-member-name h4{
	font-size: 18px;
	font-weight: 600;
	color: #222;
	text-align: center;
	margin-top: 15px;
}

.team-member-name h4 a{
	font-size: 18px;
	font-weight: 600;
	color: #222;
	text-align: center;
	margin-top: 15px;
}

.team-member-name h4 a:hover{
	color: #2387ea;
}

.team-member-name h6{
	font-size: 12px;
	font-weight: 600;
	color: #aaa;
	text-align: center;
	text-transform: uppercase;
	margin-top: 5px;
}


/*--------------------
Style 2
----------------------*/
.team-block{
	position: relative;
	overflow: hidden;
	background: #fff;
	border: 1px solid #ececec;
	border-radius: 10px;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.08);
    -moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.08);
    box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.08);
    margin: 25px 0px 25px 0px;
	-webkit-transition-duration: .4s;
	transition-duration: .4s; 
	margin: 25px 15px !important;
}

.team-block:hover{
    -webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
    -moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
    box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
    margin: 25px 0px 25px 0px;
}

.team-block-text{
	padding: 30px 30px 20px 30px;
}

.team-block-text h4{
	color: #222;
	font-size: 21px;
	font-weight: 500;
}

.team-block-text h4 a{
	color: #222;
	font-size: 21px;
	font-weight: 500;
}
.team-block-text h4 a:hover{
	color: #222;
}

.team-block-text h6{
	color: #2387ea;
	font-size: 15px;
	font-weight: 500;
	margin-top: 5px;
}

.team-block-text h6:after{
	content: "";
    display: block;
    width: 30%;
    height: 3px;
    border-bottom: 4px solid rgba(0, 0, 0, 0.08);
    -webkit-border-radius: 2px;
    border-radius: 2px;
    text-align: center;
    margin: 15px 0px;
}

.team-block-text p{
	color: #999;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.8;
	margin: 15px 0px;
}

.team-block-text ul li{
	display: inline-block;
	margin-top: 5px;

}

.team-block-text ul li a{
	color: #666;
	font-size: 14px;
	margin-right: 18px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}

.team-block-text ul li a:hover{
	color: #2387ea;
}

.team-block-img img{
	display: inline-block;
	width: 100%;
	height: auto;
}



/*--------------------
Style 3
----------------------*/
.team-box{  
	position: relative;
	overflow: hidden;
    border: 1px solid #eee;
    border-radius: 10px;
    text-align: center;
    background: #fff;
 	padding: 10px;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.08);
    -moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.08);
    box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.08); 
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
}
.team-box:hover{  
    -webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
    -moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
    box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16); 
}

.team-box-img{  
    width: 100%;
    height: auto;
}

.team-box h4{  
    color: #222;
    font-size: 18px;
    font-weight: 600;
    margin: 20px 0px 0px;
}
.team-box h4 a{  
    color: #222;
    font-size: 18px;
    font-weight: 600;
    margin: 20px 0px 0px;
}

.team-box span{  
    color: #999;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px
}

.team-box p{  
    color: #666;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.5;
    margin: 15px 20px 20px 20px;
}


.team-box-social{
	margin-bottom: 5px;
}

.team-box-social li{
    display: inline-block;
    margin-left: 15px;
}

.team-box-social li a i{
    color: #444;
    font-size: 14px;
    padding: 10px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
}

.team-box-social li:hover a i{
    color: #2387ea;
}




/*--------------------
Style 4
----------------------*/
.team-circle{
    position: relative;
    text-align: center;
    border: 1px solid #eee;
    background: #fff;
    border-radius: 10px;
    padding: 15px 0px;
    margin: 15px 0px;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.1);
    -moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.1);
    box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.1);
   -webkit-transition-duration: .4s;
    transition-duration: .4s; 
}
.team-circle:hover{
    -webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.2);
    -moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.2);
    box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.2);
}

.team-circle-img img{
    border-radius: 50%;
    width: 60%;
    height: 60%;
}

.team-circle-text h4{
    color: #222;
    font-size: 17px;
    font-weight: 600;
    margin-top: 15px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
}
.team-circle-text h4 a{
    color: #222;
    font-size: 17px;
    font-weight: 600;
    margin-top: 15px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
}
.team-circle-text h4 a:hover{
    color: #2387ea;
}

.team-circle-text h5{
    color: #999;
    font-size: 15px;
    font-weight: 500;
    margin-top: 7px;
}

.team-circle-social{
	margin-top: 10px;
}

.team-circle-social li{
    display: inline-block;
	margin: 5px 0px 0px 0px;
}

.team-circle-social li a i{
    color: #999;
    font-size: 14px;
    padding: 10px;
    -webkit-transition-duration: .4s;
    transition-duration: .4s;
}
.team-circle-social li:hover a i{
    color: #2387ea;
}


/*--------------------
Style 5
----------------------*/
.team-classic{
    position: relative;
    padding: 0px;
    margin: 15px 0px;
}

.team-classic img{
    display: block;
    width: 100%;
    height: auto;
}

.team-classic-overlay {
    position: absolute;
    top: 15px;
    bottom: 15px;
    left: 15px;
    right: 15px;
    background: rgba(0, 0, 0, 0.6);
    overflow: hidden;
    opacity: 0;
    -webkit-transition-duration: .5s;
    transition-duration: .5s;   
}
.team-classic:hover .team-classic-overlay {
    opacity: 1;
}

.team-classic-content{
    white-space: nowrap; 
    color: white;
    font-size: 20px;
    position: absolute;
    overflow: hidden;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.team-classic-content i{
    font-size: 17px;
    text-align: center;
    color: #ccc;
    padding: 10px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;       
}
.team-classic-content i:hover{
    color: #fff;
}
.team-classic:hover .team-classic-overlay i{
    animation-name: fadeInDown;
    animation-duration: .5s; 
    animation-timing-function: ease-out; 
}

.team-classic-name{
    white-space: nowrap; 
    color: white;
    font-size: 20px;
    position: absolute;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    padding: 50px;
}

.team-classic-name h4{
    color: #fff;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
}

.team-classic-name h6{
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    letter-spacing: 1px;
    margin-top: 8px;
}
.team-classic:hover .team-classic-name h4{
    animation-name: fadeInDown;
    animation-duration: .5s; 
    animation-timing-function: ease-out; 
}
.team-classic:hover .team-classic-name h6{
    animation-name: fadeInDown;
    animation-duration: .6s; 
    animation-timing-function: ease-out; 
}

@media screen and (max-width:767px) {
    .team-classic{
        position: relative;
        margin: 15px 0px;
    }
}








/*-----------------------------------------
14. Small Forms(Contact, Callback, Subscibe)
-------------------------------------------*/
/*--------------------
Style 1
----------------------*/
.contact-form-md{
	position: relative;
	border-radius: 10px;
	padding: 30px 40px;
	margin: 15px 0px;
}

.contact-form-md input{
	display: block;
	border-radius: 5px;
	border: none;
	background: #f4f4f4;		
	font-size: 13px;
	font-weight: 600;
	width: 100%;
	padding: 12px 16px;
	margin: 15px 0px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease; 	
}
.contact-form-md textarea{
	display: block;
	border-radius: 5px;
	border: none;
	min-height: 90px;
	background: #f4f4f4;		
	font-size: 13px;
	font-weight: 600;
	width: 100%;
	padding: 12px 16px;
	margin: 15px 0px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease; 	
}
.contact-form-md input:focus, .contact-form-md textarea:focus{
	background: #ececec;
}



/*--------------------
Style 2 (Subscribe)
----------------------*/
.subscribe-form-1{
	position: relative;
}
.subscribe-form-1 input{
	display: block;
	border-radius: 5px;
	border: none;
	color: #fff;
	text-align: center;
	background: rgba(0, 0, 0, .4);		
	font-size: 14px;
	font-weight: 600;
	width: 100%;
	padding: 15px 16px;
	margin: 15px 0px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease; 	
}

.subscribe-form-1 button{
	display: block;
	border-radius: 5px;
	border: none;
	color: #fff;
	background: #2387ea;		
	font-size: 14px;
	font-weight: 600;
	width: 100%;
	padding: 15px 16px;
	margin: 15px 0px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease; 	
}

.subscribe-form-1 button:hover{
	background: rgba(0, 0, 0, .4);
}




/*--------------------
Style 3 (Subscribe)
----------------------*/
.subscribe-form-2{
	position: relative;
}
.subscribe-form-2 input{
	display: block;
	border-radius: 5px;
	border: none;
	color: #222;
	text-align: center;
	background: #fff;		
	font-size: 14px;
	font-weight: 600;
	width: 100%;
	padding: 15px 16px;
	margin: 15px 0px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease; 	
}

.subscribe-form-2 button{
	display: block;
	border-radius: 5px;
	border: none;
	color: #fff;
	background: #2387ea;		
	font-size: 14px;
	font-weight: 600;
	width: 100%;
	padding: 15px 16px;
	margin: 15px 0px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease; 	
}

.subscribe-form-2 button:hover{
	background: rgba(0, 0, 0, .4);
}



/*-----------------------------------------
15. Contact Assets
-------------------------------------------*/
/*--------------------
Style 1
----------------------*/
.contact-icon-box-sm{
	position: relative;
	display: block;
	border-bottom: 1px solid rgba(255, 255, 255, .15);
	padding: 27px 0px;
}

.contact-icon-box-sm i{
	float: left;
	display: inline-block;
	color: #fff;	
	font-size: 50px;
	margin-right: 20px;
}

.contact-icon-box-sm h4{
	display: block;
	color: #fff;	
	font-size: 20px;
	font-weight: 400;
	margin-left: 15px;
	margin-bottom: 5px;
	vertical-align: top;
}
.contact-icon-box-sm h5{
	display: block;
	color: #fff;	
	font-size: 14px;
	font-weight: 400;
	opacity: .6;
}




.contact-icon-box-primary-color .contact-icon-box-sm i{
	color: #2387ea !important;
}
.contact-icon-box-primary-color .contact-icon-box-sm h4{
	color: #2387ea !important;
}
.contact-icon-box-primary-color .contact-icon-box-sm h5{
	color: #2387ea !important;
	opacity: 1;
}



.contact-icon-box-dark-color .contact-icon-box-sm i{
	color: #222 !important;
}
.contact-icon-box-dark-color .contact-icon-box-sm h4{
	color: #222 !important;
}
.contact-icon-box-dark-color .contact-icon-box-sm h5{
	color: #222 !important;
	opacity: 1;
}





/*-----------------------------------------
16. Blog Grid
-------------------------------------------*/
/*--------------------
Style 1
----------------------*/
.item{
	position: relative;
}

.owl-carousel .blog-grid-simple{
	margin: 10px 15px 25px 15px;
}

.blog-grid-simple{
	position: relative;
	background: #fff;
	border-radius: 10px;
	border: 1px solid #ececec;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.1);
    -moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.1);
    box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.1);  	
	padding: 25px 30px;
	margin: 15px 0px;
}

.blog-grid-simple h4 a{
	color: #111;
	font-size: 19px;
	font-weight: 500;
	margin-bottom: 15px;
}

.blog-grid-simple h4 a:before{
	content: '\f0c1';
	font-family: "Font Awesome 5 Free";
	color: #2387ea;	
	font-weight: 600;
	margin-right: 10px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s; 
}

.blog-grid-simple h4 a:hover{
	color: #2387ea;
}

.blog-grid-simple p{
	color: #999;
	font-size: 14px;
	line-height: 1.8;
	font-weight: 400;
	margin-top: 10px;
}

.blog-grid-simple-date i{
	color: #888;
	font-size: 20px;
}
.blog-grid-simple-date h5{
	color: #888;
	font-size: 13px;
	margin-top: 3px;
}

.blog-grid-simple-content{
	border-top: 1px solid #ececec;
	padding-top: 20px;
	margin-top: 15px;
}

.blog-grid-simple-content a{
	display: inline-block;
	color: #2387ea;
	font-weight: 600;
	font-size: 14px;
	margin-top: 2px;	
}

.blog-grid-simple-content a:after{
	content: '\f105';
	font-size: 14px;
	font-family: "Font Awesome 5 Free";
	color: #2387ea;	
	margin-left: 8px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s; 
}

@media (max-width: 767px) {
	.blog-grid-simple-content a:after{	
		display: none;
	}
}


.blog-grid-simple-content a:hover{
	color: #222;
}
.blog-grid-simple-content a:hover:after{
	color: #222;	
}


#blog-grid-simple .custom-dot span:hover {
    background: #aaa;
    -webkit-backface-visibility: visible;
    border-radius: 30px;
}
#blog-grid-simple .custom-dot.active span{
	width: 12px;
	height: 12px;
	background: #2387ea;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;    
}


/*--------------------
Style 2
----------------------*/
.blog-grid-2{
	position: relative;
	background: #fff;
    border: 1px solid #eee; 
    border-radius: 5px;
    overflow: hidden;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.08);
    -moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.08);
    box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.08);    
    margin: 15px 0px 20px 0px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;     
}

.blog-grid-2:hover{
    -webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
    -moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
    box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16); 
}

.blog-grid-2 img{
	width: 100%;
}

.blog-grid-text{
    padding: 20px 25px 25px 25px;
}

.blog-grid-text span{
    color: #2387ea;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 4px;
    text-transform: uppercase;
}

.blog-grid-text h4{
    color: #222;
    font-size: 19px;
    font-weight: 500;
    margin: 5px 0px 5px 0px
}

.blog-grid-text ul li{
    display: inline-block;
    color: #999;
    font-size: 14px;
    font-weight: 500;
    margin: 10px 25px 10px 0px;
}

.blog-grid-text ul li i{
    color: #2387ea;
    font-size: 14px;
    font-weight: 500;
    margin-right: 10px;
}

.blog-grid-text p{
    color: #999;
    font-size: 15px;
    font-weight: 400;
    line-height: 170%;
    border-bottom: 1px solid #ececec;
    padding-bottom: 15px;
}

.blog-grid-2-button a {
    display: inline-block;
    color: #222;
    font-size: 14px;
    font-weight: 600;
    margin: 20px 0px 0px 5px;
}

.blog-grid-2-button a i {
    font-size: 11px;
    margin-left: 4px;
}

.blog-grid-2-button a:hover{
	color: #2387ea;
}



/*--------------------
Style 3
----------------------*/
.blog-grid-3{
	position: relative;
	background: #fff;
    border: 1px solid #eee; 
    border-radius: 5px;
    overflow: hidden;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.08);
    -moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.08);
    box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.08);    
    margin: 15px 0px 25px 0px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;     
}

.blog-grid-3:hover{
    -webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
    -moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
    box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16); 
}

.blog-grid-3 img{
	width: 100%;
}

.blog-grid-3-text{
    padding: 15px 20px 16px 20px;
}

.blog-grid-3-text span{
    color: #2387ea;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 4px;
    text-transform: uppercase;
}

.blog-grid-3-text h4{
    color: #222;
    font-size: 16px;
    font-weight: 600;
    margin: 5px 0px 5px 0px
}

.blog-grid-3-text ul li{
    display: inline-block;
    color: #999;
    font-size: 12px;
    font-weight: 600;
    margin: 10px 15px 10px 0px;
}

.blog-grid-3-text ul li i{
    color: #2387ea;
    font-size: 13px;
    font-weight: 500;
    margin-right: 7px;
}

.blog-grid-3-text p{
    color: #999;
    font-size: 14px;
    font-weight: 400;
    line-height: 170%;
    border-bottom: 1px solid #ececec;
    padding-bottom: 15px;
}

.blog-grid-3-button a {
    display: inline-block;
    color: #222;
    font-size: 13px;
    font-weight: 600;
    margin: 15px 0px 0px 5px;
}

.blog-grid-3-button a i {
    font-size: 10px;
    margin-left: 4px;
}

.blog-grid-3-button a:hover{
	color: #2387ea;
}





/*--------------------
Style 4
----------------------*/
.blog-news{
    background: #fff;
    margin-top: 30px;
    -webkit-box-shadow: 2px 2px 25px 0px rgba(50, 50, 50, 0.11);
    -moz-box-shadow:    2px 2px 25px 0px rgba(50, 50, 50, 0.11);
    box-shadow:         2px 2px 25px 0px rgba(50, 50, 50, 0.11);
    -webkit-transition-duration: .4s;
    transition-duration: .4s; 
}
.blog-news:hover{
    -webkit-box-shadow: 5px 5px 30px 0px rgba(50, 50, 50, 0.25);
    -moz-box-shadow:    5px 5px 30px 0px rgba(50, 50, 50, 0.25);
    box-shadow:         5px 5px 30px 0px rgba(50, 50, 50, 0.25);
}


.blog-news-img{
    width: 100%;
    height: auto;
}

.blog-news-text{
    text-align: center; 
    padding: 25px 50px;
}

.blog-news-text h4{
    color: #222;
    font-size: 25px;
    font-weight: 500;
}
.blog-news-text h4 a{
    color: #222;
    font-size: 25px;
    font-weight: 500;
}
.blog-news-text h4 a:hover{
    color: #2387ea;
}

.blog-news-text p{
    color: #999;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.6;
    margin-top: 10px;
}

.blog-news-comment{ 
    display: inline-block;
}

.blog-news-comment strong{ 
    background: #2387ea;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    border-radius: 50px;
    padding: 3px 4px;
}

.blog-news-comment a{ 
    color: #666;
    font-size: 13px;
    font-weight: 600;
    margin-left: 8px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s; 
}
.blog-news-comment a:hover{ 
    color: #2387ea;
}

.blog-news-comment p{ 
    display: inline-block;
    color: #666;
    font-size: 13px;
    font-weight: 600;
}

.blog-news-comment span{ 
    color: #2387ea;
    font-size: 13px;
    font-weight: 600;
    margin-left: 7px;
}

.blog-news-left{
    display: inline-block;
    margin: 15px 0px;
    -webkit-box-shadow: 2px 2px 25px 0px rgba(50, 50, 50, 0.10);
    -moz-box-shadow:    2px 2px 25px 0px rgba(50, 50, 50, 0.10);
    box-shadow:         2px 2px 25px 0px rgba(50, 50, 50, 0.10);
    -webkit-transition-duration: .4s;
    transition-duration: .4s; 
}

.blog-news-left-img{
    width: 100%;
    height: auto;
}

.blog-news-left-text{
    text-align: left;
    padding: 25px 30px 0px 0px;
}
@media (max-width: 767px) {
	.blog-news-left-text{
	    text-align: left;
	    padding: 25px 30px 25px 30px;
	}
}

.blog-news-left-text h4{
    color: #222;
    font-size: 18px;
    font-weight: 500;
}
.blog-news-left-text h4 a{
    color: #222;
    font-size: 18px;
    font-weight: 500;
}
.blog-news-left-text h4 a:hover{
    color: #2387ea;
}

.blog-news-left-text p{
    color: #999;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.8;
    margin-top: 10px;
}


.blog-news-left-comment{ 
    display: inline-block;
}

.blog-news-left-comment strong{ 
    background: #2387ea;
    color: #fff;
    font-size: 13px;
    border-radius: 50px;
    padding: 4px 6px;
}

.blog-news-left-comment a{ 
    color: #666;
    font-size: 13px;
    font-weight: 600;
    margin-left: 8px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s; 
}
.blog-news-left-comment a:hover{ 
    color: #2387ea;
}

.blog-news-left-comment p{ 
    display: inline-block;
    color: #666;
    font-size: 13px;
    font-weight: 600;
}

.blog-news-left-comment span{ 
    color: #2387ea;
    font-size: 13px;
    font-weight: 600;
    margin-left: 7px;
}




/*--------------------
Style 5
----------------------*/
.blog-grid{
    position: relative;
    overflow: hidden;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 15px;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.1);
    -moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.1);
    box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.1);
    -webkit-transition-duration: .3s;
    transition-duration: .3s; 
}

.blog-grid:hover{
    -webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
    -moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
    box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
}

.blog-grid strong{
    padding: 3px 5px;
    background: #2387ea;  
    color: #fff;   
    font-size: 13px;
    font-weight: 600;
    border-radius: 3px;  
}

.blog-grid h4 {
    line-height: 1;
    margin-top: 10px; 
    margin-bottom: 5px;
}

.blog-grid h4 a{
    color: #222;
    font-size: 18px;
    font-weight: 600;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
}

.blog-grid h4 a:hover{
    color: #2387ea;
}

.blog-grid span{
    color: #999;
    font-size: 14px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
}

.blog-grid span a{
    color: #666;
    font-size: 14px;
}

.blog-grid span a:hover{
    color: #2387ea;
    font-size: 14px;
}

.blog-grid img{
    width: 100%;
    height: auto;
    margin: 15px 0px;
}

.blog-grid p{
    color: #999;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.8;
}

.blog-grid-button{
    display: inline-block;
    margin-top: 15px;

}

.blog-grid-button a{
    color: #222;
    font-size: 14px;
    font-weight: 600;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
}

.blog-grid-button a i{
    color: #222;
    font-size: 13px;
    margin-left: 3px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
}

.blog-grid-button a:hover{
    color: #2387ea;
}

.blog-grid-button a:hover i{
    color: #2387ea;
}









/*-----------------------------------------
17. Blog List
-------------------------------------------*/
.blog-list{
    position: relative;
    margin-bottom: 40px;
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
}

.blog-list img{
    border-radius: 5px;
    width: 100%;
    height: auto;
}

.blog-list h6{
    margin-top: 10px;
}
.blog-list h6 a{
    color: #666;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: 10px;
}
.blog-list h6 a:hover{
    color: #2387ea;
}

.blog-list h4{
    margin-top: 25px;
}
.blog-list h4 a{
    font-size: 25px;
    font-weight: 500;
    color: #222;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}
.blog-list h4 a:hover{
    color: #2387ea;
}

.blog-list-info{
    display: inline-block;
    margin-top: 15px;
    margin-right: 15px;
}

.blog-list-info li{
    display: inline-block;
    margin-right: 20px;
}

.blog-list-info li i{
    color: #666; 
    font-size: 16px;
    padding-right: 10px;
}    

.blog-list-info li span{
    font-size: 14px;
    font-weight: 600;
    color: #999;
}

.blog-list p{
    color: #999;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.8;
    margin-top: 15px;
}





/*-----------------------------------------
18. Blog Post
-------------------------------------------*/
.blog-post{
    position: relative;
    margin-bottom: 40px;
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
}

.blog-post img{
    border-radius: 5px;
    width: 100%;
    height: auto;
}

.blog-post h6{
    margin-top: 10px;
}
.blog-post h6 a{
    color: #666;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: 10px;
}
.blog-post h6 a:hover{
    color: #2387ea;
}

.blog-post h4{
    margin-top: 5px;
}
.blog-post h4 a{
    font-size: 25px;
    font-weight: 500;
    color: #222;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}
.blog-post h4 a:hover{
    color: #2387ea;
}

.blog-post-info{
    display: inline-block;
    margin-top: 15px;
    margin-right: 15px;
}

.blog-post-info li{
    display: inline-block;
    margin-right: 20px;
}

.blog-post-info li i{
    color: #666; 
    font-size: 16px;
    padding-right: 10px;
}    

.blog-post-info li span{
    font-size: 14px;
    font-weight: 600;
    color: #999;
}

.blog-post p{
    color: #999;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.8;
    margin-top: 15px;
}


blockquote{
    background: #f7f7f7;
    border-left: 4px solid #2387ea; 
    margin: 25px 0px 25px 0px;
    padding: 15px 30px 30px 30px;
}

blockquote p{
	font-size: 17px;
	font-weight: 600 !important;
}

.comment-block{
    background: #f7f7f7;
    border-radius: 5px;
    border: 1px solid #eee;
    padding: 20px;
}

.blog-comments{
    background: #fff;
    border-radius: 5px;
}

.blog-comments h3{
    font-size: 20px;
    font-weight: 600;
    color: #222;
    margin-top: 30px;
}

.blog-comment-user{
    padding-top: 25px;
    padding-bottom: 25px;
}

.blog-comment-user img{
    border-radius: 5px;
}

.blog-comment-user h6{
    display: inline-block;
    color: #222;
    font-size: 16px;
    margin: 0px;
    margin-right: 15px;
}

.blog-comment-user strong{
    color: #2387ea;
    font-size: 12px;
    font-weight: 600;
}

.blog-comment-user p{
    color: #666;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.7;
    margin-top: 10px;
}









/*-----------------------------------------
19. Pricing Options
-------------------------------------------*/
/*--------------------
Style 1
----------------------*/
.pricing-plan{
	position: relative;
	background: #fff;
	border-radius: 10px;
    box-shadow: 0 0 57px rgba(0,0,0,.08);
	padding: 40px;
	margin: 15px 0px;
}

.pricing-plan-emphasise{
	transform: scale(1.1);
	z-index: 2;
	position: relative;
}

@media (max-width: 991px) {
	.pricing-plan-emphasise{
		transform: scale(1);
		z-index: 2;
		position: relative;
	}
}

.pricing-plan h4{
	font-size: 16px;
	font-weight: 600;
	color: #222;
	margin-bottom: 3px;
}

.pricing-plan strong{
	font-size: 14px;
	font-weight: 500;
	color: #999;
}

.pricing-plan h3{
	font-size: 60px;
	font-weight: 400;
	letter-spacing: -2px;
	color: #333;
	margin: 15px 0px;
}

.pricing-plan h3 sup{
	font-size: 30px;
	font-weight: 500;
}

.pricing-plan h3:after{
	content: "";
	display: block;
	width: 100%;
	height: 1px;
	border-bottom: 1px solid #eee;
	padding-top: 15px; 
	margin-bottom: 25px;
}

.pricing-plan p{
	font-size: 15px;
	font-weight: 500;
	line-height: 1.8;
	color: #999;
	padding: 0px 15px;
	margin: 20px 0px;
}

.pricing-plan a{
	position: relative;
	display: inline-block;	
	background: #2387ea;
	color: #fff;
	border: 2px solid #2387ea;
	border-radius: 50px;
	font-weight: 600;
	font-size: 13px;
	text-align: center;
	padding: 9px 19px 9px 19px;
	margin: 20px 5px 5px 0px;		
	-webkit-transition: all .4s ease;
	-moz-transition: all .4s ease;
	transition: all .4s ease;
}

.pricing-plan a:hover{
	background: #222;
	border-color: #222;
}


.pricing-plan ul{
	padding: 0px 30px;	
}

@media (max-width: 991px) {
	.pricing-plan ul{
		padding: 0px 10px;	
	}
}

@media (max-width: 767px) {
	.pricing-plan ul{
		padding: 0px;	
	}
}

.pricing-plan ul li{
    list-style-type: none;
    color: #999;
    font-size: 15px;
    text-align: left;
    font-weight: 500;
    margin: 10px 0px;  
}
.pricing-plan ul li i{
	font-size: 13px;
    color: #2387ea;
    padding-right: 10px;
}



/*--------------------
Style 2
----------------------*/
.pricing-list{
    position: relative;
    text-align: center;
    border-radius: 10px;
    background: rgba(34, 34, 34, 0.8);
    margin: 15px 0px;
    padding: 30px 40px; 
}

.pricing-list h3{
    color: #fff;
    font-size: 22px;
    font-weight: 600;
}

.pricing-list p{
    color: #d9d9d9;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 1.5px;
    margin: 10px 0px;
}


.pricing-list-price{
    border-top: 1px solid rgba(255, 255, 255, .1);
    margin: 20px 0px;
    padding-top: 10px;
}


.pricing-list-price h2{
    color: #fff;
    font-size: 55px;
    font-weight: 500;

}

.pricing-list-price h2 sup{
    color: #fff;
    font-size: 35px;
    font-weight: 500;
    line-height: 1.6;
    margin-right: 5px;
}


.pricing-list-term{
    display: inline-block;
    padding: 10px 25px;
    background:  #222;
    border-radius: 30px;
    margin-top: 5px;
}

.pricing-list-term h6{
    color: #fff;
    font-size: 11px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.pricing-list-products{
    text-align: center;
    padding: 0px 30px;
}

.pricing-list-products li{
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    padding-top: 5px;
}


.pricing-list-button{
    display: inline-block;
    border: 2px solid #fff;
    border-radius: 30px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;   
}
.pricing-list-button:hover{
    background: #fff;
}

.pricing-list-button a{
    display: inline-block;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 30px;
}
.pricing-list-button:hover a{
    color: #222; 
}

.pricing-list-recomended{
    background: rgba(35, 135, 234, 0.9);
}

.pricing-list-recomended p{
    color: #fff;
}

.pricing-list-recomended .pricing-list-term{
   background: #fff;
}

.pricing-list-recomended .pricing-list-term h6{
   color: #222;
}



/*--------------------
Style 3
----------------------*/
.pricing-modern{
    position: relative;
    background: #fff;
    text-align: center;
    overflow: hidden;
    padding-bottom: 25px;
    border-radius: 10px;
    margin-top: 20px;
    box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.08);
    -webkit-transition-duration: .4s;
    transition-duration: .4s; 
}
.pricing-modern:hover{
	box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
}

.pricing-modern h5{
    background: #333;
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 15px;
}

.pricing-modern-icon{
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding-top: 25px; 
    padding-bottom: 25px;
}

.pricing-modern-icon img{
    width: 80px; 
    height: auto;
}

.pricing-modern-productcon{
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding-bottom: 25px;
}
.pricing-modern-productcon li{
    color: #999;
    font-size: 16px;
    font-weight: 500;   
    padding: 8px;
}

.pricing-modern h2{
    color: #222;
    font-size: 50px;
    font-weight: 600;
    margin: 35px;   
}

.pricing-modern h2 sup{
    color: #222;
    font-size: 35px;
    font-weight: 600;
}

.pricing-modern h2 span{
    color: #999;
    font-size: 15px;
    font-weight: 600;
}

.pricing-modern-button a{
    display: inline-block;
    background: #222;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    border-radius: 50px;
    padding: 10px 30px;
    -webkit-transition-duration: .4s;
    transition-duration: .4s;   
}
.pricing-modern-button a:hover{
    background: #2387ea;
    color: #fff;
}

.pricing-modern-recommended{
    background: #333;
}
.pricing-modern-recommended li{
    color: #f7f7f7;
}

.pricing-modern-recommended h2,
.pricing-modern-recommended h2 sup{
    color: #f7f7f7;
}

.pricing-modern-button-recommended a{
    background: #fff;
    color: #222;
}
.pricing-modern-button-recommended a:hover{
    background: #222;
}



/*--------------------
Style 4
----------------------*/
.pricing-plan-2{
	position: relative;
	background: #fff;
	border-radius: 10px;
    box-shadow: 0 0 57px rgba(0,0,0,.03);
    border: 1px solid #f1f1f1;
	padding: 35px 40px;
	margin: 15px 0px;
}

.pricing-plan-2-emphasise{
	transform: scale(1.05);
	z-index: 2;
	box-shadow: 0 0 57px rgba(0,0,0,.05);
	position: relative;
}

@media (max-width: 991px) {
	.pricing-plan-2-emphasise{
		transform: scale(1);
		z-index: 2;
		position: relative;
	}
}

.pricing-plan-2 h4{
	font-size: 17px;
	font-weight: 400;
	color: #222;
	text-align: left;
	margin-bottom: 3px;
}

.pricing-plan-2 strong{
	font-size: 15px;
	font-weight: 500;
	color: #999;
	text-align: left;
}

.pricing-plan-2 h3{
	font-size: 35px;
	font-weight: 600;
	letter-spacing: -1px;
	color: #333;
	text-align: left;
	margin: 15px 0px;
}

.pricing-plan-2 h3 sup{
	font-size: 25px;
	font-weight: 600;
	margin-right: 2px;
}

.pricing-plan-2 h3:after{
	content: "";
	display: block;
	width: 100%;
	height: 1px;
	border-bottom: 1px solid #eee;
	padding-top: 15px; 
	margin-bottom: 25px;
}

.pricing-plan-2 p{
	font-size: 15px;
	font-weight: 500;
	line-height: 1.8;
	color: #999;
	padding: 0px 15px;
	margin: 20px 0px;
}

.pricing-plan-2 a{
	position: relative;
	display: block;	
	background: #2387ea;
	color: #fff;
	border: 2px solid #2387ea;
	border-radius: 5px;
	font-weight: 600;
	font-size: 12px;
	text-align: center;
	padding: 7px 15px 7px 15px;
	margin: 25px 0px 0px 0px;		
	-webkit-transition: all .4s ease;
	-moz-transition: all .4s ease;
	transition: all .4s ease;
}

.pricing-plan-2 a:hover{
	background: #222;
	border-color: #222;
}


.pricing-plan-2 ul{
	padding: 0px 0px;	
}

@media (max-width: 991px) {
	.pricing-plan-2 ul{
		padding: 0px 10px;	
	}
}

@media (max-width: 767px) {
	.pricing-plan-2 ul{
		padding: 0px;	
	}
}

.pricing-plan-2 ul li{
    list-style-type: none;
    color: #999;
    font-size: 13px;
    text-align: left;
    font-weight: 600;
    margin: 10px 0px;  
}
.pricing-plan-2 ul li i{
	font-size: 11px;
    color: #2387ea;
    padding-right: 10px;
}






/*-----------------------------------------
20. Projects (Portfolio)
-------------------------------------------*/
/*--------------------
Style 1
----------------------*/
.project-classic {
	position: relative;
	overflow: hidden;
	margin: 15px 0px;
}

.masonry-grid .project-classic {
	margin-bottom: 15px;
	margin-top: 0px;
}

.no-gutters .project-classic {
	margin: 0px;
}

.project-classic img {
	display: block;
	width: 100%;
	transform: scale(1.1);
	height: auto;
}

.project-classic-overlay {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    opacity: 0;
    background-color: rgba(255, 255, 255, .9);
    text-align: center;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;       
}

.project-classic:hover .project-classic-overlay {
	opacity: 1;
}
.project-classic:hover img {
	transform: scale(1);
}

.project-classic-content {
	display: table;
    width: 100%;
    height: 100%;
    text-align: center;
}

.project-classic-content-inner {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 15px;
}

.project-classic-content h3{
	font-size: 24px;
	color: #222;
	font-weight: 700;
	margin-bottom: 5px;
}
.project-classic-content h4{
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #b5b5b5;
	font-weight: 700;
	margin-bottom: 10px;
}



/*--------------------
Style 2
----------------------*/
.project-classic-2 {
	position: relative;
	overflow: hidden;
	margin: 15px 0px;
}

.masonry-grid .project-classic-2 {
	margin-bottom: 15px;
	margin-top: 0px;
}

.no-gutters .project-classic-2 {
	margin: 0px;
}

.project-classic-2 img {
	display: block;
	width: 100%;
	transform: scale(1);
	height: auto;
}

.project-classic-2 .project-classic-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0;
    background-color: #2387ea;
    text-align: center;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;       
}

.project-classic-2:hover .project-classic-overlay {
	opacity: 1;
}
.project-classic-2:hover img {
	transform: scale(1.1);
}

.project-classic-2 .project-classic-content {
	display: table;
    width: 100%;
    height: 100%;
    text-align: center;
}

.project-classic-2 .project-classic-content-inner {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 15px;
}

.project-classic-2 .project-classic-content h3{
	font-size: 24px;
	color: #fff;
	font-weight: 700;
	margin-bottom: 7px;
}
.project-classic-2 .project-classic-content h4{
	font-size: 12px;
	opacity: .7;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #fff;
	font-weight: 700;
	margin-bottom: 10px;
}



/*--------------------
Style 3 
----------------------*/
.project-classic-3 {
	position: relative;
	overflow: hidden;
	margin: 15px 0px;
}

.masonry-grid .project-classic-3 {
	margin-bottom: 15px;
	margin-top: 0px;
}

.no-gutters .project-classic-3 {
	margin: 2px;
}

.project-classic-3 img {
	display: block;
	width: 100%;
	transform: scale(1);
	height: auto;
}

.project-classic-3 .project-classic-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0;
    background-color: rgba(0, 0, 0, .7);
    text-align: center;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;       
}

.project-classic-3:hover .project-classic-overlay {
	opacity: 1;
}
.project-classic-3:hover img {
	transform: scale(1.1);
    filter: blur(5px);
}

.project-classic-3 .project-classic-content {
	display: table;
    width: 100%;
    height: 100%;
    text-align: center;
}

.project-classic-3 .project-classic-content-inner {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 15px;
}

.project-classic-3 .project-classic-content h3:before{
	content: "";
	display: block;
	width: 100px;
	height: 2px;
	background: #2387ea;
	margin: 15px auto;
}

.project-classic-3 .project-classic-content h3{
	font-size: 17px;
	text-transform: uppercase;
	color: #fff;
	font-weight: 700;
	margin-bottom: 8px;
}

.project-classic-3 .project-classic-content h4{
	font-size: 11px;
	opacity: .7;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #fff;
	font-weight: 700;
	margin-bottom: 10px;
}





/*-----------------------------------------
21. Gallery
-------------------------------------------*/
.justified_gallery{
	overflow: hidden;
	position: relative;
}

.gallery-item {
	position: relative;
	overflow: hidden;
	margin: 15px 0px;
}

.no-gutters .gallery-item {
	margin: 0px 0px !important;
}

.justified_gallery .gallery-item {
	width: 100%;
	margin: 0px 0px !important;
}


/*--------------------
Style 1
----------------------*/
.gallery-style-1{
	position: relative;
	overflow: hidden;
}

.gallery-style-1 .gallery-item img {
	display: block;
	width: 100%;
	height: auto;
	overflow: hidden;
}

.gallery-style-1 .gallery-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0;
    background-color: rgba(0, 0, 0, .9);
    text-align: center;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;       
}

.gallery-style-1 .gallery-item:hover .gallery-overlay {
	opacity: 1;
}
.gallery-style-1 .gallery-item:hover img {
	transform: scale(1.1);
    filter: blur(5px);
}

.gallery-style-1 .gallery-content {
	display: table;
    width: 100%;
    height: 100%;
    text-align: center;
}

.gallery-style-1 .gallery-content-inner {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 15px;
}

.gallery-style-1 .gallery-content i{
	display: inline-block;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, .1);
	font-size: 25px;
	color: #fff;
	margin: 5px;
	padding: 20px 20px 20px 20px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;  	
}

.gallery-style-1 .gallery-content i:hover{
	background-color: rgba(0, 0, 0, .4);
}




/*--------------------
Style 2
----------------------*/
.gallery-style-2{
	position: relative;
}

.gallery-style-2 .gallery-item img {
	display: block;
	width: 100%;
	height: auto;
}

.gallery-style-2 .gallery-overlay {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    opacity: 0;
    background-color: rgba(255, 255, 255, .7);
    text-align: center;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;       
}

.gallery-style-2 .gallery-item:hover .gallery-overlay {
	opacity: 1;
}
.gallery-style-2 .gallery-item:hover img {
	transform: scale(1.1);
    filter: blur(5px);
}

.gallery-style-2 .gallery-content {
	display: table;
    width: 100%;
    height: 100%;
    text-align: center;
}

.gallery-style-2 .gallery-content-inner {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 15px;
}

.gallery-style-2 .gallery-content i{
	border-radius: 50%;
	background-color: rgba(255, 255, 255, .5);
	font-size: 25px;
	color: #222;
	margin: 5px;
	padding: 20px 20px 20px 20px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;  	
}

.gallery-style-2 .gallery-content i:hover{
	background-color: rgba(255, 255, 255, .7);
}




/*--------------------
Style 3
----------------------*/
.gallery-style-3{
	position: relative;
}

.gallery-style-3 .gallery-item img {
	display: block;
	width: 100%;
	height: auto;
	transition: all 3s ease-in-out;
}

.gallery-style-3 .gallery-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0;
    background-color: rgba(0, 0, 0, .7);
    text-align: center;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;       
}

.gallery-style-3 .gallery-item:hover .gallery-overlay {
	opacity: 1;
}
.gallery-style-3 .gallery-item:hover img {
	transform: scale(1.2);
	transition: all 3s ease-in-out;
}

.gallery-style-3 .gallery-content {
	display: table;
    width: 100%;
    height: 100%;
    text-align: center;
}

.gallery-style-3 .gallery-content-inner {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 15px;
}

.gallery-style-3 .gallery-content i{
	background-color: rgba(0, 0, 0, .5);
	border-radius: 50%;
	font-size: 25px;
	color: #fff;
	margin: 5px;
	padding: 20px 20px 20px 20px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;  	
}

.gallery-style-3 .gallery-content i:hover{
	background-color: rgba(0, 0, 0, .7);
}




/*--------------------
Style 4
----------------------*/
.gallery-style-4{
	position: relative;
}

.gallery-style-4 .gallery-item img {
	display: block;
	width: 100%;
	height: auto;
	transition: all 3s ease-in-out;
}

.gallery-style-4 .gallery-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0;
    background-color: #2387ea;
    text-align: center;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;       
}

.gallery-style-4 .gallery-item:hover .gallery-overlay {
	opacity: 1;
}
.gallery-style-4 .gallery-item:hover img {
	transition: all 3s ease-in-out;
}

.gallery-style-4 .gallery-content {
	display: table;
    width: 100%;
    height: 100%;
    text-align: center;
}

.gallery-style-4 .gallery-content-inner {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 15px;
}

.gallery-style-4 .gallery-content i{
	border-radius: 50%;
	font-size: 25px;
	color: #fff;
	margin: 5px;
	padding: 20px 20px 20px 20px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;  	
}






/*--- Magnific Popup Styles ---*/
.mfp-bg{
	z-index: 99998;
}

.mfp-wrap{
	z-index: 99999;
}
.mfp-fade.mfp-bg {
	opacity: 0;
	-webkit-transition: all 0.15s ease-out; 
	-moz-transition: all 0.15s ease-out; 
	transition: all 0.15s ease-out;
}
.mfp-fade.mfp-bg.mfp-ready {
	opacity: 0.8;
}
.mfp-fade.mfp-bg.mfp-removing {
	opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
	opacity: 0;
	-webkit-transition: all 0.15s ease-out; 
	-moz-transition: all 0.15s ease-out; 
	transition: all 0.15s ease-out;
}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
	opacity: 1;
}
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
	opacity: 0;
}


/*--- Controllers ---*/
.mfp-arrow-left:before {
    border-right: none;
    content: "\e629";
    font-size: 2em;
    font-family: 'themify';
    color: #fff;
}
.mfp-arrow-left:after {
    border-right: none;
    content: none;
    font-size: 2em;
    font-family: 'themify';
    color: #fff;
}

.mfp-arrow-right:before {
    border-left: none;
    content: "\e628";
    font-size: 2em;
    font-family: 'themify';
    color: #fff;
}
.mfp-arrow-right:after {
    border-right: none;
    content: none;
    font-size: 2em;
    font-family: 'themify';
    color: #fff;
}

.mfp-close{
	margin: 15px;
}





/*-----------------------------------------
22. Gallery Carousel
-------------------------------------------*/
.gallery-carousel-container img{
	border-radius: 10px;
    -webkit-box-shadow: 0 20px 80px rgba(0,0,0,0.12);
    box-shadow: 0 20px 80px rgba(0,0,0,0.12);
    margin: 50px 0px 90px 0px;
}

.gallery-carousel-item img{
	z-index: 1;
	-webkit-transform: scale(0.9);
	transform: scale(0.9);
	opacity: 0.6;
	-webkit-transition: 0.8s;
	transition: 0.8s;	
}

.gallery-carousel-item .owl-item{
	padding: 0px;
}

.gallery-carousel-container .center{
	z-index: 99;
}

.gallery-carousel-container .center img{
	position: relative;
	opacity: 1;
	-webkit-transform: scale(1.05);
	transform: scale(1.05);
	-webkit-box-shadow: 0 20px 80px rgba(0,0,0,0.25);
    box-shadow: 0 20px 80px rgba(0,0,0,0.25);
}

@media screen and (max-width:767px) {
	.gallery-carousel-item .owl-item.active.center img{	
		padding: 30px;
	}
}




/*-----------------------------------------
23. Gallery Grid
-------------------------------------------*/
.gallery-item img{
	width: 100%;
}

@media screen and (max-width: 1200px) {
	.grid-gallery-5col, .grid-gallery-4col, .grid-gallery-3col, .grid-gallery-2col{
		margin: -7.5px !important;
	}	
}


/*--------------------
	2 Columns
----------------------*/
.grid-gallery-2col{
	margin: 0px -15px;  
}

.grid-gallery-2col .gallery-item{
	width: calc(50% - 30px);
	float: left;
	margin: 15px;
}

@media screen and (max-width: 991.98px) {
	.grid-gallery-2col .gallery-item{
		width: calc(50% - 15px);
		float: left;
		margin: 7.5px;
	}	
}

@media screen and (max-width: 767.98px) {
	.grid-gallery-2col .gallery-item{
		width: calc(50% - 15px);
		float: left;
		margin: 7.5px;
	}	
}

@media screen and (max-width: 575.98px) {
	.grid-gallery-2col{
		margin: 0px 0px !important;  
	}	
	.grid-gallery-2col .gallery-item{
		float: none !important;
		width: 100% !important;
		margin: 15px 0px !important;
	}	
}



/*--------------------
	3 Columns
----------------------*/
.grid-gallery-3col{
	margin: 0px -15px;  
}

.grid-gallery-3col .gallery-item{
	width: calc(100% / 3 - 30px);
	float: left;
	margin: 15px;
}

@media screen and (max-width: 991.98px) {
	.grid-gallery-3col .gallery-item{
		width: calc(100% / 3 - 15px);
		float: left;
		margin: 7.5px;
	}	
}

@media screen and (max-width: 767.98px) {
	.grid-gallery-3col .gallery-item{
		width: calc(50% - 15px);
		float: left;
		margin: 7.5px;
	}	
}

@media screen and (max-width: 575.98px) {
	.grid-gallery-3col{
		margin: 0px 0px !important;  
	}	
	.grid-gallery-3col .gallery-item{
		float: none !important;
		width: 100% !important;
		margin: 15px 0px !important;
	}	
}


/*--------------------
	4 Columns
----------------------*/
.grid-gallery-4col{
	margin: 0px -15px;  
}

.grid-gallery-4col .gallery-item{
	width: calc(25% - 30px);
	float: left;
	margin: 15px;
}

@media screen and (max-width: 991.98px) {
	.grid-gallery-4col .gallery-item{
		width: calc(33% - 15px);
		float: left;
		margin: 7.5px;
	}	
}

@media screen and (max-width: 767.98px) {
	.grid-gallery-4col .gallery-item{
		width: calc(100% / 2 - 15px);
		float: left;
		margin: 7.5px;
	}	
}

@media screen and (max-width: 575.98px) {
	.grid-gallery-4col{
		margin: 0px 0px !important;  
	}	
	.grid-gallery-4col .gallery-item{
		float: none !important;
		width: 100% !important;
		margin: 15px 0px !important;
	}	
}



/*--------------------
	5 Columns
----------------------*/
.grid-gallery-5col{
	margin: 0px -7.5px;  
}

.grid-gallery-5col .gallery-item{
	width: calc(20% - 15px);
	float: left;
	margin: 7.5px;
}

@media screen and (max-width: 991.98px) {
	.grid-gallery-5col .gallery-item{
		width: calc(33% - 15px);
		float: left;
		margin: 7.5px;
	}	
}

@media screen and (max-width: 767.98px) {
	.grid-gallery-5col .gallery-item{
		width: calc(50% - 15px);
		float: left;
		margin: 7.5px;
	}	
}

@media screen and (max-width: 575.98px) {
	.grid-gallery-5col{
		margin: 0px 0px !important;  
	}	
	.grid-gallery-5col .gallery-item{
		float: none !important;
		width: 100% !important;
		margin: 15px 0px !important;
	}	
}




/*-----------------------------------------
24. Gallery Masonry
-------------------------------------------*/
.wrapper {
    width: 100%;
    margin: 0px auto;
}

.masonry-item:first-child{
    margin-top: 0px !important;
}


/*--------------------
	2 Columns
----------------------*/
.masonry-2col {
	-moz-column-count: 2;
	-webkit-column-count: 2;
	column-count: 2;
}

@media only screen and (max-width: 991.98px) {
    .masonry-2col {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
    }
}

@media only screen and (max-width: 767.98px) {
    .masonry-2col {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
    }
}

@media only screen and (max-width: 575.98px) {
    .masonry-2col {
        -moz-column-count: 1;
        -webkit-column-count: 1;
        column-count: 1;
    }
}



/*--------------------
	3 Columns
----------------------*/
.masonry-3col {
	-moz-column-count: 3;
	-webkit-column-count: 3;
	column-count: 3;
}

@media only screen and (max-width: 991.98px) {
    .masonry-3col {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
    }
}

@media only screen and (max-width: 767.98px) {
    .masonry-3col {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
    }
}

@media only screen and (max-width: 575.98px) {
    .masonry-3col {
        -moz-column-count: 1;
        -webkit-column-count: 1;
        column-count: 1;
    }
}



/*--------------------
	4 Columns
----------------------*/
.masonry-4col {
	-moz-column-count: 4;
	-webkit-column-count: 4;
	column-count: 4;
}

@media only screen and (max-width: 991.98px) {
    .masonry-4col {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
    }
}

@media only screen and (max-width: 767.98px) {
    .masonry-4col {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
    }
}

@media only screen and (max-width: 575.98px) {
    .masonry-4col {
        -moz-column-count: 1;
        -webkit-column-count: 1;
        column-count: 1;
    }
}



/*--------------------
	5 Columns
----------------------*/
.masonry-5col {
	-moz-column-count: 5;
	-webkit-column-count: 5;
	column-count: 5;
}

@media only screen and (max-width: 991.98px) {
    .masonry-5col {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
    }
}

@media only screen and (max-width: 767.98px) {
    .masonry-5col {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
    }
}

@media only screen and (max-width: 575.98px) {
    .masonry-5col {
        -moz-column-count: 1;
        -webkit-column-count: 1;
        column-count: 1;
    }
}





/*-----------------------------------------
25. Shop Grid
-------------------------------------------*/
/*--- Style 1 ---*/
.shop-classic-3{
    overflow: hidden;
    position: relative;
    border: 1px solid #eee;
    box-shadow: 0px 5px 40px 0px rgba(70, 70, 70, 0.10);
    margin: 20px 0px;
    -webkit-transition-duration: .5s;
    transition-duration: .5s; 
}
.shop-classic-3:hover{
    box-shadow: 0px 5px 40px 0px rgba(70, 70, 70, 0.16);
}

.shop-classic-3-img{
    overflow: hidden;
    display: block;
    position: relative;
}

.shop-classic-3-img img{
    display: block;
    width: 100%;
    height: auto;
    -webkit-transition-duration: .5s;
    transition-duration: .5s; 
}
.shop-classic-3:hover .shop-classic-3-img img{
    transform: scale(1.06)
}

.shop-classic-3-overlay{
    position: absolute;
    bottom: 0;
    left: 2.5%;
    right: 0;
    bottom: 2.6%;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.shop-classic-3-overlay ul{
    color: #111;
    font-size: 22px;
    font-weight: 400;
    position: absolute;
    top: 30px;
    right: 15px;
    opacity: 0;
    -webkit-transition-duration: .5s;
    transition-duration: .5s; 
}

.shop-classic-3:hover ul{
    right: 25px;
    opacity: 1;
}
.shop-classic-3:hover ul li:nth-child(1){
    -webkit-animation: 400ms ease-in-out fadeInRight;
    animation: 400ms ease-in-out fadeInRight;
    right: 30px;
    opacity: 1;
}
.shop-classic-3:hover ul li:nth-child(2){
    -webkit-animation: 600ms ease-in-out fadeInRight;
    animation: 600ms ease-in-out fadeInRight;
    right: 30px;
    opacity: 1;
}
.shop-classic-3:hover ul li:nth-child(3){
    -webkit-animation: 800ms ease-in-out fadeInRight;
    animation: 800ms ease-in-out fadeInRight;
    right: 30px;
    opacity: 1;
}

.shop-classic-3-overlay ul li a{
    display: inline-block;
    padding: 10px 20px;
    background: #fff;
    margin-bottom: 5px;
    -webkit-transition-duration: .4s;
    transition-duration: .4s; 
}
.shop-classic-3-overlay ul li a:hover{
    padding: 10px 20px;
    background: #2387ea;
    margin-bottom: 5px;
}

.shop-classic-3-overlay ul li a i{
    color: #222;
    font-size: 20px;
    -webkit-transition-duration: .4s;
    transition-duration: .4s; 
}
.shop-classic-3-overlay ul li:hover a i{
    color: #fff;
    font-size: 20px;
}

.shop-classic-3-price{
    position: relative;
    text-align: center;
    padding: 15px 10px 20px 10px;
}

.shop-classic-3-price h4 a{
    color: #222;
    font-size: 18px;
    font-weight: 600;
    line-height: 1;
}
.shop-classic-3:hover .shop-classic-3-price h4 a{
    color: #2387ea;
}

.shop-classic-3-price h5{
    margin-top: 3px;
}
.shop-classic-3-price h5 span{
    text-decoration: line-through;
    color: #999;
    font-size: 18px;
    font-weight: 500;
    margin-right: 10px;
}
.shop-classic-3-price h5 strong{
    color: #666;
    font-size: 18px;
    font-weight: 600;
}

@media screen and (max-width:767px) {
    .shop-classic-3-price h4 a{
        font-size: 16px;
    }
    .shop-classic-3-price h5 span{
        font-size: 14px;
    }
    .shop-classic-3-price h5 strong{
        font-size: 14px;
    }
}



/*--- Style 2 ---*/
.shop-classic{
    border: 1px solid #eee;
    background: #fff; 
    border-radius: 5px;
    text-align: center;
    box-shadow: 0px 5px 40px 0px rgba(70, 70, 70, 0.10);
    padding-bottom: 20px;
    margin: 15px 0px; 
}

.shop-classic-img {
    position: relative;
    overflow: hidden;
}

.shop-classic-img img{
    display: block;
    width: 100%;
    height: auto;
    -webkit-transition-duration: .4s;
    transition-duration: .4s;
}
.shop-classic:hover .shop-classic-img img{
    transform: scale(1.06);
}

.shop-classic-text{
    margin: 15px 0px 30px;
}

.shop-classic-text h4{
    color: #222;
    font-size: 19px;
    font-weight: 600;
}

.shop-classic-text h5{
    color: #666;
    font-size: 17px;
    font-weight: 600;
    margin-top: 5px;
}

.shop-classic-text h6{
    margin-top: 5px;
}

.shop-classic-text h6 span{
    text-decoration: line-through;
    color: #999;
    font-size: 16px;
    font-weight: 500;
}

.shop-classic-text h6 strong{
    color: #999;
    font-size: 16px;
    font-weight: 600;
    margin-left: 5px;
}

.col-md-3 .shop-classic-text h4{
    color: #222;
    font-size: 16px;
    font-weight: 600;
}
.col-md-3 .shop-classic-text h5{
    color: #666;
    font-size: 15px;
    font-weight: 600;
    margin-top: 5px;
}


.shop-classic-text ul{
   margin-top: 5px;
}

.shop-classic-text ul li{
    color: #2387ea;
    font-size: 14px;
    display: inline-block;
}

.shop-classic{
    position: relative;
}

.image{
    display: block;
    width: 100%;
    height: auto;
}

.shop-classic-button{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #2387ea;
    overflow: hidden;
    width: 100%;
    height: 0;
    -webkit-transition-duration: .4s;
    transition-duration: .4s;
}
.shop-classic-button:hover{
    background: #222;
}
.shop-classic:hover .shop-classic-button{
    height: 42px;
}

.shop-classic-button i{
    display: inline-block;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
}
.shop-classic-button p{ 
    display: inline-block;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    margin: 8px 0px 0px 7px;
}



/*--- Style 3 ---*/
.shop-classic-2{
    position: relative;
    box-shadow: 0px 5px 40px 0px rgba(70, 70, 70, 0.10);
    margin: 20px 0px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
}
.shop-classic-2:hover{
    position: relative;
    box-shadow: 0px 5px 40px 0px rgba(70, 70, 70, 0.16);
}

.shop-classic-2-img{
    border: 1px solid #eee;
}

.shop-classic-2 img{
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.shop-classic-2-price{
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.shop-classic-2-img:hover img {
  opacity: 0.2;
}
.shop-classic-2-img:hover .shop-classic-2-price{
  opacity: 1;
}

.shop-classic-2-price h5{
    text-decoration: line-through;
    color: #666;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
}

.shop-classic-2-price h4{
    color: #222;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 1px;
}

.shop-classic-2-price ul{
    margin-top: 5px;
}

.shop-classic-2-img ul li{
    display: inline-block;
    color: #2387ea; 
    font-size: 14px;
}

.shop-classic-2-price h6 a{
    text-transform: uppercase;
    color: #2387ea;
    font-size: 12px;
    font-weight: 900;
    margin-top: 3px;
    letter-spacing: 1px;
    -webkit-transition-duration: .4s;
    transition-duration: .4s;
}
.shop-classic-2-price h6 a:hover{
    color: #222;
}

.shop-classic-2-text{
    text-align: center; 
    padding: 15px 0px 25px 0px;
}

.shop-classic-2-text h3 a{
    color: #222;
    font-size: 20px;
    font-weight: 600;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
}
.shop-classic-2-text h3 a:hover{
    color: #2387ea;
}

.shop-classic-2-text-cart a{
    background: #2387ea;
    border-radius: 3px; 
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 5px 14px;
    -webkit-transition-duration: .4s;
    transition-duration: .4s;
}
.shop-classic-2-text-cart a:hover{
    background: #222;
}

.col-md-3 .shop-classic-2-text h3 a{
    color: #222;
    font-size: 17px;
    font-weight: 600;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
}


.shop-classic-sale-img{
    display: block;
    float: right;
    position: absolute;
    text-align: center;
    background: #2387ea;
    left: 20px;
    top: 5px;
    min-width: 60px;
    padding: 4px 0px;
    margin: 10px 0px 15px 0px;
}

.shop-classic-sale-img h4{
    text-transform: uppercase;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
    margin-top: 0px;
}

.shop-classic-hot-img{
    display: block;
    float: right;
    position: absolute;
    text-align: center;
    background: #5cb85c;
    left: 20px;
    top: 5px;
    min-width: 60px;
    padding: 4px 0px;
    margin: 10px 0px 15px 0px;
}

.shop-classic-hot-img h4{
    text-transform: uppercase;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-top: 0px;
}




/*--- Shop List ---*/
.shop-list-2{
    border: 1px solid #eee;
    background: #fff;
    box-shadow: 0px 5px 40px 0px rgba(70, 70, 70, 0.10);
    margin-top: 35px;
    -webkit-transition-duration: .4s;
    transition-duration: .4s;
}

.shop-list-2:hover{
   box-shadow: 0px 5px 40px 0px rgba(70, 70, 70, 0.16);
}

.shop-list-2-img{
    overflow: hidden;
    position: relative;
}

.shop-list-2-img img{
    display: block;
    width: 100%;
    height: auto;
}

.shop-list-2-img:hover img{
    transform: scale(1.06)
}

.shop-list-2-info{
    background: #fff;
    padding: 50px;
}

.shop-list-2-price{
    padding-bottom: 35px;
    border-bottom: 1px solid #ddd;
}

.shop-list-2-price-name h4{
    color: #222;
    font-size: 30px;
    font-weight: 600;
}

.shop-list-2-price-name h5{
    color: #2387ea;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 5px;
}

.shop-list-2-price-digits h4{
    display: inline-block;
    text-align: right;
    color: #999;
    font-size: 20px;
    font-weight: 500;
    margin-right: 20px;
    text-decoration: line-through;
}

.shop-list-2-price-digits h3{
    display: inline-block;
    text-align: right;
    color: #666;
    font-size: 25px;
    font-weight: 500;
}

.shop-list-2-text p{
    color: #999;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.8;
    padding: 40px 0px;
}

.add-cart{
    background: #fff;
    display: inline-block;
    border: 1.5px solid #eee;
    border-radius: 30px;
    margin-right: 15px;
    color: #222;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 12px 25px;
	margin-top: 5px;   
    -webkit-transition-duration: .4s;
    transition-duration: .4s;
}
.add-cart:hover{
    color: #222;
    background: #eee;
}

.more-detail{
    background: #2387ea;
    display: inline-block;
    border: 1.5px solid #2387ea;
    border-radius: 30px;
    color: #fff;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 11px 25px;
    margin-top: 5px;
    -webkit-transition-duration: .4s;
    transition-duration: .4s;
}
.more-detail:hover{
    background: #222;
    border-color: #222;
    color: #fff;
}

@media (max-width: 991px) {
    .shop-list-2-info{
        padding: 20px;
    }
}



/*--- Shop Cart ---*/
.shop-cart-box{
    background: #fff;
    display: inline-block;
    border: 1px solid #ececec;
    margin: 5px 0px 10px 0px; 
    padding: 10px 0px;
}

.button-close{
    display: block;
    padding: 35px 12px;
    margin-left: 5px;
}

@media (max-width: 991px) {
    .button-close{
        display: block;
        padding: 5px 0px;
    }
}
@media (max-width: 767px) {
    .button-close{
        display: block;
        padding: 5px 0px;
    }
}   

.button-close a i{
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    padding: 5px;
    background: #2387ea;
    border-radius: 3px;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
}

.button-close a i:hover{
    background: #888;
}



.shop-cart-box-img img{
    width: 100%;
    height: auto;
}

.shop-cart-box-info{
    display: block;
    padding: 20px 0px 0px 20px;
}

@media (max-width: 991px) {
    .shop-cart-box-info{
        display: block;
        padding: 20px 0px 0px 10px;
    }
}

.shop-cart-box-info h4{
    color: #222;
    font-size: 20px;
    font-weight: 500;
}

.shop-cart-box-info span{
    color: #999;
    font-size: 15px;
    font-weight: 400;
}

.shop-cart-box-quantity{
    padding-top: 20px;
}

.shop-cart-box-quantity input{
    max-width: 60px;
    box-shadow: none;
    display: inline-block;
}

.form-control{
    color: #999;
    font-size: 14px;
    line-height: inherit;
    border: 1px solid #ddd;
    border-radius: 2px;
    padding: 5px 12px;
    transition: 0.2s linear;
}
.form-control:focus {
    box-shadow: none;
    border-color: #2387ea;
}

.shop-cart-box-quantity h6{
    display: inline-block;
    color: #222;
    font-size: 14px;
    font-weight: 500;
    margin-right: 5px;
}
@media (max-width: 991px) {
    .shop-cart-box-quantity{
        padding: 20px 0px 0px 10px;
    }
}


.shop-cart-box-price{
    display: block;
    padding-top: 20px;
}

.shop-cart-box-price h5{
    color: #555;
    font-size: 25px;
    font-weight: 300;
    margin-right: 15px;
}

@media (max-width: 991px) {
    .shop-cart-box-price h5{
        color: #222;
        font-size: 15px;
        margin-left: 10px;
    }
}
@media (max-width: 767px) {
    .shop-cart-box-price h5{
        font-size: 20px;
    }
}


/*right*/
.shop-cart-info-price{
    padding: 20px;
    border: 1px solid #ececec;
    margin: 5px 0px;
}

.right-info-price li{
    color: #666;
    font-size: 16px;
    font-weight: 500;
    line-height: 160%;
    padding: 10px 0px;
}

.right-info-price li h6{
    float: right;
    color: #666;
    font-size: 16px;
    font-weight: 500;
}

.total-price{
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #ddd;
}

.total-price p{
    color: #222;
    font-size: 17px;
    font-weight: 500;
}

.total-price p strong{
    float: right;
    color: #222;
    font-size: 17px;
    font-weight: 500;
}




/*-----------------------------------------
26. Contact us
-------------------------------------------*/
/*--------------------
Style 1
----------------------*/
.map-1{
	height: 350px;
}

.contact-box-place-office{
    position: relative;
    text-align: center;
    background: #fff;
    border: 1px solid #ddd;
    padding: 40px 80px; 
    border-radius: 5px;
    margin-bottom: 15px;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s; 
}
.contact-box-place-office:hover{
    background: #f7f7f7;
    border: 1px solid #eee;
}

.contact-box-place-office i{
    color: #222;
    font-size: 45px;
}

.contact-box-place-office h4{
    color: #222;
    font-size: 22px;
    font-weight: 600;
    margin-top: 10px;
}

.contact-box-place-office h6{
    color: #555;
    font-size: 14px;
    font-weight: 600;
    padding: 0px 30px;  
    margin-top: 7px;
}

.contact-box-place-office p{
    color: #666;
    font-size: 13px;
    font-weight: 500;
    margin-top: 7px;
    line-height: 1.6;
}

.contact-box-place-office ul{
    margin-top: 5px;
}

.contact-box-place-office ul li{
    color: #666;
    font-size: 14px;    
    font-weight: 600;
    padding: 5px 0px;
}

.contact-box-place-office ul li i{
    color: #666;
    font-size: 14px;
    margin-right: 15px;
}


/*--------------------
Style 2
----------------------*/
.contact-box-data{
    position: relative;
    padding: 10px;
    background: #fff;
}

.contact-box-place{
    margin-top: 35px;
}

.contact-box-icon{
   width: 20%;
   height: auto;
   float: left;
}

.contact-box-icon i{
    color: #2387ea;
    font-size: 30px;
    padding: 30px 10px 0px 10px;
}

.contact-box-text{
    width: 80%;
    height: auto;
    float: left;
}

.contact-box-text h5{
    color: #222;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: -1px;
    word-spacing: 1px; 
    text-transform: uppercase;
}

.contact-box-text p{
    color: #999;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.6;
    margin-top: 8px;
}   

.contact-box-4 h4{
    color: #222;
    font-size: 30px;
    font-weight: 600;
}

.contact-box-4 textarea{
	min-height: 200px;
}


/*--------------------
Style 3
----------------------*/
.get-contact-1{
    background: #f9f9f9;
    overflow: hidden;
    padding: 27px 30px 26px 30px;
}

.contact-1-box-social{
    padding-left: 30px;
    position: relative;
    margin-top: 0px;
}

.contact-1-box-text{
    padding-left: 5px;
}

.contact-1-box-text span{
    color: #2387ea;
    font-size: 13px;
    font-weight: 600;
}

.contact-1-box-text p{
    display: block;
    color: #999;
    font-size: 15px;
    line-height: 1.5;
    margin-top: 8px; 
}

.social-left{
    margin-top: 40px;
    float: left;
    width: 50%;
}

.contact-1-box-social i{
    font-size: 20px;
    left: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0px, -50%);
    -moz-transform: translate(0px, -50%);
    -ms-transform: translate(0px, -50%);
    -o-transform: translate(0px, -50%);
    transform: translate(0px, -50%);
    color: #2387ea;
}


@media (max-width: 1400PX) {
    .social-left {
        margin-top: 40px;
        width: 100%;
    }
}
@media (max-width: 991px) {
    .contact-1-box{
        padding: 30px 10px 20px 20px;
    }
    .get-contact-1{
        padding: 20px;
    }  
}



/*-----------------------------------------
27. Project Single
-------------------------------------------*/
/*--------------------
Style 1
----------------------*/
.project-single-img{
    padding-bottom: 30px;
}

.project-single h4{
    color: #222;
    font-size: 19px;
    font-weight: 600; 
    margin-bottom: 25px;
}
.project-single h4:after{
    content: " ";
    display: block;
    background: #2387ea;
    width: 70px;
    height: 2px;
    margin-top: 8px;
}

.project-single-text p{
    color: #999;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.8;
    margin: 5px 0px; 
}

.project-single-text h5{
    color: #222;
    font-size: 18px;
    font-weight: 500;
    margin: 25px 0px 20px 0px; 
}
.project-single-text h5:after{
    content: " ";
    display: block;
    background: #2387ea;
    width: 70px;
    height: 2px;
    margin-top: 8px;
}

/*Right*/
.project-single-info{
    display: inline-block;
    display: block;
    width: 100%;
}

.project-single-info ul li{
    color: #999;
    font-size: 15px;
    font-weight: 500; 
    border-bottom: 1px solid #ececec;
    padding: 17px 0px;
}

.project-single-info ul li:last-child{
	border-bottom: none;
}

.project-single-info ul li span{
    display: inline-block;
    color: #222;
    font-size: 15px;
    font-weight: 600;
    margin-right: 25px;
    width: 80px;
    max-width: 100%;
}



/*-----------------------------------------
28. Shop Single
-------------------------------------------*/
.single-product{
	padding-left: 30px;
}
@media screen and (max-width:767px) {
	.single-product{
		padding-left: 0px;
		margin-top: 30px;
	}	
}
.single-product h2{
	font-size: 25px;
	font-weight: 600;
	color: #222;
}

.single-product p{
	font-size: 15px;
	font-weight: 400;
	line-height: 180%;
	color: #999;
	margin-top: 20px;
}

.product-review-area {
	margin-top: 15px;
}
.product-review-area i{
	color: #f9d100;
	font-size: 18px;
	margin-right: 2px;
}	
.product-review-area span{
	color: #111;
	font-size: 14px;
	margin-left: 10px;
}	

.single-product-price{
	margin-top: 20px;
}

.single-product-price h4{
	font-size: 25px;
	font-weight: 500;
	color: #222;
}

.product-categories{
	display: block;
	margin-top: 35px;
	border-top: 1px solid #eee;
	padding: 20px 5px 0px 5px;
}
.product-categories ul{
	display: block;
}

.product-categories span{
	font-size: 15px;
	font-weight: 600;
	color: #222;
	line-height: 2;
}

.product-categories ul{
	display: inline-block;	
	padding-left: 20px;
	margin-bottom: 0px;
}
.product-categories ul li{
	list-style-type: none;
	display: inline-block;
	color: #999;
	font-weight: 500;	
}
.product-categories ul li a{
	color: #999;
	font-size: 14px;
}
.product-categories ul li a:hover{
	color: #2387ea;
}
.product-categories ul li:after{
	content: ' / ';
	display: inline-block;
	font-size: 10px;
	color: #999;
	margin: 0px 5px;
}
.product-categories ul li:last-child:after{
	content: none;
}


.product-tags{
	display: block;
	padding: 0px 5px;
}
.product-tags ul{
	display: block;
}

.product-tags span{
	font-size: 15px;
	font-weight: 600;
	color: #222;
	line-height: 2;
}
.product-tags ul{
	display: inline-block;	
	padding-left: 20px;
	margin-bottom: 0px;
}
.product-tags ul li{
	list-style-type: none;
	display: inline-block;
	color: #999;
	font-weight: 500;	
}
.product-tags ul li a{
	color: #999;
	font-size: 14px;
}
.product-tags ul li a:hover{
	color: #2387ea;
}
.product-tags ul li:after{
	content: ', ';
	display: inline-block;
	font-size: 10px;
	color: #aaa;
	margin: 0px 5px;
}
.product-tags ul li:last-child:after{
	content: none;
}

.quantity {
  position: relative;
}

.quantity input[type=number]::-webkit-inner-spin-button,
.quantity input[type=number]::-webkit-outer-spin-button
{
  -webkit-appearance: none;
  margin: 0;
}

.quantity input[type=number]
{
  -moz-appearance: textfield;
}

.quantity input {
  width: 85px;
  height: 62px;
  line-height: 1.65;
  font-size: 16px;
  color: #222;
  font-weight: 600;
  float: left;
  display: block;
  padding: 0;
  margin: 0;
  padding-left: 20px;
  border: 2px solid #eee;

}

.quantity input:focus {
  outline: 0;
}

.quantity-nav {
  float: left;
  position: relative;
  height: 62px;
}

.quantity-button {
  position: relative;
  cursor: pointer;
  width: 30px;
  text-align: center;
  color: #222;
  background: #fff;
  border: 2px solid #ececec;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.7;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
}

.quantity-button.quantity-up {
  position: absolute;
  height: 50%;
  top: 0;
}

.quantity-button.quantity-down {
  position: absolute;
  bottom: 0px;
  height: 50%;
  border-top: none;
}

.add-to-cart-button{
	display: inline-block;
	background: #2387ea;
	color: #fff;
	font-size: 17px;
	font-weight: 600;
	padding: 18.5px 30px;	
	margin-left: 10px;	
	border-radius: 0px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;		
}
.add-to-cart-button:hover{
	background: #222;
	color: #fff;
}

.product-share{
	background: #f9f9f9;
	border-radius: 5px;
	padding: 20px 15px;
}
.product-share span{
	font-size: 15px;
	font-weight: 600;
	color: #222;
	margin-right: 15px;	
}
.product-share a i{
	color: #222;
	font-size: 15px;
	padding: 0px 7px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;		
}
.product-share a i:hover{
	color: #2387ea;
}



/*---------------
	Shop Tabs
-----------------*/
.shop-tabs .tabs{
	position: relative;
}

.shop-tabs .tabs .tab-menu li {
	background: #f9f9f9;
	display: inline-block;
	width: 33%;
	text-align: center;
}

.shop-tabs .tabs .tab-menu li a {
	color: #222;
	display: block;
	font-size: 15px;
	font-weight: 600;
	padding: 18px 24px;
	text-decoration: none;
}

.shop-tabs .tabs .tab-menu li:hover a, .tabs li:hover i {
	color: #2387ea;
}

.shop-tabs .tab-menu .active-tab a, .shop-tabs .active-tab a i {
	color: #2387ea !important;
}


.shop-tabs .tab-menu{
	margin-bottom: 4px;
}

@media screen and (max-width: 1200px) {
	.shop-tabs .tabs .tab-menu li {
		background: #f9f9f9;
		display: inline-block;
		text-align: center;
		width: auto;
	}	
	.shop-tabs .tabs .tab-menu li a {
		color: #111;
		display: block;
		font-size: 15px;
		font-weight: 500;
		padding: 18px 24px;
		text-decoration: none;
	}	
}

@media screen and (max-width:767px) {
	.shop-tabs .tabs .tab-menu li {
		display: block;
		width: 100%;
		text-align: center;
		margin-bottom: 4px;
	}
	.shop-tabs .tab-menu .tabs li a{
		display: block;
	}
	.shop-tabs .tab-menu .tabs li i {
		font-size: 25px;	
	}	
}

.product-tab-body{
	display: block;
	position: relative;
	padding: 25px;
	background: #f9f9f9 !important;
}

.product-tab-body h2{
	font-size: 30px;
	color: #222;
	font-weight: 300;
	margin-bottom: 10px;
}
.product-tab-body h3{
	font-size: 18px;
	color: #222;
	font-weight: 600;
	margin-bottom: 10px;
}
.product-tab-body h4{
	font-size: 20px;
	color: #222;
	font-weight: 600;
	margin-bottom: 10px;
}
.product-tab-body h5{
	font-size: 15px;
	color: #222;
	font-weight: 600;
	margin-bottom: 10px;
}
.product-tab-body h2:after, .product-tab-body h3:after, .product-tab-body h4:after, .product-tab-body h5:after{
	content: '';
	display: block;	
	width: 70px;
	height: 2px;
	background: #2387ea;
	margin-top: 15px;
	margin-bottom: 10px;
}

.product-tab-body ul{
	margin-top: 15px;
}
.product-tab-body ul li{
	display: block;
	font-size: 15px;
	color: #111;
	border-bottom: 1px dashed #ddd;
	line-height: 1.9;
	font-weight: 500;
	padding: 15px 5px;	
}
.product-tab-body ul li span{
	display: inline-block;
	width: 200px;
	max-width: 100%;
}
.product-tab-body ul li:before{
  content: "●";
  display: inline-block;
  color: #2387ea;
  font-size: 14px;
  margin-right: 10px;
  line-height: 1;
}

.product-tab-body p{
	font-size: 14px;
	color: #555;
	line-height: 1.9;
	font-weight: 400;
	margin: 15px 0px;
}

/*-------------------
Shop Product Review Form
---------------------*/

.shop-form-title{
	font-size: 15px;
	color: #111;
	font-weight: 500;
	line-height: 1;
}


.rating {
  display: inline-block;
  position: relative;
  height: 30px;
  font-size: 30px;
}

.rating label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  cursor: pointer;
  line-height: 1;
}

.rating label:last-child {
  position: static;
}

.rating label:nth-child(1) {
  z-index: 5;
}

.rating label:nth-child(2) {
  z-index: 4;
}

.rating label:nth-child(3) {
  z-index: 3;
}

.rating label:nth-child(4) {
  z-index: 2;
}

.rating label:nth-child(5) {
  z-index: 1;
}

.rating label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.rating label .icon {
  float: left;
  color: transparent;
}

.rating label:last-child .icon {
  color: #ccc;
}

.rating:not(:hover) label input:checked ~ .icon,
.rating:hover label:hover input ~ .icon {
  color: #f9d100;
}

.rating label input:focus:not(:checked) ~ .icon:last-child {
  color: #ccc;
  text-shadow: 0 0 5px #f9d100;
}


.review-input input{
	font-weight: 500;
	font-size: 14px;
	color: #222;
	display: block;
	margin-top: 15px;
	border-radius: 5px;
	border: 1px solid #eee;
	padding: 13px 15px;
	background: #fff;
	width: 100%;
	max-width: 100%;
	transition: .3s;
}
.review-input input:focus{
	border-color: #ccc;
}

.review-input textarea{
	font-weight: 500;
	font-size: 14px;
	color: #222;
	display: block;
	margin-top: 15px;
	border-radius: 5px;
	border: 1px solid #eee;
	padding: 13px 15px;
	background: #fff;
	width: 100%;
	min-height: 100px;
	transition: .3s;
}
.review-input textarea:focus{
	border-color: #ccc;
}


.customer-review{
	margin-top: 30px;
}
.customer-review-block{
	background: #fff;
	border-radius: 5px;
	border: 1px solid #eee;
	padding: 15px;
}
.customer-review-block h5{
	display: inline-block;
    color: #222;
    font-size: 17px;
    font-weight: 600;
    line-height: 1;
    margin: 0px;
}
.customer-review-block h5:after{
	content: none;
}

.customer-review-block strong{
	display: inline-block;
    color: #2387ea;
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    margin-left: 15px;
}
.customer-review-block p {
    font-size: 14px;
    color: #999;
    line-height: 1.9;
    font-weight: 400;
}
.customer-review-stars{
	text-align: center;
	margin: 10px 0px;
}
.customer-review-stars i{
    color: #f9d100;
    font-size: 10.5px;
}



/*-----------------------------------------
29. Shop Single(Carousel)
-------------------------------------------*/
.sr-text {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}

.slick-slider .slick-prev, .slick-slider .slick-next {
  display: none !important;
  z-index: 100;
  font-size: 2.5em;
  height: 40px;
  width: 40px;
  margin-top: -20px;
  color: #eee;
  position: absolute;
  top: 50%;
  text-align: center;
  color: #222;
  opacity: 1;
  transition: opacity .25s;
  cursor: pointer;
  background: #eee;
}

.slick-slider .slick-prev:hover, .slick-slider .slick-next:hover {
  opacity: .65;
}

.slick-slider .slick-prev {
  left: 0;
}

.slick-slider .slick-next {
  right: 0;
}

.wrapper {
  max-width: 1024px;
  margin: 0 auto;
  font-family: '';
}

.wrapper strong {
  font-weight: 300;
  letter-spacing: 2px;
  font-size: 1.2em;
  display: block;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  color: #666;
}

.product-images {
  width: 100%;
  margin: 0 auto;
}

.product-images li, .product-images figure, .product-images a, .product-images img {
  display: block;
  outline: none;
  border: none;
}

.product-images .main-img-slider figure {
  margin: 0 auto;
}

.product-images .main-img-slider figure a {
  cursor: e-resize;
}

.product-images .main-img-slider figure a img {
  width: 100%;
  margin: 0 auto;
}

.product-images .thumb-nav {
  margin: 0 auto;
  margin-top: 0.5px;
  width: 100%;
  padding: 0;
}

.product-images .thumb-nav.slick-slider .slick-prev, .product-images .thumb-nav.slick-slider .slick-next {
  font-size: 1.2em;
  height: 20px;
  width: 26px;
  margin-top: -10px;
}

.product-images .thumb-nav.slick-slider .slick-prev {
  margin-left: -30px;
}

.product-images .thumb-nav.slick-slider .slick-next {
  margin-right: -30px;
}

.product-images .thumb-nav li {
  display: block;
  margin: 0 auto;
  float: left;
  cursor: pointer;
  margin-right: 0px;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;	  
}
.product-images .thumb-nav li img {
  display: block;
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  border: 0.5px solid transparent;
  border-bottom: 2px solid transparent;
}

.product-images .thumb-nav li:hover {
  opacity: .7;
}


.product-images .thumb-nav li:hover, .product-images .thumb-nav li:focus {
  border-color: #ccc;
}

.product-images .thumb-nav li.slick-current img {
  border-bottom: 2px solid #2387ea;
}

.pswp__bg {
  background: #fff;
}

.pswp__top-bar {
  background-color: transparent !important;
}

.pswp__button,
.pswp__button:before,
.pswp__button--close:before,
.pswp__button--arrow--left:before,
.pswp__button--arrow--right:before {
  background: none !important;
  background-size: 100%;
  width: 44px;
  height: 44px;
  font-family: 'icomoon';
}

.pswp__button {
  color: #000 !important;
  opacity: 0.4 !important;
  transition: opacity .25s;
}

.pswp__button:hover {
  opacity: 0.65 !important;
}

.pswp__button:before {
  opacity: 1 !important;
}

.pswp__button.pswp__button--arrow--left:before, .pswp__button.pswp__button--arrow--right:before {
  font-size: 44px;
}

.pswp__button.pswp__button--arrow--left:before {
  content: "\e93b";
}

.pswp__button.pswp__button--arrow--right:before {
  content: "\e93c";
}

.pswp__button.pswp__button--close {
  top: 10px;
  right: 20px;
}

.pswp__button.pswp__button--close:before {
  content: "\e92a";
  font-size: 44px;
}

.pswp__button.pswp__button--close:hover {
  color: #222;
}





/*-----------------------------------------
30. Vertical Navs
-------------------------------------------*/
/*--- Style 1 ---*/
.vertical-nav-1{
	padding: 0px;
	margin: 0px;
}

.vertical-nav-1 li{
	list-style-type: none;
}

.vertical-nav-1 li a{
	display: block;
	font-size: 13px;
	font-weight: 600;
	color: #222;
	border-bottom: 1px solid #eee;
	padding: 12px 0px;
}

.vertical-nav-1 li a:hover{
	color: #2387ea;
}


/*--- Style 2 ---*/
.vertical-nav-2{
	padding: 0px;
	margin: 0px;
}

.vertical-nav-2 li{
	list-style-type: none;
}

.vertical-nav-2 li a{
	display: block;
	font-size: 13px;
	font-weight: 600;
	color: #2387ea;
	border-bottom: 1px solid #eee;
	padding: 12px 0px;
}

.vertical-nav-2 li a:hover{
	color: #222;
}



/*-----------------------------------------
31. Sidebars
-------------------------------------------*/
/*--- Style 1 ---*/
.sidebar-1{
	border: 1px solid #eee;
	background: #fbfbfb;
	border-radius: 5px;
	padding: 25px;
}

.sidebar-1 h3{
	color: #222;
	font-size: 18px;
	font-weight: 600;
	margin: 25px 0px 15px 0px;
}

.sidebar-1 h3:after{
	content: "";
	width: 50px;
	height: 2px; 
	background: #2387ea;
	display: block;
	margin-top: 8px;
}

.sidebar-1 h3:first-child{
	margin-top: 0px;
}


/*--- Style 2 ---*/
.blog-post-right{
    border-bottom: 1px solid #eee;
    padding-bottom: 30px;
}

/*search-form*/
#search-input{
    padding: 0px;
    border: solid 1px #eee;
    border-radius: 10px;
    background-color: #fff;
    padding: 3px;
}

#search-input input{
    border: 0;
    border-radius: 5px;
    box-shadow: none;
}

#search-input button{
    margin: 2px 0 0 0;
    background: none;
    box-shadow: none;
    border: 0;
    outline: none !important;
    padding: 0 11px 0 13px;
    border-left: solid 1px #ccc;
}

#search-input button:hover{
    border: 0;
    box-shadow: none;
    border-left: solid 1px #ccc;
}

#search-input button i{
    color: #999;
    font-size: 16px;
    margin: 8px 3px 0px 0px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s; 
}

#search-input button:hover i{
    color: #2387ea;
}

.blog-post-right h4{
    color: #222;
    font-size: 22px;
    font-weight: 600;
    margin-top: 35px;
}   

.blog-post-right h4:after {
    content: '';
    display: block;
    width: 50px;
    max-width: 100%;
    height: 2px;
    background: #2387ea;
    margin-top: 10px;
}

.blog-post-follow ul li{
    display: inline-block;
    border-radius: 5px;
    margin-right: 4px;
    -webkit-transition-duration: .4s;
    transition-duration: .4s; 
}

.blog-post-follow ul li:hover{
    background: #222; 
}

.blog-post-follow ul li a i{
    padding: 10px 11.5px;
    width: 40px;
    height: 40px;	
    color: #fff;
    font-size: 20px;
}

.social-facebook{
    background: #3B5999;
}
.social-youtube{
    background: #FF0000;
}
.social-twitter{
    background: #5BACEB;
}
.social-behance{
    background: #0058FE;
}
.social-pinterest{
    background: #BD081B;
}

/*Category*/
.blog-post-categories ul li{
    background: #f9f9f9;
    margin: 5px 0px;
    -webkit-transition-duration: .4s;
    transition-duration: .4s; 
}
.blog-post-categories ul li:hover{
    background: #f5f5f5;
}

.blog-post-categories ul li a{
    display: block;
    color: #222;    
    font-size: 14px;
    font-weight: 600;  
    padding: 15px;

}
.blog-post-categories ul li a:hover{
    color: #2387ea;
}

.blog-post-categories ul li a span{
    color: #222;    
    font-size: 13px;
    float: right;
    padding-right: 10px;
}
.blog-post-categories ul li:hover a span{
   color: #2387ea;
}


/*Recent-post*/
.recent-post-info{
    margin: 15px 0px;
}

.recent-post img{
    width: 100%;
    border-radius: 5px;
}

.recent-post h3 a{
    display: inline-block;
    color: #222;
    font-size: 14px;
    font-weight: 600;
}

.recent-post h3 a:hover{
    color: #2387ea;
}

.recent-post h6{
    color: #999;
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
}

.recent-post-tags a{
    margin: 3px 2px;
}

/*Archive*/
.archive-box ul li{
    border-bottom: 1px solid #eee;
}

.archive-box ul li a{
    display: inline-block;
    color: #666;
    font-size: 14px;
    font-weight: 500;
    line-height: 160%;
    padding: 10px 0px 10px 5px; 
    -webkit-transition-duration: .4s;
    transition-duration: .4s;
}

.archive-box ul li a:hover{
    color: #2387ea;
}

.archive-box ul li span{
    float: right;
    color: #666;
    font-weight: 600;
    font-size: 13px;
    margin-right: 10px;
    margin-top: 10px;
}






/*-----------------------------------------
32. Search Results
-------------------------------------------*/
.search-result{
	position: relative;
	border-bottom: 1px solid #eee;
	padding: 25px 0px;
}

.search-result:first-child{
	padding-top: 0px;
}

.search-result a{
	font-size: 19px;
}

.search-result p{
	margin-top: 5px;
}



/*-----------------------------------------
33. FAQ
-------------------------------------------*/
.faq-box{
	border-bottom: 2px solid #eee;
	margin: 20px 0px 15px 0px;
}





/*-----------------------------------------
34. Dropcaps
-------------------------------------------*/

/*--- Style 1 ---*/
.dropcap-1 p::first-letter{
	color: #222;
    float: left;
    font-size: 50px;
    margin: 0 10px 0 0;
    text-align: center;
    padding: 10px 0;
    font-weight: 600;	
}



/*--- Style 2 ---*/
.dropcap-2 p::first-letter{
	color: #fff;
	background: #222;
	border-radius: 3px;
    float: left;
    font-size: 40px;
    margin: 0 15px 0 0;
    text-align: center;
    padding: 12px 12px;
    font-weight: 600;	
}



/*--- Style 3 ---*/
.dropcap-3 p::first-letter{
	color: #fff;
	background: #2387ea;
	border-radius: 3px;
    float: left;
    font-size: 40px;
    margin: 0 15px 0 0;
    text-align: center;
    padding: 12px 12px;
    font-weight: 600;	
}



/*--- Style 4 ---*/
.dropcap-4 p::first-letter{
	color: #2387ea;
	border-radius: 3px;
    float: left;
    font-size: 40px;
    margin: 0 15px 0 0;
    text-align: center;
    padding: 12px 12px;
    font-weight: 600;	
}



/*--- Style 5 ---*/
.dropcap-5 p::first-letter{
	color: #2387ea;
	border-radius: 3px;
    float: left;
    font-size: 32px;
    border: 1px solid #eee;
    border-radius: 50%;
    margin: 0 10px 0 0;
    text-align: center;
    padding: 12px 16px;
    font-weight: 600;
}


/*--- Dropcap Sizes ---*/

.dropcap-sm p::first-letter{
	font-size: 40px;
    padding: 13px 16px;
}

.dropcap-md p::first-letter{
	font-size: 45px;
    padding: 20px 20px;
}

.dropcap-lg p::first-letter{
	font-size: 50px;
    padding: 25px 25px;
}





/*-----------------------------------------
35. Action Boxes
-------------------------------------------*/
.action-box{
	position: relative;
	overflow: hidden;
	padding: 30px 0px;
}


/*--- Action Box Sizes ---*/
.action-box-xs{
	padding: 15px 0px;
}

.action-box-sm{
	padding: 30px 0px;
}

.action-box-md{
	padding: 60px 0px;
}

.action-box-lg{
	padding: 90px 0px;
}



/*--- Action Box Headings ---*/
.action-box h2 strong,
.action-box h3 strong, 
.action-box h4 strong, 
.action-box h5 strong, 
.action-box h6 strong{
	font-weight: 700;
}

.action-box h2 i,
.action-box h3 i, 
.action-box h4 i, 
.action-box h5 i, 
.action-box h6 i{
	font-style: italic;
	font-family: 'Libre Baskerville', serif;
}

.action-box h2{
	position: relative;
	font-size: 36px;
	font-weight: 600;
	color: #222;
	line-height: 1.3;
	letter-spacing: -2px;
	word-spacing: 2px;	
}

@media screen and (max-width: 767px) {
	.action-box h2{
		font-size: 25px;
	}
}

.action-box h3{
	position: relative;
	font-size: 33px;
	font-weight: 400;
	color: #222;
	line-height: 1.3;
	letter-spacing: -2px;
	word-spacing: 2px;	
}
@media screen and (max-width: 767px) {
	.action-box h3{
		font-size: 27px;
	}
}
.action-box h4{
	position: relative;
	font-size: 28px;
	font-weight: 400;
	color: #222;
	line-height: 1.3;
	letter-spacing: -2px;
	word-spacing: 2px;	
}
.action-box h5{
	position: relative;
	font-size: 24px;
	font-weight: 400;
	color: #222;
	line-height: 1.3;
	letter-spacing: -2px;
	word-spacing: 2px;	
}
.action-box h6{
	position: relative;
	font-size: 18px;
	font-weight: 600;
	color: #222;
	line-height: 1.3;
	letter-spacing: -1px;
	word-spacing: 1px;	
}
.action-box p{
	font-size: 15px;
	font-weight: 500;
	color: #979797;
	line-height: 160%;
	padding-top: 5px;
	margin-bottom: 0px;
}

.action-box small{
	display: inline-block;
	font-size: 13px;
	font-weight: 700;
	color: #2387ea;
	line-height: 150%;
	text-transform: uppercase;
	margin-bottom: 10px;
}


/*-----------------------------------------
36. Clients
-------------------------------------------*/
.client-grid-item{
	padding: 20px 15px;
}






/*-----------------------------------------
37. Timeline
-------------------------------------------*/

.timeline {
    position: relative;
    max-width: 100%;
    margin: 0 auto;
}

.timeline::after {
    content: '';
    position: absolute;
    width: 2px;
    background-color: #eee;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
}

/* Container around content */
.timeline-container {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
}

/* The circles on the timeline */
.timeline-container::after {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    right: -7px;
    background-color: #fff;
    border: 2px solid #2387ea;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
}

.timeline-left {
    left: 0;
}

.timeline-right {
    left: 50%;
}

/* Add arrows to the left container (pointing right) */
.timeline-left::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid white;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent white;
}

/* Add arrows to the right container (pointing left) */
.timeline-right::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
}

.timeline-right::after {
    left: -11px;
}

/* The actual content */
.timeline-content {
    padding: 0px 0px 50px 0px;
    background-color: white;
    position: relative;
    border-radius: 6px;
}

.timeline-content-inner{
	background: #f7f7f7;
	border-radius: 10px;	
	padding: 20px 30px 30px 30px;
}

.timeline-content h3{
    display: block;
    color: #222;
    font-size: 16px;
    font-weight: 600;
    margin: 7px 0px;
}


.timeline h2{
    display: block;
    color: #2387ea;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -1px;
    word-spacing: 1px;
    margin-bottom: 15px;
}

.timeline-left-all h2{
	width: 50%;
	color: #2387ea;
	margin-left: 50%;
	padding-left: 30px;
	margin-bottom: -35px;	
}

.timeline-right-all h2{
	width: 50%;
	text-align: right;
	color: #2387ea;
	padding-right: 35px;
	margin-bottom: -35px;	
}



@media screen and (max-width: 600px) {
	.timeline::after {
		left: 31px;
	}

	.timeline-container {
		width: 100%;
		padding-left: 70px;
		padding-right: 25px;
	}

	.timeline-container::before {
		left: 60px;
		border: medium solid white;
		border-width: 10px 10px 10px 0;
		border-color: transparent white transparent transparent;
	}

	.timeline-left::after, .timeline-right::after {
		left: 20px;
	}

	.timeline-right {
		left: 0%;
	}

	.timeline-left-all h2{
		margin: 0px;
		width: 100%;
		padding-left: 65px;
		padding-bottom: 10px;
		margin-bottom: 0px !important;
		color: #2387ea;
		text-align: left !important; 
	}

	.timeline-right-all h2{
		margin: 0px;
		width: 100%;
		padding-left: 65px;
		padding-bottom: 10px;
		margin-bottom: 0px !important;
		color: #2387ea;
		text-align: left !important; 
	}

}


.timeline-left .timeline-content{
	text-align: right;
}

@media screen and (max-width: 600px) {
	.timeline-left .timeline-content{
		text-align: left;
	}		
}



/*-----------------------------------------
38. Top-Bar Styles
-------------------------------------------*/
/*--- Style 1 ---*/
#top-bar{
	position: relative;
	z-index: 99999;
	background: #fff;
	border-bottom: 1px solid #eee;
}
.top-bar-info {
	padding: 0px;
}
.top-bar-info li{
	font-size: 13px;
	font-weight: 500;
	color: #aaa;
	border-left: 1px solid #eee;
	list-style-type: none;
	float: left;
	margin: 0px;
	padding: 13.5px 14px 12.5px 14px;
}
.top-bar-info li i{
	font-size: 15px;
	color: #999;
	padding-right: 8px;
}

.social-icons{
	padding: 0px;
	float: right;
	margin: 0px;
}
.social-icons li{
	font-size: 14px;
	list-style-type: none;
	float: left;
	width: 50px;
	text-align: center;
	border-left: 1px solid #eee;
	margin: 0px;
	padding: 12px;	
}
.social-icons li:last-child{
	border-right: 1px solid #eee;
}
.social-icons li a{
	color: #777;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;	
}
.social-icons a:hover{
	color: #2387ea;
}


/*--- Style 2 ---*/
#top-bar-transparent{
	position: relative;
	z-index: 99999;
	background: rgba(0, 0, 0, .2);
	border-bottom: 1px solid rgba(255, 255, 255, .1);
}
#top-bar-transparent .top-bar-info {
	padding: 0px;
}
#top-bar-transparent .top-bar-info li{
	font-size: 13px;
	font-weight: 500;
	color: #aaa;
	border-left: 1px solid rgba(255, 255, 255, .1);
	list-style-type: none;
	float: left;
	margin: 0px;
	padding: 13.5px 14px 12.5px 14px;
}
#top-bar-transparent .top-bar-info li i{
	font-size: 15px;
	color: #999;
	padding-right: 8px;
}

#top-bar-transparent .social-icons{
	padding: 0px;
	float: right;
	margin: 0px;
}
#top-bar-transparent .social-icons li{
	font-size: 14px;
	list-style-type: none;
	float: left;
	width: 50px;
	text-align: center;
	border-left: 1px solid rgba(255, 255, 255, .1);
	margin: 0px;
	padding: 12px;	
}
#top-bar-transparent .social-icons li:last-child{
	border-right: 1px solid rgba(255, 255, 255, .1);
}
#top-bar-transparent .social-icons li a{
	color: #777;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;	
}
#top-bar-transparent .social-icons a:hover{
	color: #2387ea;
}



/*-----------------------------------------
39. 3D Flipbox
-------------------------------------------*/
.flipcard{ 
	position: relative;
	display: block;
	background-size: cover;
	background-repeat: no-repeat;	
	width: 100%; 
	height: 350px; 
	margin: 15px 0px;
}

.flipcard .front{
	width: 100%; 
	height: 350px; 
	position: absolute;
	transform: perspective( 600px ) rotateY( 0deg );
	background: #222;
	background-position: center;
	background-size: cover;
	backface-visibility: hidden;
    -webkit-transition: -webkit-transform .9s cubic-bezier(.5,.2,.2,.8);
    transition: -webkit-transform .9s cubic-bezier(.5,.2,.2,.8);
    transition: transform .9s cubic-bezier(.5,.2,.2,.8);
    transition: transform .9s cubic-bezier(.5,.2,.2,.8),-webkit-transform .9s cubic-bezier(.5,.2,.2,.8);
	perspective: 1000px;
	transform-style: preserve-3d;
}
.flipcard > .back{
	width: 100%; 
	height: 350px; 
	position:relative;
	padding: 60px 20px;
	transform: perspective( 600px ) rotateY( 180deg );
	background: #2387ea; 
	backface-visibility: hidden;
	transform-style: preserve-3d;
    -webkit-transition: -webkit-transform .9s cubic-bezier(.5,.2,.2,.8);
    transition: -webkit-transform .9s cubic-bezier(.5,.2,.2,.8);
    transition: transform .9s cubic-bezier(.5,.2,.2,.8);
    transition: transform .9s cubic-bezier(.5,.2,.2,.8),-webkit-transform .9s cubic-bezier(.5,.2,.2,.8);
}
.flipcard:hover > .front{
	transform: perspective( 600px ) rotateY( -180deg );
}
.flipcard:hover > .back{
	transform: perspective( 600px ) rotateY( 0deg );
}


.flipcard .front h2{
	color: #fff;
    position: absolute;
    width: 100%;
    font-size: 25px;
    display: block;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-perspective: inherit;
    perspective: inherit;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 999;
    top: 50%;
    left: 0;
    text-align: center;
    -webkit-transform: translate3d(0,-50%,50px);
    transform: translate3d(0,-50%,50px);
}

.flipcard .front h2:after{
	content: "";
	display: block;
	width: 100px;
	height: 3px;
	background: rgba(255, 255, 255, .5);
	margin: 18px auto; 
}

.flipcard .back h2{
	color: #fff;
    width: 100%;
    display: block;
    font-size: 28px;
    text-align: center;
    padding-top: 100px;
    backface-visibility: hidden;
    -webkit-transform: translate3d(0,-50%,50px);
    transform: translate3d(0,-50%,50px);   
}

.flipcard .back p{
	color: #fff;
    width: 100%;
    display: block;
    text-align: center;
    line-height: 1.8;
    padding-top: 0px;
    backface-visibility: hidden;
    -webkit-transform: translate3d(0,-60%,50px) scale(.8);
    transform: translate3d(0,-60%,50px) scale(.8);   
}

.flipcard .back a{
	color: #fff;
    width: 100%;
    display: inline-block !important;
    text-align: center;
    line-height: 1.8;
    padding-top: 0px;
    backface-visibility: hidden;
    -webkit-transform: translate3d(0,-200%,50px) scale(.8);
    transform: translate3d(0,-200%,50px) scale(.8);   
}



/*-----------------------------------------
40. Modals
-------------------------------------------*/
.izimodal{
	overflow: hidden;
}

.iziModal-content{
	width: 100%;
}

.modal-inside{
	position: relative;
	width: 100%;
}

.close-modal{
    opacity: 1;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 100%;
    position: absolute;
    top: 5px;
    right: 12px;
    color: #aaa;
    z-index: 20;
    line-height: 40px;
    font-weight: 100;
    font-size: 17px;
}

.close-modal i{
    color: #aaa;
    line-height: 40px;
    font-weight: 100;
    font-size: 17px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;    
}

.close-modal:hover i{
    color: #222;
}

.modal-heading{
	margin-bottom: 15px;
}

.modal-heading h2{
	font-size: 36px;
	color: #222;
	font-weight: 300;
	line-height: 1.5;	
}

.modal-heading h3{
	font-size: 28px;
	color: #222;
	font-weight: 600;
	line-height: 1.5;
}

.modal-heading h4{
	font-size: 25px;
	color: #222;
	font-weight: 500;
	line-height: 1.5;
}

.modal-heading h5{
	font-size: 20px;
	color: #222;
	font-weight: 600;
	line-height: 1.5;
}

.modal-heading h6{
	font-size: 17px;
	color: #222;
	font-weight: 600;
	line-height: 1.5;
}

.modal-heading strong{
	font-weight: 600;
	letter-spacing: -1px;
	word-spacing: 1px;
}

.modal-heading ul{
	padding: 0px;
}

.modal-heading ul li{
	list-style-type: none;
	display: inline-block;
}

.modal-heading ul li:after{
	content: " /";
    font-size: 13px;
    color: #999;	
    margin: 0px 2px;
}
.modal-heading ul li:last-child:after{
	content: none;
}

.modal-heading ul li a{
    font-size: 13px;
    color: #999;
    line-height: 1.7;
    font-weight: 400;	
}

.modal-heading ul li a:hover{
	color: #2387ea;
}

.modal-inside p {
    font-size: 15px;
    color: #999;
    line-height: 1.7;
    font-weight: 400;
}


.izimodal .inner-frame{
	padding: 0px 30px 30px 30px;
}


.quick-view-price{
	position: relative;
}

.quick-view-price h4{
	display: inline-block;
	font-size: 22px;
	color: #222;
	font-weight: 400;
	line-height: 1.5;
}

.quick-view-price .old-price{
	color: #999;
	margin-right: 15px;
}



/*-----------------------------------------
41. Accordions
-------------------------------------------*/
.card-header-primary {
	padding: 22px 5px;
	margin-bottom: 0;
	background-color: transparent !important; 
	border-bottom: 0px; 
}

.card{
	border: none;
}

.card-primary {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent !important;
    background-clip: border-box;
    border-bottom: 1px solid #eee;
    border-radius: 0px;
}

.card-primary a{
	display: block;
	font-size: 15px;
	color: #2387ea;
}

.card-primary a:hover{
	color: #2387ea;
}
.card-primary .collapsed:focus:hover{
	color: #2387ea;
}

.card-primary a:after{
	float: right;
	content: "–";
	font-size: 16px;
	font-weight: 700;
	display: inline-block;
}
.card-primary .collapsed:after{
	float: right;
	content: "+";
	font-size: 16px;
	font-weight: 700;
	display: inline-block;
}



.card-primary .collapsed{
	color: #222;
}

.card-primary .collapsed:focus{
	color: #222;
}

.card-body-primary{
	padding: 5px 5px 20px 5px;
}


.card-body-primary p{
	font-size: 14px;
	font-weight: 500;
	color: #999;
	line-height: 1.8;	
}



/*--- White background Accrodions ---*/
.card-header-white {
	padding: 22px 5px;
	margin-bottom: 0;
	background-color: transparent !important; 
	border-bottom: 0px; 
}

.card{
	border: none;
}

.card-white {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff !important;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    background-clip: border-box;
    border-radius: 5px;
    padding: 0px 15px;
    margin: 15px 0px;
}

.card-white a{
	display: block;
	font-size: 15px;
	color: #2387ea;
}

.card-white a:hover{
	color: #2387ea;
}
.card-white .collapsed:focus:hover{
	color: #2387ea;
}

.card-white a:after{
	float: right;
	content: "–";
	font-size: 16px;
	font-weight: 700;
	display: inline-block;
}
.card-white .collapsed:after{
	float: right;
	content: "+";
	font-size: 16px;
	font-weight: 700;
	display: inline-block;
}

.card-body-white{
	padding: 5px 5px 20px 5px;
}

.card-white .collapsed{
	color: #222;
}

.card-white .collapsed:focus{
	color: #222;
}


.card-body-white p{
	font-size: 14px;
	font-weight: 500;
	color: #999;
	line-height: 1.8;	
}


/*--- White Bordered Accrodions ---*/
.card-header-white-bordered {
	padding: 22px 5px;
	margin-bottom: 0;
	background-color: transparent !important; 
	border-bottom: 0px; 
}

.card{
	border: none;
}

.card-white-bordered {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent !important;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0);
    border-bottom: 1px solid #fff !important;
    background-clip: border-box;
    border-radius: 0px;
    padding: 0px 5px;
    margin: 0px 0px;
}

.card-white-bordered a{
	display: block;
	font-size: 15px;
	color: #fff !important;
}

.card-white-bordered a:hover{
	color: #2387ea;
}
.card-white-bordered .collapsed:focus:hover{
	color: #fff;
}

.card-white-bordered a:after{
	float: right;
	content: "–";
	font-size: 16px;
	font-weight: 700;
	display: inline-block;
}
.card-white-bordered .collapsed:after{
	float: right;
	content: "+";
	font-size: 16px;
	font-weight: 700;
	display: inline-block;
}

.card-body-white-bordered{
	padding: 5px 5px 20px 5px;
}

.card-white-bordered .collapsed{
	color: #222;
}

.card-white-bordered .collapsed:focus{
	color: #222;
}

.card-body-white-bordered p{
	font-size: 14px;
	font-weight: 500;
	color: #999;
	line-height: 1.8;	
}


/*--- Grey background Accrodions ---*/
.card-header-grey {
	padding: 22px 5px;
	margin-bottom: 0;
	background-color: transparent !important; 
	border-bottom: 0px; 
}

.card{
	border: none;
}

.card-grey {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fafafa !important;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0);
    border: 1px solid #eee;
    background-clip: border-box;
    border-radius: 5px;
    padding: 0px 15px;
    margin: 15px 0px;
}

.card-grey a{
	display: block;
	font-size: 15px;
	color: #2387ea;
}

.card-grey a:hover{
	color: #2387ea;
}
.card-grey .collapsed:focus:hover{
	color: #2387ea;
}

.card-grey a:after{
	float: right;
	content: "–";
	font-size: 16px;
	font-weight: 700;
	display: inline-block;
}
.card-grey .collapsed:after{
	float: right;
	content: "+";
	font-size: 16px;
	font-weight: 700;
	display: inline-block;
}

.card-body-grey{
	padding: 5px 5px 20px 5px;
}

.card-grey .collapsed{
	color: #222;
}

.card-grey .collapsed:focus{
	color: #222;
}


.card-body-grey p{
	font-size: 14px;
	font-weight: 500;
	color: #999;
	line-height: 1.8;	
}



/*-----------------------------------------
42. Tabs
-------------------------------------------*/

/*-----------
 Default Tabs
-------------*/
.default-tabs .tabs{
	position: relative;
}

.default-tabs .tabs .tab-menu li {
	background: #fff;
	display: inline-block;
	text-align: center;
}

.default-tabs .tabs .tab-menu li a {
	color: #111;
	display: block;
	font-size: 14px;
	font-weight: 600;
	padding: 18px 24px;
	text-decoration: none;
}

.default-tabs .tabs .tab-menu li a i{
	color: #2387ea;
	display: inline-block;
	font-size: 15px;
	margin-right: 10px; 
	text-decoration: none;
}


.default-tabs .tabs .tab-menu li:hover a, .default-tabs .tabs .tabs li:hover i {
	color: #2387ea;
}

.default-tabs .tab-menu .active-tab a, .default-tabs .active-tab a i {
	color: #2387ea !important;
}


.default-tabs .tab-menu{
	margin-bottom: 4px;
}

@media screen and (max-width: 1200px) {
	.default-tabs .tabs .tab-menu li {
		background: #fff;
		display: inline-block;
		text-align: center;
		width: auto;
	}	
	.default-tabs .tabs .tab-menu li a {
		color: #111;
		display: block;
		font-size: 15px;
		font-weight: 500;
		padding: 18px 24px;
		text-decoration: none;
	}	
}

@media screen and (max-width:767px) {
	.default-tabs .tabs .tab-menu li {
		display: block;
		width: 100%;
		text-align: center;
		margin-bottom: 4px;
	}
	.default-tabs .tab-menu .tabs li a{
		display: block;
		padding: 18px 14px;
	}
	.default-tabs .tab-menu .tabs li i {
		font-size: 25px;	
	}	
}

.tab-body{
	display: block;
	position: relative;
	background: #fff;
	border-radius: 5px;
	margin-top: 4px;
}

.tab-body h2{
	font-size: 30px;
	color: #222;
	font-weight: 300;
	margin-bottom: 10px;
}

.tab-body h3{
	font-size: 25px;
	color: #222;
	font-weight: 500;
	margin-bottom: 10px;
}

.tab-body h4{
	font-size: 20px;
	color: #222;
	font-weight: 500;
	margin-bottom: 10px;
}

.tab-body h5{
	font-size: 15px;
	color: #111;
	font-weight: 500;
	margin-bottom: 10px;
}

.tab-body-dark{
	display: block;
	position: relative;
	padding: 25px;
	background: rgba(0, 0, 0, .7);
	border-radius: 5px;
}

.tab-body-dark h2{
	font-size: 30px;
	color: #fff;
	font-weight: 300;
	margin-bottom: 10px;
}

.tab-body-dark h3{
	font-size: 25px;
	color: #fff;
	font-weight: 500;
	margin-bottom: 10px;
}

.tab-body-dark h4{
	font-size: 20px;
	color: #fff;
	font-weight: 500;
	margin-bottom: 10px;
}

.tab-body-dark h5{
	font-size: 15px;
	color: #fff;
	font-weight: 500;
	margin-bottom: 10px;
}

.tab-body-transparent{
	display: block;
	position: relative;
	background: transparent;
	border-radius: 5px;
}


/*-----------
 Medium Icon Tabs
-------------*/
.medium-icon-tabs .tabs{
	position: relative;
}

.medium-icon-tabs .tabs .tab-menu {
    display: table;
    border-collapse: collapse;
    width: 100%;
	margin-bottom: 5px;
}

.medium-icon-tabs .tabs .tab-menu li {
	display: table-cell;
}


.medium-icon-tabs .tabs .tab-menu li:last-child{
	margin-right: 0px;
}

.medium-icon-tabs .tabs .tab-menu li a {
	background: transparent;
	color: #fff;
	display: block;
	font-size: 15px;
	font-weight: 600;
	border-radius: 5px;
	padding: 18px 24px;
	text-decoration: none;
}

.medium-icon-tabs .tabs .tab-menu li a i{
	color: #fff;
	display: block;
	font-size: 40px;
	margin-bottom: 10px; 
	text-decoration: none;
}

.medium-icon-tabs .tabs .tab-menu li:hover a, .medium-icon-tabs .tabs .tabs li:hover i {
	background: rgba(0, 0, 0, .5);
	color: #fff;
}

.medium-icon-tabs .tab-menu .active-tab a, .medium-icon-tabs .active-tab a i {
	color: #fff !important;
}

.medium-icon-tabs .tab-menu .active-tab a{
	background: #2387ea !important;
	color: #fff;
}

.medium-icon-tabs .tab-menu .active-tab a i{
	color: #fff !important;
}

@media screen and (max-width: 1200px) {
	.medium-icon-tabs .tabs .tab-menu li {
		background: transparent;
		display: inline-block;
		text-align: center;
		width: auto;
	}	
	.medium-icon-tabs .tabs .tab-menu li a {
		color: #fff;
		display: block;
		font-size: 15px;
		font-weight: 500;
		padding: 18px 24px;
		text-decoration: none;
	}	
}

@media screen and (max-width:767px) {
	.medium-icon-tabs .tabs .tab-menu li {
		display: block;
		width: 100%;
		text-align: center;
		margin-bottom: 4px;
	}
	.medium-icon-tabs .tab-menu .tabs li a{
		display: block;
	}
	.medium-icon-tabs .tab-menu .tabs li i {
		font-size: 25px;	
	}	
}



/*--- Light Color ---*/
.medium-icon-tabs-light .tabs{
	position: relative;
}

.medium-icon-tabs-light .tabs .tab-menu {
    display: table;
    border-collapse: collapse;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
	margin-bottom: 5px;
	-webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.075);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.075);	
}

.medium-icon-tabs-light .tabs .tab-menu li {
	display: table-cell;
}

.medium-icon-tabs-light .tabs .tab-menu li:last-child{
	margin-right: 0px;
}

.medium-icon-tabs-light .tabs .tab-menu li a {
	background: transparent;
	color: #222;
	display: block;
	font-size: 15px;
	font-weight: 600;
	padding: 18px 24px;
	text-decoration: none;
}

.medium-icon-tabs-light .tabs .tab-menu li a i{
	color: #222;
	display: block;
	font-size: 40px;
	margin-bottom: 10px; 
	text-decoration: none;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;  	
}

.medium-icon-tabs-light .tabs .tab-menu li a:hover{
	color: #2387ea;
}
.medium-icon-tabs-light .tabs .tab-menu li a:hover i{
	color: #2387ea;
}

.medium-icon-tabs-light .tab-menu .active-tab a, .medium-icon-tabs .active-tab a i {
	color: #2387ea !important;
}

.medium-icon-tabs-light .active-tab a i {
	color: #2387ea !important;
}

.medium-icon-tabs-light .tab-menu .active-tab a{
	background: #fff !important;
	color: #2387ea;
}



@media screen and (max-width: 1200px) {
	.medium-icon-tabs-light .tabs .tab-menu li {
		background: transparent;
		display: inline-block;
		text-align: center;
		width: auto;
	}	
	.medium-icon-tabs-light .tabs .tab-menu li a {
		color: #222;
		display: block;
		font-size: 15px;
		font-weight: 500;
		padding: 18px 24px;
		text-decoration: none;
	}	
}

@media screen and (max-width:767px) {
	.medium-icon-tabs-light .tabs .tab-menu li {
		display: block;
		width: 100%;
		text-align: center;
		margin-bottom: 4px;
	}
	.medium-icon-tabs-light .tab-menu .tabs li a{
		display: block;
	}
	.medium-icon-tabs-light .tab-menu .tabs li i {
		font-size: 25px;	
	}	
}




/*-----------
 Big Icon Tabs
-------------*/
.big-icon-tabs .tabs{
	position: relative;
}

.big-icon-tabs .tabs .tab-menu li {
	display: inline-block;
	text-align: center;
	margin-right: 5px;
}
.big-icon-tabs .tabs .tab-menu li:last-child{
	margin-right: 0px;
}

.big-icon-tabs .tabs .tab-menu li a {
	background: #ececec;
	color: #111;
	display: block;
	font-size: 15px;
	font-weight: 500;
	padding: 18px 24px;
	text-decoration: none;
	border-top: 2px solid #ececec;
}

.big-icon-tabs .tabs .tab-menu li a i{
	color: #2387ea;
	display: block;
	font-size: 40px;
	margin-bottom: 10px; 
	text-decoration: none;
}

.big-icon-tabs .tabs .tab-menu li:hover a, .big-icon-tabs .tabs .tabs li:hover i {
	color: #2387ea;
}

.big-icon-tabs .tab-menu .active-tab a, .big-icon-tabs .active-tab a i {
	border-color: #2387ea !important;
}

.big-icon-tabs .tab-menu .active-tab a{
	background: #f7f7f7 !important;
}


@media screen and (max-width: 1200px) {
	.big-icon-tabs .tabs .tab-menu li {
		background: #f7f7f7;
		display: inline-block;
		text-align: center;
		width: auto;
	}	
	.big-icon-tabs .tabs .tab-menu li a {
		color: #111;
		display: block;
		font-size: 15px;
		font-weight: 500;
		padding: 18px 24px;
		text-decoration: none;
	}	
}

@media screen and (max-width:767px) {
	.big-icon-tabs .tabs .tab-menu li {
		display: block;
		width: 100%;
		text-align: center;
		margin-bottom: 4px;
	}
	.big-icon-tabs .tab-menu .tabs li a{
		display: block;
	}
	.big-icon-tabs .tab-menu .tabs li i {
		font-size: 25px;	
	}	
}






/*-----------------------------------------
43. Progress Bars
-------------------------------------------*/

.progress-bar-title{
	position: relative;
	margin-bottom: 8px;
	margin-top: 26px;
}

.progress-bar-title h5{
	display: inline-block;
	width: 50%;
	float: left;
	font-size: 13px;
	color: #222;
}

.progress-bar-title h6{
	text-align: right;
	display: inline-block;
	width: 50%;
	float: left;
	font-size: 13px;
	color: #222;	
}

.progress-bar-primary{
	background: #2387ea;
}

.progress-bar-dark{
	background: #222;
}

.progress-bar-white{
	background: #fff;
}

/*--- Progress Bar Sizes ---*/
.progress-bar-xs{
	position: relative;
	height: 5px;
}

.progress-bar-sm{
	position: relative;
	height: 8px;
}

.progress-bar-md{
	position: relative;
	height: 10px;
}

.progress-bar-lg{
	position: relative;
	height: 14px;
}





/*-----------------------------------------
44. Pie Charts
-------------------------------------------*/
.pie-chart{
	margin: 20px 0px;
}

.pie-chart .chart { 
	position: relative; 
	display: block; 
	width: 120px; 
	text-align: center; 
	border-radius: 50%;  
	margin: auto;
}

.pie-chart-md .chart { 
	width: 150px; 
}

.pie-chart-sm .chart { 
	width: 130px; 
}

.pie-chart .chart canvas { 
	position: absolute; 
	top: 0; 
	left: 0; 
}

.pie-chart .chart-percent { 
	margin: 0 0 15px 0
}

.pie-chart .percent { 
	font-size: 17px;
	font-weight: 600;
	display: inline-block; 
	z-index: 2;
}

.pie-chart-md .percent { 
	font-size: 18px;
	font-weight: 600;
	display: inline-block; 
	z-index: 2;
}

.percent:after { 
	content: '%'; 
}

.pie-chart h4{
	font-size: 14px;
	font-weight: 600;	
	text-align: center;
	margin-top: 15px;
}

.pie-chart-md h4{
	font-size: 18px;
	font-weight: 600;	
	text-align: center;
	margin-top: 17px;
}

.pie-chart h5{
	color: #999;
	font-size: 12px;
	font-weight: 500;	
	text-align: center;
	margin-top: 15px;
}

.pie-chart-md h5{
	color: #999;
	font-size: 13px;
	font-weight: 500;	
	text-align: center;
	margin-top: 7px;
}




/*-----------------------------------------
45. Countdowns
-------------------------------------------*/

/*--- Styles 1 ---*/
.countdown-1{
	position: relative;
	text-align: center;
}

.countdown-1 h6{
	font-size: 17px;
	color: #222;
	text-align: center;
	margin-top: 5px;
}

.countdown-1 .countdown-box{
	font-size: 60px;
	font-weight: 700;
	color: #222;
	margin: 15px 0px;
}

@media (max-width: 767px) {
	.countdown-1 .countdown-box{
		font-size: 30px;
	}
}


/*Small Size*/
.countdown-1-sm{
	position: relative;
	text-align: center;
}

.countdown-1-sm h6{
	font-size: 14px;
	color: #222;
	text-align: center;
	margin-top: 5px;
}

.countdown-1-sm .countdown-box{
	font-size: 45px;
	font-weight: 700;
	color: #222;
	margin: 15px 0px;
}

@media (max-width: 767px) {
	.countdown-1-sm .countdown-box{
		font-size: 30px;
	}
}


/*--- Styles 1 White ---*/
.countdown-1-white{
	position: relative;
	text-align: center;
}

.countdown-1-white h6{
	font-size: 17px;
	color: #fff;
	opacity: .6;
	text-align: center;
	margin-top: 5px;
}

.countdown-1-white .countdown-box{
	font-size: 60px;
	font-weight: 700;
	color: #fff;
	margin: 15px 0px;
}

@media (max-width: 767px) {
	.countdown-1-white .countdown-box{
		font-size: 30px;
	}
}


/*--- Styles 2 ---*/
.countdown-2{
	position: relative;
	text-align: center;
}

.countdown-2 h6{
	font-size: 17px;
	color: #222;
	text-align: center;
	margin-top: 7px;
}

.countdown-2 .countdown-box{
	font-size: 40px;
	font-weight: 700;
	color: #222;
	background-color: #f7f7f7;
	border-radius: 10px;
	padding: 25px 15px;	
	margin: 15px 0px;
}

@media (max-width: 767px) {
	.countdown-2 .countdown-box{
		font-size: 30px;
	}
}



/*--- Styles 3 ---*/
.countdown-3{
	position: relative;
	text-align: center;
}

.countdown-3 h6{
	font-size: 17px;
	color: #fff;
	text-align: center;
	margin-top: 7px;
}

.countdown-3 .countdown-box{
	font-size: 40px;
	font-weight: 700;
	color: #fff;
	background-color: #222;
	border-radius: 10px;
	padding: 25px 15px;	
	margin: 15px 0px;
}

@media (max-width: 767px) {
	.countdown-3 .countdown-box{
		font-size: 30px;
	}
}




/*-----------------------------------------
46. Owl Carousel Dots
-------------------------------------------*/
.custom-dot {
  display: inline-block;
  z-index: 1;
  margin-top: 20px;
}
.custom-dot span {
  width: 12px;
  height: 12px;
  margin: 5px 7px;
  background: #cecece;
  cursor: pointer;
  display: block;
  border: 1px solid #fff;
  -webkit-backface-visibility: visible;
  border-radius: 30px;  
  -webkit-transition-duration: .3s;
  transition-duration: .3s;      
}
.custom-dot span:hover {
    background: #aaa;
    -webkit-backface-visibility: visible;
    border-radius: 30px;
}
.custom-dot.active span{
  width: 12px;
  height: 12px;
  background: #2387ea;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;    
}

.owl-nav i{
    color: #fff;  
}


.owl-prev {
	opacity: 0;
    width: 50px;
    height: 50px;
    position: absolute;
    top: calc(50% - 30px);
    margin-left: -20px;
    display: block !important;
    border:0px solid black;
    background-color: rgba(0, 0, 0, .3) !important;
	padding-top: 15px !important;   
	-webkit-transition-duration: .3s;
	transition-duration: .3s;  
	transition: opacity .3s ease-in-out;	 
}

.owl-next {
	opacity: 0;
    width: 50px;
    height: 50px;
    position: absolute;
    top: calc(50% - 30px);
    right: 0px;
    display: block !important;
    border:0px solid black;
    background-color: rgba(0, 0, 0, .3) !important;   
    padding-top: 15px !important; 
	-webkit-transition-duration: .3s;
	transition-duration: .3s;  
	transition: opacity .3s ease-in-out;       
}
 
.owl-prev:hover{
	background-color: rgba(0, 0, 0, .6) !important;   
}

.owl-next:hover{
	background-color: rgba(0, 0, 0, .6) !important;   
}


.owl-carousel:hover .owl-prev{
	opacity: 1;
    -webkit-animation-name: fadeIn; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: .3s; /* Safari 4.0 - 8.0 */
    animation-name: fadeIn;
    animation-duration: .3s;
}
.owl-carousel:hover .owl-next{
	opacity: 1;
    -webkit-animation-name: fadeIn; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: .3s; /* Safari 4.0 - 8.0 */
    animation-name: fadeIn;
    animation-duration: .3s;
}



/*-----------------------------------------
47. Pulse Animation
-------------------------------------------*/
/*--- Style 1 ---*/
.pulsebox-1{
	background: rgba(255, 255, 255, .1);
	border-radius: 50%;
	border-radius: 50%;
	cursor: pointer;
	animation: pulse_two 1s infinite;	
}
@-webkit-keyframes pulse_two {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  }
  70% {
      -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 1);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  }
}
@keyframes pulse_two {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, .7);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, .7);
  }
  70% {
      -moz-box-shadow: 0 0 0 30px rgba(255,255,255, 0);
      box-shadow: 0 0 0 30px rgba(255,255,255, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
      box-shadow: 0 0 0 0 rgba(255,255,255, 0);
  }
}


/*--- Style 2 ---*/
.pulsebox-2{
	background: rgba(255, 255, 255, .1);
	border-radius: 50%;
	border-radius: 50%;
	cursor: pointer;
	animation: pulse_two 1.5s infinite;	
}

/*--- Style 3 ---*/
.pulsebox-3{
	background: rgba(255, 255, 255, .1);
	border-radius: 50%;
	border-radius: 50%;
	cursor: pointer;
	animation: pulse_two 2s infinite;	
}





/*-----------------------------------------
48. Loader Styles
-------------------------------------------*/
/*--- Style 1 ---*/
.loader-icon {
    width: 80px;
    height: 80px;
    border: 2px solid #2387ea;
    border-right-color: transparent;
    border-radius: 50%;
    position: relative;
    animation: loader-rotate 1s linear infinite;
    margin: 0 auto;
}
@keyframes loader-rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

/*--- Style 2 ---*/
.loader-icon-2 {
    width: 80px;
    height: 80px;
    border: 2px solid #fff;
    border-right-color: transparent;
    border-radius: 50%;
    position: relative;
    animation: loader-rotate 1s linear infinite;
    margin: 0 auto;
}


/*--- Style 3 ---*/
.loader-icon-3 {
    width: 80px;
    height: 80px;
    border: 2px solid #222;
    border-right-color: transparent;
    border-radius: 50%;
    position: relative;
    animation: loader-rotate 1s linear infinite;
    margin: 0 auto;
}





/*--- Style 4 ---*/
.load-holder {
    display: flex;
    align-items: center;
    justify-content: center;    
}
.load {
    display: flex;
    
    position: relative;
    
    animation: rotateLoad infinite linear 1s;
}
.load.mini{ width: 15px; height: 15px; }
.load.little { width: 25px; height: 25px; }
.load { width: 60px; height: 60px; }
.load:before,
.load:after {
    display: flex;
    
    position: absolute;
    
    content: '';
    background: #2387ea;
    border-radius: 50%;
    width: 55%;
    height: 55%;
}
.load:before { right: 0; bottom: 0; animation: scaleChange_1 infinite linear 1s; }
.load:after { top: 0; left: 0; animation: scaleChange_2 infinite linear 1s; }
.load.dark:before,
.load.dark:after { background: transparent; }
.load.mini:after { border-width: 2px; }
.load.little:after { border-width: 3px; }
.load:after { border-width: 8px; }

@keyframes rotateLoad {
    0%      { transform: rotate(0deg); }
    100%    { transform: rotate(360deg); }
}
@keyframes scaleChange_1 {
    0%      { transform: scale(1); }
    50%     { transform: scale(0); }
    100%    { transform: scale(1); }
}
@keyframes scaleChange_2 {
    0%      { transform: scale(0); }
    50%     { transform: scale(1); }
    100%    { transform: scale(0); }
}




/*--- Style 6 ---*/
.load-3 {
    display: flex;
    
    position: relative;
    
    animation: rotateLoad infinite linear 1s;
}
.load-3.mini{ width: 15px; height: 15px; }
.load-3.little { width: 25px; height: 25px; }
.load-3 { width: 60px; height: 60px; }
.load-3:before,
.load-3:after {
    display: flex;
    
    position: absolute;
    
    content: '';
    background: #222;
    border-radius: 50%;
    width: 55%;
    height: 55%;
}
.load-3:before { right: 0; bottom: 0; animation: scaleChange_1 infinite linear 1s; }
.load-3:after { top: 0; left: 0; animation: scaleChange_2 infinite linear 1s; }
.load-3.dark:before,
.load-3.dark:after { background: transparent; }
.load-3.mini:after { border-width: 2px; }
.load-3.little:after { border-width: 3px; }
.load-3:after { border-width: 8px; }



/*--- Style 5 ---*/
.load-2 {
    display: flex;
    
    position: relative;
    
    animation: rotateLoad infinite linear 1s;
}
.load-2.mini{ width: 15px; height: 15px; }
.load-2.little { width: 25px; height: 25px; }
.load-2 { width: 60px; height: 60px; }
.load-2:before,
.load-2:after {
    display: flex;
    
    position: absolute;
    
    content: '';
    background: #fff;
    border-radius: 50%;
    width: 55%;
    height: 55%;
}
.load-2:before { right: 0; bottom: 0; animation: scaleChange_1 infinite linear 1s; }
.load-2:after { top: 0; left: 0; animation: scaleChange_2 infinite linear 1s; }
.load-2.dark:before,
.load-2.dark:after { background: transparent; }
.load-2.mini:after { border-width: 2px; }
.load-2.little:after { border-width: 3px; }
.load-2:after { border-width: 8px; }





/*--- Style 6 ---*/
.spin-load-holder{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;	
}

.spin-load-1 {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 100px;
    height: 100px;
    margin: -50px 0 0 -50px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #9370DB;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    margin-top: 5px;
}
.spin-load-1:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #BA55D3;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}
.spin-load-1:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #FF00FF;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}
@-webkit-keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


/*--- Style 7 ---*/
.loader-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: #000000;
}

.cssload-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 78.284271247462px;
  height: 78.284271247462px;
  margin-left: -39.142135623731px;
  margin-top: -39.142135623731px;
  border-radius: 100%;
  animation-name: cssload-loader;
  -o-animation-name: cssload-loader;
  -ms-animation-name: cssload-loader;
  -webkit-animation-name: cssload-loader;
  -moz-animation-name: cssload-loader;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-timing-function: linear;
  -o-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  animation-duration: 2.4s;
  -o-animation-duration: 2.4s;
  -ms-animation-duration: 2.4s;
  -webkit-animation-duration: 2.4s;
  -moz-animation-duration: 2.4s;
}

.cssload-loader .cssload-side {
  display: block;
  width: 10px;
  height: 33px;
  background-color: #2387ea;
  margin: 3px;
  position: absolute;
  border-radius: 50%;
  animation-duration: 0.895s;
  -o-animation-duration: 0.895s;
  -ms-animation-duration: 0.895s;
  -webkit-animation-duration: 0.895s;
  -moz-animation-duration: 0.895s;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-timing-function: ease;
  -o-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
}

.cssload-loader .cssload-side:nth-child(1),
.cssload-loader .cssload-side:nth-child(5) {
  transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  animation-name: cssload-rotate0;
  -o-animation-name: cssload-rotate0;
  -ms-animation-name: cssload-rotate0;
  -webkit-animation-name: cssload-rotate0;
  -moz-animation-name: cssload-rotate0;
}

.cssload-loader .cssload-side:nth-child(3),
.cssload-loader .cssload-side:nth-child(7) {
  transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  animation-name: cssload-rotate90;
  -o-animation-name: cssload-rotate90;
  -ms-animation-name: cssload-rotate90;
  -webkit-animation-name: cssload-rotate90;
  -moz-animation-name: cssload-rotate90;
}

.cssload-loader .cssload-side:nth-child(2),
.cssload-loader .cssload-side:nth-child(6) {
  transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  animation-name: cssload-rotate45;
  -o-animation-name: cssload-rotate45;
  -ms-animation-name: cssload-rotate45;
  -webkit-animation-name: cssload-rotate45;
  -moz-animation-name: cssload-rotate45;
}

.cssload-loader .cssload-side:nth-child(4),
.cssload-loader .cssload-side:nth-child(8) {
  transform: rotate(135deg);
  -o-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  animation-name: cssload-rotate135;
  -o-animation-name: cssload-rotate135;
  -ms-animation-name: cssload-rotate135;
  -webkit-animation-name: cssload-rotate135;
  -moz-animation-name: cssload-rotate135;
}

.cssload-loader .cssload-side:nth-child(1) {
  top: 39.142135623731px;
  left: 78.284271247462px;
  margin-left: -5px;
  margin-top: -16px;
}

.cssload-loader .cssload-side:nth-child(2) {
  top: 67.213203431093px;
  left: 67.213203431093px;
  margin-left: -5px;
  margin-top: -16px;
}

.cssload-loader .cssload-side:nth-child(3) {
  top: 78.284271247462px;
  left: 39.142135623731px;
  margin-left: -5px;
  margin-top: -16px;
}

.cssload-loader .cssload-side:nth-child(4) {
  top: 67.213203431093px;
  left: 11.071067816369px;
  margin-left: -5px;
  margin-top: -16px;
}

.cssload-loader .cssload-side:nth-child(5) {
  top: 39.142135623731px;
  left: 0px;
  margin-left: -5px;
  margin-top: -16px;
}

.cssload-loader .cssload-side:nth-child(6) {
  top: 11.071067816369px;
  left: 11.071067816369px;
  margin-left: -5px;
  margin-top: -16px;
}

.cssload-loader .cssload-side:nth-child(7) {
  top: 0px;
  left: 39.142135623731px;
  margin-left: -5px;
  margin-top: -16px;
}

.cssload-loader .cssload-side:nth-child(8) {
  top: 11.071067816369px;
  left: 67.213203431093px;
  margin-left: -5px;
  margin-top: -16px;
}

@keyframes cssload-rotate0 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
}
@-webkit-keyframes cssload-rotate0 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
  }
}
@keyframes cssload-rotate90 {
  0% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    transform: rotate(90deg);
  }
  60% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    transform: rotate(270deg);
  }
}
@-webkit-keyframes cssload-rotate90 {
  0% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  60% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}
@keyframes cssload-rotate45 {
  0% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    transform: rotate(45deg);
  }
  60% {
    -webkit-transform: rotate(225deg);
            transform: rotate(225deg);
    transform: rotate(225deg);
  }
  100% {
    -webkit-transform: rotate(225deg);
            transform: rotate(225deg);
    transform: rotate(225deg);
  }
}
@-webkit-keyframes cssload-rotate45 {
  0% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  60% {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
  }
  100% {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
  }
}
@keyframes cssload-rotate135 {
  0% {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    transform: rotate(135deg);
  }
  60% {
    -webkit-transform: rotate(315deg);
            transform: rotate(315deg);
    transform: rotate(315deg);
  }
  100% {
    -webkit-transform: rotate(315deg);
            transform: rotate(315deg);
    transform: rotate(315deg);
  }
}
@-webkit-keyframes cssload-rotate135 {
  0% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  60% {
    -webkit-transform: rotate(315deg);
    transform: rotate(315deg);
  }
  100% {
    -webkit-transform: rotate(315deg);
    transform: rotate(315deg);
  }
}
@keyframes cssload-loader {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes cssload-loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}




/*--- Style 8 ---*/
.sk-circle {
  margin: 100px auto;
  width: 70px;
  height: 70px;
  position: relative;
}
.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
          animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg); }
.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg); }
.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg); }
.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg); }
.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg); }
.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg); }
.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg); }
.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg); }
.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg); }
.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg); }
.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg); }
.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; }
.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s; }
.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; }
.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }
.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s; }
.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s; }
.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; }
.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s; }
.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s; }
.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s; }
.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s; }

@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}



/*--- Style 9 ---*/
.pulse-loader {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 0px auto;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

.double-bounce3, .double-bounce4 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #2387ea;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce4 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}


.double-bounce5, .double-bounce6 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #222;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce6 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}


/*--- Style 10 ---*/
.saquare-loader-1 {
  width: 40px;
  height: 40px;
  background-color: #2387ea;
  margin: 0px auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}
.saquare-loader-2 {
  width: 40px;
  height: 40px;
  background-color: #fff;
  margin: 0px auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}
.saquare-loader-3 {
  width: 40px;
  height: 40px;
  background-color: #222;
  margin: 0px auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% { -webkit-transform: perspective(120px) }
  50% { -webkit-transform: perspective(120px) rotateY(180deg) }
  100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
}

@keyframes sk-rotateplane {
  0% { 
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg) 
  } 50% { 
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg) 
  } 100% { 
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}



/*--- Style 11 ---*/
.line-loader {
  margin: 0px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.line-loader > div {
  background-color: #2387ea;
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.line-loader .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.line-loader .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.line-loader .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.line-loader .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}


.line-loader-2 {
  margin: 0px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.line-loader-2 > div {
  background-color: #fff;
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.line-loader-2 .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.line-loader-2 .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.line-loader-2 .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.line-loader-2 .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}


.line-loader-3 {
  margin: 0px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.line-loader-3 > div {
  background-color: #222;
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.line-loader-3 .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.line-loader-3 .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.line-loader-3 .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.line-loader-3 .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}


@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}



/*-----------------------------------------
49. Social icons
-------------------------------------------*/
/*--------------------
Style 1
----------------------*/
.social-brand-icons{
	padding: 0px;
	margin: 0px;
}

.social-brand-icons li{
	list-style-type: none;
	display: inline-block;
	height: 50px;
}

.social-brand-icons li a{
	background: #fff;
	border: 1px solid #eee;
	font-size: 13px;
	border-radius: 50%;
	color: #222;
	width: 50px;
	height: 50px;
	padding: 10.5px 13px 10px 13px;
	margin-right: 5px;
}

.social-brand-icons li a:hover{
	color: #fff;	
	background: #2387ea;
	border-color: #2387ea; 
}


/*--------------------
Style 2
----------------------*/
.social-brand-icons-small{
	padding: 0px;
	margin: 0px;
}

.social-brand-icons-small li{
	list-style-type: none;
	display: inline-block;
}

.social-brand-icons-small li a{
	background: #222;
	border: 1px solid #222;
	font-size: 11px;
	border-radius: 50%;
	color: #fff;
	padding: 6px 8px;
	margin-right: 5px;
}

.social-brand-icons-small li a:hover{
	color: #fff;	
	background: #2387ea;
	border-color: #2387ea; 
}




/*-----------------------------------------
50. Errors
-------------------------------------------*/
/*--------------------
Style 1
----------------------*/
.error-404-4 {
    width: 100%;
    height: auto;
    margin: 0 auto !important;
    text-align: center !important;
    position: relative;
    max-width: 1000px;
}

.error-404-4 video {
    width: 100%;  
}

 svg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}

.error-404-4 svg text {
    font-weight: 900;
    text-transform: uppercase;
    font-size: 55px;
}

.error-404-4 svg rect {
    fill: #fff;
}

.error-404-4 svg > rect {
    -webkit-mask: url(#mask);
    mask: url(#mask);
}   

.error-404-4-text h2{
    position: relative;
    color: #D3D6D3;
    font-size: 100px;
    font-weight: 800;
    letter-spacing: 3px;
    text-transform: uppercase;
    text-align: center;
    margin-top: -230px;
}

.error-404-4-text h4{
    position: relative;
    color: #A4A7A4;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
    margin-top: -10px;
}

.error-404-4-text p{
    position: relative;
    color: #949694;
    font-size: 17px;
    font-weight: 600;
    line-height: 160%;
    text-align: center;
    margin-top: 10px;
}

@media (max-width: 991px) {
    .error-404-4-text h2{
        margin-top: -180px;
    }
}
@media (max-width: 767px) {
    .error-404-4-text h2{
        margin-top: -100px;
        font-size: 50px;
    }
    .error-404-4-text h4{
        font-size: 27px;
        margin-top: -5px;
    }
}

/*--------------------
Style 2
----------------------*/
.error-box-2{
    text-align: center;
    padding: 10px;
}

.clip-text {
    display: inline-block;
    position: relative;
    margin: 25px;
    padding: 20px 40px;
    text-align: center;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.clip-text h2{
    color: #fff;
    font-size: 200px;
    font-weight: 800;
    line-height: 89%;
}

.clip-text h3{
    color: #fff;
    font-size: 60px;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 120%; 
}

.error-box-2 h4{
    color: #666;
    font-size: 20px;
    font-weight: 600;
    line-height: 140%; 
    margin-top: 10px;
}

.error-info-2 a{
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    margin-right: 20px;
    border: 1.5px solid #000;
    padding: 6px 20px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
}
.error-info-2 a:hover{
    border: 1.5px solid #3c59b3;
}


.clip-text:before,
.clip-text:after {
    position: absolute;
    content: '';
}

/* Background */
.clip-text:before {
    z-index: -2;
    top: -20px;
    right: -20px;
    bottom: -20px;
    left: -20px;
    background-image: inherit;
}

/* Text Background (black zone) */
.clip-text:after {
    position: absolute;
    background: #fff;
    z-index: -1;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    padding: 50px;
}

@media (max-width: 767px) {
    .clip-text h2{
        font-size: 100px;
    }
    .clip-text h3{
        font-size: 50px;
    }
    .error-box-2 h4{
        font-size: 17px;
    }
}



/*--------------------
Style 3
----------------------*/
.erreor-box-404{
    background-size: cover !important;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #fff;
    text-align: center;
} 

.error-box-3{
    padding: 70px;
}

.error-box-3 h2{
	position: relative;
    color: #fff;
    font-size: 170px;
    font-weight: 700;
    line-height: 1;
}

@media (max-width: 767px) {
	.error-box-3{
		padding: 100px 30px;
	}

	.error-box-3 h2{
	    font-size: 90px;
	}	
}

.error-box-3 h3{
    color: #fff;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
    margin: 15px 0px;
}

.error-box-3 p{
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    margin-top: 10px;
    line-height: 150%;
    margin-bottom: 20px
}



/*-----------------------------------------
51. Animations
-------------------------------------------*/
.zoom-in-animation{
	transition:transform 8s ease-in-out 0s;
	animation-duration: 6.5s;
	animation-name: shrink;
}

@keyframes shrink {
  0% {
    background-size: 100% 100%;
  }
  100% {
    background-size: 110% 110%;
  }
}





/*-----------------------------------------
52. Footer
-------------------------------------------*/
/*--------------------
Style 1
----------------------*/
.footer-style-1{
	position: relative;
	background: #121212;
	padding: 40px 0px 0px 0px;
}

.footer-style-1 h3{
	color: #fff;
	font-size: 19px;
	font-weight: 500;
	margin-bottom: 30px;
	margin-top: 30px;
}
.footer-style-1 h3:after{
	content: '';
	display: block;
	width: 50px;
	max-width: 100%;
	height: 2px;
	background: #2387ea;
	margin: 0 auto;
	margin-top: 10px;
}

.footer-style-1 p{
	font-size: 14px;
	font-weight: 500;
	color: #999;
	line-height: 190%;	
	margin-bottom: 15px;
}



/*--- Social Links ---*/
.footer-style-1-social-links li{
	display: inline-block;
	background: #333;
	border-radius: 5px;
	color: #fff;
	margin-right: 10px;		
	margin-bottom: 5px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;	
}

.footer-style-1-social-links li a{
	display: block;
	font-size: 15px;
	color: #fff;
	width: 35px;
	height: 35px;	
	padding: 11.5px;
	padding-top: 6px;
}

.footer-style-1-social-links li:hover{
	background: #2387ea;
}


/*--- Quick Links ---*/
.footer-style-1-links{
	position: relative;
	padding: 0px;
	margin: 0px;
}

.footer-style-1-links li{
	list-style-type: none;
	display: block;
	padding: 4px 0px;
}

.footer-style-1-links li:first-child{
	padding-top: 0px;
}

.footer-style-1-links li a{
	color: #fff;
	font-size: 14px;
}

.footer-style-1-links li a:hover{
	color: #2387ea;
}


/*--- Latest News ---*/
.footer-style-1-latest-news{
	position: relative;
	padding: 0px;
	margin: 0px;
}
.footer-style-1-latest-news li{
	list-style-type: none;
	display: block;
	padding: 10px 0px;
	border-bottom: 1px solid rgba(255, 255, 255, .1);
}

.footer-style-1-latest-news li:first-child{
	padding-top: 0px;
}

.footer-style-1-latest-news li span{
	display: block;
	color: #fff;
	font-size: 14px;	
	opacity: .5;
}
.footer-style-1-latest-news li a{
	color: #fff;
	font-size: 14px;	
}
.footer-style-1-latest-news li a:hover{
	color: #2387ea;
}


/*--- Contact Info ---*/
.footer-style-1-contact-info{
	position: relative;
	padding: 0px;
	margin: 0px;
}
.footer-style-1-contact-info li{
	list-style-type: none;
	display: block;
	padding: 10px 0px;
}

.footer-style-1-contact-info li i{
	color: #fff;
	font-size: 14px;
	margin-right: 5px; 	
}

.footer-style-1-contact-info li span{
	color: #fff;
	opacity: .7;
	font-size: 14px;	
}


/*--- Footer Bar ---*/
.footer-style-1-bar{
	position: relative;
	background: #222;
	padding: 20px 0px;
	margin-top: 60px;
}

.footer-style-1-bar h5{
	color: #fff;
	font-size: 13px;
	font-weight: 600;
	margin-top: 5px;
}

@media (max-width: 767px) {
	.footer-style-1-bar h5{
		text-align: center;
		margin-bottom: 15px;
	}
}

.footer-style-1-bar-links{
	text-align: right;
}
@media (max-width: 767px) {
	.footer-style-1-bar-links{
		text-align: center;
	}
}



.footer-style-1-bar{
	position: relative;
}	

.footer-style-1-bar li{
	list-style-type: none;
	display: inline-block;
	margin-left: 12px;
}

.footer-style-1-bar li a{
	color: #fff;
	font-size: 13px;	
}

.footer-style-1-bar li a:hover{
	color: #2387ea;
}



/*--------------------
Style 1 Light
----------------------*/
.footer-style-1-light{
	position: relative;
	background: #fff;
	padding: 40px 0px 0px 0px;
}

.footer-style-1-light h3{
	color: #222;
	font-size: 19px;
	font-weight: 500;
	margin-bottom: 30px;
	margin-top: 30px;
}
.footer-style-1-light h3:after{
	content: '';
	display: block;
	width: 50px;
	max-width: 100%;
	height: 2px;
	background: #2387ea;
	margin-top: 10px;
}

.footer-style-1-light p{
	font-size: 13px;
	font-weight: 600;
	color: #666;
	line-height: 190%;	
	margin-bottom: 15px;
}



/*--- Social Links ---*/
.footer-style-1-light .footer-style-1-social-links li{
	display: inline-block;
	background: #fafafa;
	border-radius: 5px;
	color: #222;
	margin-right: 10px;		
	margin-bottom: 5px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;	
}

.footer-style-1-light .footer-style-1-social-links li a{
	display: block;
	font-size: 15px;
	color: #222;
	width: 35px;
	height: 35px;	
	padding: 11.5px;
	padding-top: 6px;
}

.footer-style-1-light .footer-style-1-social-links li:hover{
	background: #2387ea;
}
.footer-style-1-light .footer-style-1-social-links li:hover a{
	color: #fff;
}


/*--- Quick Links ---*/
.footer-style-1-light .footer-style-1-links{
	position: relative;
	padding: 0px;
	margin: 0px;
}

.footer-style-1-light .footer-style-1-links li{
	list-style-type: none;
	display: block;
	padding: 4px 0px;
}

.footer-style-1-light .footer-style-1-links li:first-child{
	padding-top: 0px;
}

.footer-style-1-light .footer-style-1-links li a{
	color: #222;
	font-weight: 600;
	font-size: 14px;
}

.footer-style-1-light .footer-style-1-links li a:hover{
	color: #2387ea;
}


/*--- Latest News ---*/
.footer-style-1-light .footer-style-1-latest-news{
	position: relative;
	padding: 0px;
	margin: 0px;
}
.footer-style-1-light .footer-style-1-latest-news li{
	list-style-type: none;
	display: block;
	padding: 10px 0px;
	border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.footer-style-1-light .footer-style-1-latest-news li:first-child{
	padding-top: 0px;
}

.footer-style-1-light .footer-style-1-latest-news li span{
	display: block;
	color: #777;
	font-size: 14px;	
	opacity: .5;
	font-weight: 600;
}
.footer-style-1-light .footer-style-1-latest-news li a{
	color: #222;
	font-size: 14px;	
	font-weight: 600;
}
.footer-style-1-light .footer-style-1-latest-news li a:hover{
	color: #2387ea;
}


/*--- Contact Info ---*/
.footer-style-1-light .footer-style-1-contact-info{
	position: relative;
	padding: 0px;
	margin: 0px;
}
.footer-style-1-light .footer-style-1-contact-info li{
	list-style-type: none;
	display: block;
	font-weight: 600;
	padding: 10px 0px;
}

.footer-style-1-light .footer-style-1-contact-info li i{
	color: #222;
	font-size: 14px;
	margin-right: 5px; 	
}

.footer-style-1-light .footer-style-1-contact-info li span{
	color: #222;
	opacity: .7;
	font-size: 14px;	
}


/*--- Footer Bar ---*/
.footer-style-1-light .footer-style-1-bar{
	position: relative;
	background: #f2f2f2;
	padding: 20px 0px;
	margin-top: 60px;
}

.footer-style-1-light .footer-style-1-bar h5{
	color: #222;
	font-size: 13px;
	font-weight: 600;
	margin-top: 5px;
}

@media (max-width: 767px) {
	.footer-style-1-light .footer-style-1-bar h5{
		text-align: center;
		margin-bottom: 15px;
	}
}

.footer-style-1-light .footer-style-1-bar-links{
	text-align: right;
}
@media (max-width: 767px) {
	.footer-style-1-light .footer-style-1-bar-links{
		text-align: center;
	}
}



.footer-style-1-light .footer-style-1-bar{
	position: relative;
}	

.footer-style-1-light .footer-style-1-bar li{
	list-style-type: none;
	display: inline-block;
	margin-left: 12px;
}

.footer-style-1-light .footer-style-1-bar li a{
	color: #222;
	font-weight: 600;
	font-size: 13px;	
}

.footer-style-1-light .footer-style-1-bar li a:hover{
	color: #2387ea;
}





/*--------------------
Style 2
----------------------*/
.footer-style-2{
	position: relative;
	background: #fff;
    box-shadow: 0 0 65px rgba(0,0,0,.04);	
	padding: 60px 0px;
}

.footer-style-2 p{
	font-size: 14px;
	font-weight: 600;
	margin: 15px 0px;
}




/*--------------------
Style 3
----------------------*/
.footer-style-3{
	position: relative;
	background: #121212;
	padding: 30px 0px 0px 0px;
}

.footer-style-3 h3{
	color: #fff;
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 30px;
	margin-top: 30px;
}
.footer-style-3 h3:after{
	content: '';
	display: block;
	width: 50px;
	max-width: 100%;
	height: 2px;
	background: #2387ea;
	margin-top: 10px;
}

.footer-style-3 p{
	font-size: 13px;
	font-weight: 600;
	color: #999;
	line-height: 170%;	
	margin-bottom: 15px;
}



/*--- Social Links ---*/
.footer-style-3-social-links li{
	display: inline-block;
	background: transparent;
	border-radius: 50%;
	color: #fff;
	margin-right: 0px;		
	margin: 3px 3px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;	
}

.footer-style-3-social-links li a{
	display: block;
	font-size: 12px;
	color: #fff;
	width: 35px;
	height: 35px;	
	padding: 11.5px;
	padding-top: 8px;
}

.footer-style-3-social-links li:hover{
	background: #2387ea;
}


/*--- Quick Links ---*/
.footer-style-3-links{
	position: relative;
	padding: 0px;
	margin: 0px;
}

.footer-style-3-links li{
	list-style-type: none;
	display: block;
	padding: 4px 0px;
}

.footer-style-3-links li:first-child{
	padding-top: 0px;
}

.footer-style-3-links li a{
	color: #fff;
	font-size: 14px;
}

.footer-style-3-links li a:hover{
	color: #2387ea;
}


/*--- Latest News ---*/
.footer-style-3-latest-news{
	position: relative;
	padding: 0px;
	margin: 0px;
}
.footer-style-3-latest-news li{
	list-style-type: none;
	display: block;
	padding: 10px 0px;
	border-bottom: 1px solid rgba(255, 255, 255, .1);
}

.footer-style-3-latest-news li:first-child{
	padding-top: 0px;
}

.footer-style-3-latest-news li span{
	display: block;
	color: #fff;
	font-size: 14px;	
	opacity: .5;
}
.footer-style-3-latest-news li a{
	color: #fff;
	font-size: 14px;	
}
.footer-style-3-latest-news li a:hover{
	color: #2387ea;
}


/*--- Contact Info ---*/
.footer-style-3-contact-info{
	position: relative;
	padding: 0px;
	margin: 0px;
}
.footer-style-3-contact-info li{
	list-style-type: none;
	display: block;
	padding: 10px 0px;
}

.footer-style-3-contact-info li i{
	color: #fff;
	font-size: 14px;
	margin-right: 5px; 	
}

.footer-style-3-contact-info li span{
	color: #fff;
	opacity: .7;
	font-size: 14px;	
}


/*--- Footer Bar ---*/
.footer-style-3-bar{
	position: relative;
	background: rgba(0, 0, 0, .2);
	padding: 15px 0px;
	margin-top: 60px;
}

.footer-style-3-bar h5{
	color: #fff;
	font-size: 13px;
	font-weight: 600;
	margin-top: 5px;
}

@media (max-width: 767px) {
	.footer-style-3-bar h5{
		text-align: center;
		margin-bottom: 15px;
	}
}

.footer-style-3-bar-links{
	text-align: right;
}
@media (max-width: 767px) {
	.footer-style-3-bar-links{
		text-align: center;
	}
}



/*--------------------
Style 4
----------------------*/
.footer-style-4{
	position: relative;
	background: #111;
	padding: 60px 0px 0px 0px;
}

.footer-style-4 h3{
	color: #fff;
	font-size: 15px;
	font-weight: 700;
	text-transform: uppercase;
	margin-bottom: 30px;
	margin-top: 10px;
}
@media (max-width: 991px) {
	.footer-style-4 h3{
		margin-top: 45px;
	}
}

/*--- Quick Links ---*/
.footer-style-4-qick-links{
	position: relative;
	padding: 0px;
	margin: 0px;
}

.footer-style-4-qick-links li{
	list-style-type: none;
	display: block;
	padding: 4px 0px;
}

.footer-style-4-qick-links li:first-child{
	padding-top: 0px;
}

.footer-style-4-qick-links li a{
	color: #999;
	font-size: 14px;
	font-weight: 600;
}

.footer-style-4-qick-links li a:hover{
	color: #fff;
}


/*--- Contact List ---*/
.footer-4-contact-list{
	padding: 0px;
	margin: 0px;
}

.footer-4-contact-list li{
	color: #999;
	font-size: 14px;
	font-weight: 600;	
	list-style-type: none;
	display: block;
	padding: 8px 0px;
}

.footer-4-contact-list li span{
	color: #fff;
}

.footer-4-contact-list li:first-child{
	padding-top: 0px;
}



/*--- Bottom Bar ---*/
.footer-4-bottom-bar{
	background: transparent;
	border-top: 1px solid #222;
	padding: 30px 0px;
	margin-top: 50px;
}

.footer-4-bottom-bar p{
	color: #999;
	font-size: 13px;
	font-weight: 600;	
}



/*--- Footer Contact Form ---*/
.footer-contact-form input, .footer-contact-form textarea, .footer-contact-form select{
    display: block;
    border-radius: 5px;
    border: none;
    outline: 0;
    background: #222;
    font-size: 12px;
    font-weight: 600;
    width: 100%;
    color: #fff;
    margin-bottom: 17px;
    padding: 10px 16px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.footer-contact-form input:focus, .footer-contact-form textarea:focus, .footer-contact-form select:focus{
	background: #222;
}

.footer-contact-form textarea{
	min-height: 70px;
}

.footer-contact-form button{
	margin-top: 0px;
}




/*-----------------------------------------
53. Color Switcher
-------------------------------------------*/
#style-switcher {
    left: -220px;
    position: fixed;
    top: 250px;
    width: 220px;
    z-index: 9999;    
    background: #fff;
    border-radius: 0 0 3px;
    box-shadow: 0px 5px 15px 0 rgba(0, 0, 0, 0.1);
    -webkit-transition-duration: .7s;
    transition-duration: .7s;      
}

#style-switcher div {
    padding: 5px 10px;
}

#style-switcher h2 {
    color: #222;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0;
    margin-right: 0;
    margin-top: 0;
    padding: 15px 0px 7px 0px;
    text-align: center;
}

#style-switcher.opened{
    left: 0px;
}

#style-switcher h2 a {
    background: #eee;
    border-radius: 0px 3px 3px 0px;
    display: block;
    height: 40px;
    position: absolute;
    right: -40px;
    top: 0;
    width: 40px;
}

#style-switcher h2 a i {
    color: #111;
    font-size: 17px;
    left: 11px;
    position: absolute;
    top: 11px;
}

.colors {
    padding-left: 7px;
}

.colors {
    list-style: none;
    margin: 0px 0px 10px 0px;
    overflow: hidden;
}

.colors li {
    float: left;
}

.colors li a {
	cursor: pointer;
	display: block;
	height: 30px;
	margin: 7px 9px;
	width: 30px;
    border-radius: 30px;
}

.style1 {
    background: #2387ea; 
}

.style2 {
    background: #2b4fbd; 
}

.style3 {
    background: #2ebf7e;  
}

.style4 {
    background: #29d9c2;  
}

.style5 {
    background: #73a730;  
}

.style6 {
    background: #d01b28;  
}

.style7 {
    background: #e44377;  
}

.style8 {
    background: #efc008;  
}